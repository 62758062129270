export const changeFormFieldsData = (array, object) => {
	let newArr = []

	for (let i in array) {
		for (let key in object) {
			if (array[i].name === key) {
				newArr.push({
					name: array[i].name,
					value: object[key]
				});
			}
		}
	}

	return newArr
}

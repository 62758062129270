import React from 'react';
import style from './MobileRightbar.css';
// import MobileStatistic from './Statistic/MobileStatistic';
import MobileRightTopNav from './MobileRightTopNav';
// import RightBarLoader from '../../../Loader/rightBarLoader';

class MobileRightbar extends React.Component {
  render() {
    return (
        // <div className={style.rightbar_wrapper} onClick={this.ClickFunc}>
        <div className="rightbar_wrapper"  onClick={this.ClickFunc}>
          <MobileRightTopNav/>
        </div>
    );
  }
}

export default MobileRightbar;

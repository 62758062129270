import React, {Component} from 'react';
import {connect} from "dva";
import style from './MobileGradientLine.css'
import MobileGradientLineItem from './MobileGradientLineItem';
// import { mapName, mobileMapModelStateName } from '../../../../../utils/map-variables';

@connect((state) => {
    const active_map = 'mobile'
    const active_model = 'mobile'
    if(state.geo.almaty.legends_types){
        const counter = state.geo.almaty.legends_types[active_map].almaty.colors.length
        let box = []
        let layer = state.geo.almaty.active_layer
        let sublayer = state.geo.almaty.active_sublayer

        if(state.geo.almaty.start_data.dynamic_gradient){

            box = []

            for(var i = 0; i < counter; i++){
                box.push(
                    {
                        backet: state.geo.almaty.legends_types.mobile[layer][sublayer].gradient[i],
                        color: state.geo.almaty.legends_types.mobile[layer][sublayer].colors[i]
                    }
                )
            }
        }

        return {
            legends_types: box,
            active_filter: state.geo.almaty.active_filters.gradient
        }
    }

    return {
        legends_types: state.geo.almaty.legends_types[active_map].almaty,
        active_filter: state.geo.almaty.active_filters.gradient
    }
})

class MobileGradientLine extends Component {
    constructor(props) {
        super(props);
        this.state={
            active: ''
        }
    }

    changeGradientFilter = (counter) => {
        let gradient = counter == 'refresh' ?
            {min: '', max: ''} :
            {
                min: this.props.legends_types[counter].backet.min + 0.5,
                max: this.props.legends_types[counter].backet.max + 0.5
            }
        this.props.dispatch(
            {
                type: 'geo/changeActiveFilter',
                payload: {
                    name: 'gradient',
                    value: gradient
                }
            }
        )
    }
    changeActiveItem = (counter, active) => {
        if(counter === active){
            this.setState({
                    active: ''
                }, () => (
                    this.changeGradientFilter('refresh'),
                        console.log('changeActiveItem back to setup this.state:', this.state))
            )
        } else {
            this.setState({
                    active: counter
                }, () => (
                    this.changeGradientFilter(counter),
                        console.log('changeActiveItem set new this.state:', this.state))
            )
        }
    }


    render() {
        const for_render = this.props.legends_types.map((item, i) => {
            return <MobileGradientLineItem
                key={Math.random()}
                color={item.color}
                minGradient={item.backet.min}
                maxGradient={item.backet.max}
                counter={i}
                active={this.state.active}
                changeActiveItem={this.changeActiveItem}
            />
        })

        return <div className="gradient"
        >
            <ul className="ul">
                {for_render}
            </ul>
        </div>
    }

    componentDidUpdate(prevProps) {
        if(
            prevProps.active_filter.min.toString().length > 0 && this.props.active_filter.min.toString().length == 0
        ){
            this.setState({
                    active: ''
                }, () => (
                    this.changeGradientFilter('refresh')
                )
            )
        }
    }

    //FIXME To select saved active gradient filter
    componentDidMount() {
        if(this.props.active_filter.min.toString().length > 0 && this.props.active_filter.max.toString() > 0){
            let index = this.props.legends_types.findIndex(item => item.backet.min === this.props.active_filter.min);
            this.setState(state => {
                return {
                    ...state,
                    active: index,
                }
            })
        }
    }
}

export default MobileGradientLine;

const clientsTypes = {
	getClientsFetch: 'clients/getClientsFetch',
	getClientsPending: 'getClientsPending',
	getClientsSuccess: 'getClientsSuccess',
	getClientsError: 'getClientsError',

	createClientsFetch: 'clients/createClientsFetch',
	createClientsPending: 'createClientsPending',
	createClientsSuccess: 'createClientsSuccess',
	createClientsError: 'createClientsError',

	changeClientsFetch: 'clients/changeClientsFetch',
	changeClientsPending: 'changeClientsPending',
	changeClientsSuccess: 'changeClientsSuccess',
	changeClientsError: 'changeClientsError',

	deleteClientsFetch: 'clients/deleteClientsFetch',
	deleteClientsPending: 'deleteClientsPending',
	deleteClientsSuccess: 'deleteClientsSuccess',
	deleteClientsError: 'deleteClientsError',
}

export const clientsActions = {
	deleteClientsFetch: (id) => {
		return {
			type: clientsTypes.deleteClientsFetch,
			payload: id
		}
	},
	deleteClientsPending: () => {
		return {
			type: clientsTypes.deleteClientsPending
		}
	},
	deleteClientsSuccess: (id) => {

		return {
			type: clientsTypes.deleteClientsSuccess,
			payload: id
		}
	},
	deleteClientsError: () => {
		return {
			type: clientsTypes.deleteClientsError
		}
	},

	changeClientsFetch: (client, id) => {
		return {
			type: clientsTypes.changeClientsFetch,
			payload: {client, id}
		}
	},
	changeClientsPending: () => {
		return {
			type: clientsTypes.changeClientsPending
		}
	},
	changeClientsSuccess: (client) => {
		return {
			type: clientsTypes.changeClientsSuccess,
			payload: client
		}
	},
	changeClientsError: () => {
		return {
			type: clientsTypes.changeClientsError
		}
	},

	createClientsFetch: (payload) => {
		return {
			type: clientsTypes.createClientsFetch,
			payload: payload
		}
	},
	createClientsPending: () => {
		return {
			type: clientsTypes.createClientsPending
		}
	},
	createClientsSuccess: (payload) => {
		return {
			type: clientsTypes.createClientsSuccess,
			payload: payload
		}
	},
	createClientsError: () => {
		return {
			type: clientsTypes.createClientsError
		}
	},


	getClientsFetch: (query = '') => {
		return {
			type: clientsTypes.getClientsFetch,
			payload: query
		}
	},

	getClientsPending: () => {
		return {
			type: clientsTypes.getClientsPending,
		}
	},

	getClientsSuccess: (payload) => {
		return {
			type: clientsTypes.getClientsSuccess,
			payload: payload
		}
	},

	getClientsError: () => {
		return {
			type: clientsTypes.getClientsError,
		}
	},


}

import MapComponent from "./components/MapComponent";
import Layout from "antd/es/layout/layout";
import {connect} from "dva";
import {useEffect} from "react";
import {mapActions} from "../../../models/mapModel/mapActions";
import {useParams} from "react-router-dom";
import {productsActions} from "../../../models/productsModel/productsActions";



const UserInterfaceMapConnect = (props) => {

	const {
		dispatch,
		dataset
	} = props

	const {productId} = useParams()
	let current_product = dataset.products?.filter(item => item.id == productId)[0]


	useEffect(() => {
		const randomMath = Math.floor(Math.random() * 100)
		// dispatch(mapActions.datasetFetch(productId, randomMath))
		let payload = [productId, randomMath]
		dispatch(
			{
				type: 'geo/getGeoFetch',
				// payload: selectedOption.payload
				payload: (payload)
			}
		)
		dispatch(productsActions.getPublicFetch(productId))

		// dispatch(mapActions.getGeoFetch(productId, randomMath))

		// dispatch(mapActions.mapFetch('dateFrom', 'dateTo')) //FIXME need send default data from to
	}, [])


	return <Layout className={'contentLayout'}>
		<MapComponent
			dataset={dataset}
			current_product={current_product}
		/>
	</Layout>
}

function mapStateToProps(state) {
	let zids = []
	let filtered_zids = []
	let data_zids = []
	let gradient = []
	let products = []


	if (state.geo.almaty.start_data) {
		if (state.geo.almaty.start_data.zids) {
			zids = state.geo.almaty.start_data.zids.features
			// if (state.geo.almaty.active_filters.districts.length > 0) {
			if (state.geo.almaty.filtered_zids_data.length > 0) {
				data_zids = state.geo.almaty.filtered_zids_data
			} else {
				data_zids = zids
			}

			return {
				dataset: {
					zids: data_zids,
					current_field: '$' + state.geo.almaty.active_sublayer,
					current_layer: state.geo.almaty.active_layer,
					dynymic_gradient: state.geo.almaty.start_data.dynamic_gradient.mobile,
					products: state.products.data
				}
			}
		}
		if (state.geo.almaty.start_data.dynamic_gradient) {
			gradient = state.geo.almaty.start_data.dynamic_gradient.mobile
		}
		if (state.products.data.length > 0) {
			products = state.products.data
		}
		return {
			dataset: '',
			current_field: '$' + state.geo.almaty.active_sublayer,
			current_layer: state.geo.almaty.active_layer,
			dynymic_gradient: gradient,
			products: products
		}

	}

}

export const UserInterfaceMapContainer = connect(mapStateToProps)(UserInterfaceMapConnect);

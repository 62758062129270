import {Divider, List, Space, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import {formatDate} from "../../../../Tools/refactorDate";
import Card from "antd/es/card";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import FolderOpenOutlined from "@ant-design/icons/FolderOpenOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import Spin from "antd/es/spin";
import {PENDING} from "../../../../models/globalStatuses";
import Search from "antd/es/input/Search";
import DotChartOutlined from "@ant-design/icons/DotChartOutlined";

export const UserInterfaceReportsListComponent = (props) => {
    const {
        products,
        CRUDStatus,
        navigate,
        exportDatasetFile,
        onSearch,
        filteredData,
    } = props


    const download = (id) => {
        const randomMath = Math.floor(Math.random() * 10100000000)
        // window.open(`https://clavem.frontier.kz/api/v1/datasets/export/${id}?q=${randomMath}`, "_blank")
            window.open(`${window.location.origin}/api/v1/datasets/export/${id}?q=${randomMath}`, "_blank")
    }



    return (
        <>
            <Title level={3} className={'layout_page_header'} style={{fontWeight:'500'}} >
                Мои отчёты
            </Title>

            {CRUDStatus === PENDING ? <Spin/> :
                <>
                    <Search placeholder="Введите запрос" onSearch={onSearch} enterButton/>
                    <div className="client_row container" >
                        {
                            (filteredData.length ? filteredData : products || []).map((product, index) => {

                                    return <Card
                                        style={{marginTop: 10, borderRadius: '8px',}}
                                        type="inner"
                                        title={
                                            <span style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                // fontSize: '20px'
                                            }}>
                                               <p style={{margin: 0, fontSize: 18,fontWeight:'400', whiteSpace: 'initial'}}>{
                                                   <FolderOpenOutlined style={{fontSize: 24}}/>} {product.name}</p>
                                                {/*<FolderOpenOutlined style={{color: '#3C64B1'}}/>*/}

									</span>
                                        }
                                        key={`card-key:${index}`}
                                    >
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <div className={'product_card_p'}  >
                                                    <p style={{
                                                        fontSize: '13px',
                                                        color: '#707683',
                                                        marginTop: '5px',
                                                        marginBottom: '0'
                                                    }}>
                                                        от: <span style={{fontWeight: 'bold'}}>{formatDate(product.loaded_at)}</span>
                                                    </p>
                                                </div>
                                                <div style={{marginBottom: '15px', marginTop: '5px', minHeight: '20px'}}>
                                                    <div className={'product_card_p'} style={{marginTop: '10px', minHeight: '50px'}} dangerouslySetInnerHTML={{__html: product.description}}></div>
                                                </div>
                                                <Space/>


                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: '13px',
                                            color: '#707683',
                                            marginTop: '9px'
                                        }}>
                                            Для того чтобы скачать нажмите кнопку ниже
                                        </p>
                                        <a href={product.path}
                                           id={product.id}
                                           onClick={() => download(product.id)}
                                           className={'products_button_download'}
                                           // onClick={() => exportDatasetFile(product.id)}
                                           style={{
                                               display: 'block',
                                               width: '100%',
                                               background: '#292974',
                                               border: '1px solid #292974',
                                               padding: '9px',
                                               fontSize: '14px',
                                               color: '#fff',
                                               textAlign: 'center',
                                               borderRadius: '8px',
                                               transition: '.3s',
                                               marginTop: '5px'
                                           }}



                                        >
                                            Скачать
                                        </a>
                                    </Card>



                                }
                            )
                        }
                    </div>

                </>
            }
        </>
    )
}

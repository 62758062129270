import React from 'react';
import dva from 'dva';
import 'antd/dist/antd.css';
import './index.css'
import './assets/fonts/stylesheet.css'

import {App} from "./App";
import {authModel} from "./models/authModel/authModel";
import {regModel} from "./models/regModel/regModel";
import {clientsModel} from "./models/clientsModel/clientsModel";
import {productsModel} from "./models/productsModel/productsModel";
import {usersModel} from "./models/usersModel/usersModel";
import {datasetsModel} from "./models/datasetsModel/datasetsModel";
import {infoModel} from "./models/infoModel/infoModel";
import {mapModel} from "./models/mapModel/mapModel";
import {geoModel} from "./models/mapModel/geoModel";
import './Tools/i18n';


const app = dva();
app.model(authModel)
app.model(regModel)
app.model(usersModel)
app.model(clientsModel)
app.model(productsModel)
app.model(datasetsModel)
app.model(infoModel)
app.model(mapModel)
app.model(geoModel)


app.router(() => <App/>);


app.start('#root');

import React from 'react'
import {connect} from "dva";
import {Auth} from "./components/Auth/Auth";
import {authActions} from "../../models/authModel/authActions";


const AuthConnector = (props) => {
	const {authStatus} = props.auth
	const {dispatch} = props

	const onAuthorize = (username, password) => {
		dispatch(authActions.onAuth(username, password))
	}

	return (
		<Auth
			authStatus={authStatus}
			onAuthorize={onAuthorize}
		/>
	)

}


function mapStateToProps(state) {
	return {
		auth: state.auth
	}
}


export const AuthContainer = connect(mapStateToProps)(AuthConnector)

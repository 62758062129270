export const place_count = {
  cbh_and_competitors_place_count: 'ЕСТЬ CBH и ЕСТЬ конкуренты',
  cbh_and_no_competitors_place_count: 'ЕСТЬ CBH и НЕТ конкурентов',
  no_cbh_but_competitors_place_count: 'НЕТ CBH, но ЕСТЬ конкуренты',
  others_only_place_count: 'НЕТ CBH и НЕТ конкурентов, но ЕСТЬ другие',
}

export const avg_receipts_amt = {
  cbh_and_no_competitors_avg_receipt_amt: 'ЕСТЬ CBH и НЕТ конкурентов',
  cbh_and_competitors_avg_receipt_amt: 'ЕСТЬ CBH и ЕСТЬ конкуренты',
  no_cbh_but_competitors_avg_receipt_amt: 'НЕТ CBH, но ЕСТЬ конкуренты',
  others_only_avg_receipt_amt: 'НЕТ CBH и НЕТ конкурентов, но ЕСТЬ другие',
}

export const sales_amt = {
  cbh_and_competitors_category_sales_amt: 'ЕСТЬ CBH и ЕСТЬ конкуренты',
  cbh_and_no_competitors_category_sales_amt: 'ЕСТЬ CBH и НЕТ конкурентов',
  no_cbh_but_competitors_category_sales_amt: 'НЕТ CBH, но ЕСТЬ конкуренты',
  others_only_category_sales_amt: 'НЕТ CBH и НЕТ конкурентов, но ЕСТЬ другие',
}

export const receipts_count = {
  cbh_and_no_competitors_receipts_count: 'ЕСТЬ CBH и НЕТ конкурентов',
  others_only_receipts_count: 'НЕТ CBH и НЕТ конкурентов, но ЕСТЬ другие',
  cbh_and_competitors_receipts_count: 'ЕСТЬ CBH и ЕСТЬ конкуренты',
  no_cbh_but_competitors_receipts_count: 'НЕТ CBH, но ЕСТЬ конкуренты',

}

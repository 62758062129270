import React, { Component } from 'react';
import style_select from './Widgets_select.css'
import style from '../Rightbar.css'
import Select  from 'antd/es/select';
import { Switch } from 'antd';
// import { mapName } from '../../../../../utils/map-variables';
const { Option } = Select;

export default class SelectSwitchWidgets extends Component {
    //FIXME ADD HERE LOGIC FORK BY PROPS AT INIT THIS COMPONENT FOR ANOTHER RENDER, IF NEEDED ANOTHER OPTIONS IN SELECT

    modeSwitch = (checked) => {
        const map = 'mobile'
        this.props.dispatch(
            {
                type: `${map}/switchModeDPEffect`,
                payload:{
                    mode: checked ? 'proficit' : 'deficit',
                }
            }
        )
        // this.props.dispatch(
        //   {
        //     type: 'mobile/clearDPFiltersAfterSwitch',
        //     payload: {
        //       mode: checked ? 'deficit' : 'proficit',
        //     }
        //   }
        // )
        this.props.dispatch(
            {
                type: `geo/changeMapLayer`,
                payload: checked ? 'proficit' : 'deficit',
            }
        )
    }

    render() {
        let options_content = ''
        const badge = <div style={{
            display: 'block', position: 'static', marginLeft: 5 + 'px', backgroundColor: '#172044', height: 7 + 'px', width: 7 + 'px', borderRadius: 100 + '%',
        }}
        />

        if(this.props.options_type == 3){
            options_content = <Select className="select_ant_single" value={this.props.wrapper_value} onChange={this.props.handleChange}>
                <Option className="select_ant_dropdown" value={0}>Сумма оборотов</Option>
                <Option className="select_ant_dropdown" value={1}>Кол-во организаций</Option>
                <Option className="select_ant_dropdown" value={2}>Кол-во чеков</Option> :
            </Select>

        }
        if(this.props.options_type == 2){
            options_content = <Select  className="select_ant_single" value={this.props.wrapper_value} onChange={this.props.handleChange}>
                <Option value={0}>Сумма оборотов</Option>
                <Option value={1}>Кол-во касс</Option>
            </Select>
        }

        if(this.props.options_type == 'Поквартально / Понедельно'){
            options_content = <Select className="select_ant_single" value={this.props.wrapper_value} onChange={this.props.handleChange}>
                <Option value={0}>Поквартально</Option>
                <Option value={1}>Понедельно</Option>
            </Select>
        }


        if(this.props.options_type == 4){
            options_content = <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Select className="select_ant_single" style={{display: 'none'}} value={this.props.wrapper_value} onChange={this.props.handleChange}>
                    <Option value={0}>Социальные объекты</Option>
                    <Option value={1}>Бизнес объекты</Option>
                </Select>
                {/*<div*/}
                {/*    style={{*/}
                {/*        fontSize: '14px',*/}
                {/*        padding: '0px 10px',*/}
                {/*    }}>*/}
                {/*    Дефицит*/}
                {/*    <Switch*/}
                {/*        onChange={this.modeSwitch} checked={this.props.mode_DP === 'proficit'}*/}
                {/*        style={{*/}
                {/*            margin: '0px 5px',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    Профицит*/}
                {/*</div>*/}
            </div>
        }

        return (
            <>
                <div className="widget_title">
                    {this.props.wrapper_title}
                    {/*{this.props.badge.length  ? badge : '' }*/}
                    {!this.props.badge ? '' : this.props.badge.length > 0 ? badge : '' }

                </div>
                <div className="widgetSelect">
                    {options_content}
                </div>
            </>

        )
    }
}

import {Popconfirm, Table, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import {Row} from "antd";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Button from "antd/es/button";
import {formatDate} from "../../../../Tools/refactorDate";
import {PENDING} from "../../../../models/globalStatuses";
import moment from "moment";

export const ProductsListComponent = (props) => {
	const {
		products,
		onOpenModal,
		onDelete,
		CRUDStatus
	} = props
	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Путь',
			dataIndex: 'path',
			key: 'path',
			render: (_, product) => (product.path || '-')
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
			render: descr => {
				return <div dangerouslySetInnerHTML={{__html: descr || '-'}}
				/>
			}
		},
		{
			title: 'Создано',
			dataIndex: 'created_by',
			key: 'created_by'
		},
		{
			title: 'Клиент',
			dataIndex: 'client_names',
			key: 'client_names',
			render: (_, client) =>
				client.clients_names.length > 0
					? client.clients_names.map(item => <p>{item}</p>)
					: <p>-</p>
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
			// render: type => type.map(item => <p >{item}</p>)
		},
		{
			title: 'Дата создания',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (_, product) => `${formatDate(product.created_at)}`,
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
			width: 120,
			align: 'center'
		},
		{
			title: 'Действия',
			align: 'center',
			key: 'actions',
			render: (_, product) => (
				<Button.Group>
					<Tooltip placement="topLeft" title={'Edit product'}>
						<Button type="default" onClick={() => onOpenModal('update', product)}>
							<EditOutlined/>
						</Button>
					</Tooltip>
					<Popconfirm
						title="Are you sure to delete this Image?"
						onConfirm={() => onDelete(product.id)}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip placement="topLeft" title={'Delete this item'}>
							<Button type="primary" danger>
								<DeleteOutlined/>
							</Button>
						</Tooltip>
					</Popconfirm>
				</Button.Group>
			),
			width: 120
		},
	];

	const sideBarMenuItems = [ //FIXME action for page after refactor remove this code fragment
		{
			title: 'Create',
			icon:  <PlusCircleOutlined/>,
			action: () => onOpenModal('create')
		}
	]

	return (
		<>
			<Row justify={"space-between"}>
				<Title level={3} className={'layout_page_header'} style={{fontWeight: '500'}}>Продукты</Title>
				<Button style={{borderRadius:'10px', alignSelf:'center'}} type="default" onClick={() => onOpenModal('create')}>Добавить продукт</Button>
			</Row>

			<div className='container'>
				<Table
					columns={columns}
					dataSource={products}
					style={{tableLayout: 'fixed'}}
					id="admin_products"
					rowKey="name"
					loading={CRUDStatus === PENDING}
				/>
			</div>
		</>
	)
}

import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {usersActions} from "./usersActions";
import {infoActions} from "../infoModel/infoActions";
import {usersApi} from "./usersApi";
import {showError} from "../../Tools/showError";
import {authActions} from "../authModel/authActions";

export const usersModel = {
	namespace: 'users',
	state: {
		data: [],
		userProducts: [],
		CRUDStatus: null
	},
	reducers: {
		getUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: payload
			}
		},
		getUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		createUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		createUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		createUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		blockUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		blockUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		blockUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		unBlockUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		unBlockUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		unBlockUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},


		editUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		editUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		editUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},


		changeUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		changeUsersSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data.map(item => item.id === payload.id ?
						{
							...payload,
							key: `item-${item.id}`
						} : item)
				]
			}
		},
		changeUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
		deleteUsersPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		deleteUsersSuccess(state, {payload}) {
			return {
				...state,
				data: [
					...state.data.filter(item => item.id !== payload)
				],
				CRUDStatus: SUCCESS
			}
		},

		deleteUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		setProductsToUserPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		setProductsToUserSuccess(state) {
			return {
				...state,
				CRUDStatus: SUCCESS
			}
		},

		setProductsToUserError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getProductsForUserPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		getProductsForUserSuccess(state, {payload}) {
			return {
				...state,
				userProducts: payload,
				CRUDStatus: SUCCESS
			}
		},

		getProductsForUsersError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		createMassProductsToUserPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		createMassProductsToUserSuccess(state) {
			return {
				...state,
				CRUDStatus: SUCCESS
			}
		},

		createMassProductsToUserError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		deleteUsersBindPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		deleteUsersBindSuccess(state, {payload}) {
			return {
				...state,
				userProducts: [
					...state.userProducts.filter(item => item.product_id !== payload)
				],
				CRUDStatus: SUCCESS
			}
		},

		deleteUsersBindError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

	},


	effects: {

		* deleteUsersBindFetch({payload}, {put, call}) {
			yield put(usersActions.deleteUsersBindPending())
			try {
				yield call(() => usersApi.deleteBindUser(payload.user_id, payload.product_id))
				yield put(usersActions.deleteUsersBindSuccess(payload.product_id))
			} catch (e) {
				showError(e)
				yield put(usersActions.deleteUsersBindError())
			}
		},

		* createMassProductsToUserFetch({payload}, {put, call}) {
			yield put(usersActions.createMassProductsToUserPending())
			try {
				const product = yield call(() => usersApi.createMassProductsToUser(payload))
				yield put(usersActions.createMassProductsToUserSuccess(product))
			} catch (e) {
				showError(e)
				yield put(usersActions.createMassProductsToUserError())
			}
		},

		* getProductsForUserFetch({payload}, {put, call}) {
			yield put(usersActions.getProductsForUserPending())
			try {
				const userProducts = yield call((() => usersApi.getProductsForUserList(payload)))
				yield put(usersActions.getProductsForUserSuccess(userProducts))
			} catch (e) {
				showError(e)
				yield put(usersActions.getProductsForUsersError())
			}
		},

		* setProductsToUserFetch({payload}, {put, call}) {
			yield put(usersActions.setProductsToUserPending())
			try {
				yield call(() => usersApi.setProductsToUser(payload))
				yield put(usersActions.getProductsForUserFetch(payload.users_id[0]))
				yield put(usersActions.setProductsToUserSuccess())
			} catch (e) {
				showError(e)
				yield put(usersActions.setProductsToUserError())
			}
		},

		* deleteUsersFetch({payload}, {put, call}) {
			yield put(usersActions.deleteUsersPending())
			try {
				const productId = yield call(() => usersApi.deleteUser(payload))
				yield put(usersActions.deleteUsersSuccess(productId))
			} catch (e) {
				showError(e)
				yield put(usersActions.deleteUsersError())
			}
		},

		* changeUsersFetch({payload}, {put, call}) {
			yield put(usersActions.changeUsersPending())
			try {
				const product = yield call(() => usersApi.changeUser(payload.product, payload.id))
				yield put(usersActions.changeUsersSuccess(product))
			} catch (e) {
				showError(e)
				yield put(usersActions.changeUsersError())
			}
		},

		* createUsersFetch({payload}, {put, call}) {
			yield put(usersActions.createUsersPending())
			try {
				const product = yield call(() => usersApi.createUser(payload))
				yield put(usersActions.createUsersSuccess(product))
			} catch (e) {
				showError(e.message)
				yield put(usersActions.createUsersError())
			}
		},

		* blockUsersFetch({payload}, {put, call}) {
			yield put(usersActions.blockUsersPending())
			try {
				const product = yield call(() => usersApi.blockUser(payload))
				yield put(usersActions.blockUsersSuccess(product))
				// yield call(() => usersApi.getUsersList())
				yield put(usersActions.getUsersFetch())
				// yield call(unBlockUsersFetch)
			} catch (e) {
				showError(e.message)
				yield put(usersActions.blockUsersError())
			}
		},

		* unBlockUsersFetch({payload}, {put, call}) {
			yield put(usersActions.unBlockUsersPending())
			try {
				const product = yield call(() => usersApi.unBlockUser(payload))
				yield put(usersActions.unBlockUsersSuccess(product))
				yield put(usersActions.getUsersFetch())
			} catch (e) {
				showError(e.message)
				yield put(usersActions.unBlockUsersError())
			}
		},

		* editUsersFetch({payload}, {put, call}) {
			yield put(usersActions.editUsersPending())
			try {
				const user = yield call(() => usersApi.editUser(payload))
				yield put(usersActions.editUsersSuccess(user))
				yield put(infoActions.getInfoFetch())
			} catch (e) {
				showError(e.message)
				yield put(usersActions.editUsersError())
			}
		},

		* getUsersFetch(action, {put, call, take}) {
			yield put(usersActions.getUsersPending())
			try {
				yield put({type: 'products/getProductsFetch'})
				yield take(['products/getProductsSuccess'])
				const users = yield call(() => usersApi.getUsersList())
				yield put(usersActions.getUsersSuccess(users))

			} catch (e) {
				showError(e)
				yield put(usersActions.getUsersError())
			}
		},
	}
}

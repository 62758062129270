import React from 'react';
import WidgetWrapper from '../WidgetWrapper';
import WidgetFunc from './Widget/Widget';
// import { defineCustomElements } from '@carto/airship-components/dist/loader';

// defineCustomElements(window);
const emptyData = [];
const checkTwinData = null;
// @connect((state) => {
//   checkTwinData = state.ofd.filtred_widget_data.get_okeds_1_amt
//   return {
//     data: '',           //FIXME Example - state.ofd.filtred_widget_data.get_okeds_1_amt || emptyData,
//     active_filter: '',  //FIXME Example - state.ofd.active_filters.oked_macro,
//     dictionary: '',     //FIXME Example - oked_1_lvl_dictionary,
//     oked_name: '',      //FIXME Example - 'oked_level_1',
//     item_value: ''      //FIXME Example - 'amt',
//     widget_name: '',    //FIXME Example - 'ОКЭД-1'
//   }
// })


export default class MobileDoubleParentWidget extends WidgetWrapper {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  formatData(data) {
    if (data.length > 0) {
        const formattedData = data.map((item) => {
        if (this.props.item_name) {
          return {
            name: this.props.dictionary[item[this.props.item_name]],
            value: item[this.props.item_value],
          };
        }
        return {
          name: this.props.dictionary[item[this.props.oked_name]],
          value: item[this.props.item_value],
        };
      });
      if (this.props.item_name.includes('proficit')) {
        formattedData.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
      } else {
        formattedData.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
      }
      return formattedData;
    }
    return [];
  }

  formatDataTemp() {

      const formatedFilteredData = this.formatData(this.props.data);
    if (this.props.multi != false) {
      if(this.props.start_data) {

          const formatedStartData = this.formatData(this.props.start_data);
        const mergedData = [...formatedStartData.filter(item => {
          if(!formatedFilteredData.map(item => item.name).includes(item.name)) return item;
        }), ...formatedFilteredData].sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
        return mergedData;
      }
    }

    return formatedFilteredData;
  }

  render() {
    return (
        <div
            style={{
              height: '100%',
            }}
        >

          <WidgetFunc
              data={this.formatDataTemp()}
              dictionary={this.props.dictionary}
              filter_name={this.props.filter_name}
              setFilters={this.setFilters}
              active_filter={this.props.active_filter}

          />

        </div>
    );
  }

  setFilters = (filter_name, filters) => {
    const map = 'mobile'
    console.log('filters widget', filters)
    if(filters.length > 0){
        if(filters.length === 1){
            this.props.dispatch(
                {
                    type: `geo/changeActiveFilter`,
                    payload: {
                        name: filter_name,
                        value: filters[0],
                    }
                }
            )

            if (filter_name === 'data') {
                this.props.dispatch(
                    {
                        type: `geo/subLayerSwitch`,
                        payload: {
                            name: filter_name,
                            value: filters[0],
                        }
                    }
                )
            }

        } else {

            if (filter_name === 'data') {
                this.props.dispatch(
                    {
                        type: `geo/changeActiveFilter`,
                        payload: {
                            name: filter_name,
                            value: filters[filters.length - 1],
                        }
                    }
                )
                if (filter_name === 'data') {
                    this.props.dispatch(
                        {
                            type: `geo/subLayerSwitch`,
                            payload: {
                                name: filter_name,
                                value: filters[filters.length - 1],
                            }
                        }
                    )
                }
            } else {

                this.props.dispatch(
                    {
                        type: `geo/changeActiveFilter`,
                        payload: {
                            name: filter_name,
                            value: filters,
                        }
                    }
                )
                if (filter_name === 'data') {
                    this.props.dispatch(
                        {
                            type: `geo/subLayerSwitch`,
                            payload: {
                                name: filter_name,
                                value: filters,
                            }
                        }
                    )
                }
            }
      }
    }
    if (filters == '') {

        if (filter_name === 'districts') {
            this.props.dispatch(
                {
                    type: `geo/changeActiveFilter`,
                    payload: {
                        name: filter_name,
                        value: [],
                    }
                }
            )
        }


    }
    // this.props.dispatch(
    //     {
    //       type: `geo/changeDPDynamicGradient`,
    //     }
    // )
  }
}

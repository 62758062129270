import React from "react";
import { BrowserRouter } from "react-router-dom"
import {Layout} from "./Layout/Layout";
import cookies from "react-cookies";
import {LoginLayoutContainer} from "./Layout/LoginLayout";


export const App = () => {

    return (<>
            <BrowserRouter>
                {
                    cookies.load('token')

                        ? <Layout /> : <LoginLayoutContainer />

                 }
            </BrowserRouter>
        </>
    )
}




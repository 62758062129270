import Button from "antd/es/button";
import Modal from "antd/es/modal/Modal";
import Form from "antd/es/form";
import {Row} from "antd";
import Input from "antd/es/input";
import generatePassword from "password-generator";

const layout = {
	labelCol: {span: 4},
	wrapperCol: {span: 18},
};

export const ClientsModal = (props) => {
	const {
		createClientsModal,
		updateClientsModal,
		onSubmit,
		fields,
		onCancel,
		clients,
		form
	} = props

	// FIXME Create url
	// const generateRandomPassword = () => {
	// 	let url = 'https://clavem.frontier.kz/invite?' + generatePassword(35, false);
	// 	form.setFieldsValue({url})
	// }
	//
	// form.setFieldsValue({url: ''})


	const formItem = {
		width: '30%',
		marginRight: '3%',
		display: 'block',
		// flexDirection: 'column'
	}
	const inputStyle = {
		borderRadius: '8px'
	}

	return (
		<Modal title={updateClientsModal ? "Update Client" : "Создать клиента"}
			   visible={updateClientsModal || createClientsModal}
			   destroyOnClose={true}
			   onCancel={onCancel}
			   style={{top: 30}}
			   footer={[
				   <div key={1}>
					   <Button onClick={onCancel} className='basic-btn' type={"primary"}>
						   Назад
					   </Button>
					   <Button form="clientsForm" type={"primary"} htmlType="submit" className='basic-btn' >
						   Готово
					   </Button>
				   </div>
			   ]}
			   width={850}>
			<Form
				id="clientsForm"
				{...layout}
				name="clientsForm"
				form={form}
				fields={fields}
				onFinish={onSubmit}
				layout={"vertical"}
			>

				<Row>
					<Form.Item
						label="Название"
						name="name"
						style={{...formItem, width: '30%'}}
						labelCol={{span: 24}}
						rules={[
							{
								required: true
							},
							() => ({
								validator(rule, value) {
									if (value && clients.findIndex(item => item.name.toLowerCase() === value.toLowerCase() && item.id !== updateClientsModal.id) !== -1) {
										return Promise.reject(new Error('Enter unique name!'));
									}
									return Promise.resolve()
								}
							})
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
					<Form.Item
						label="Адрес"
						name="address"
						labelCol={{span: 24}}
						style={{...formItem, width: '65%', marginRight: '0'}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
				</Row>

				<Row>
					<Form.Item
						label="Бин"
						name="bin"
						labelCol={{span: 24}}
						style={{...formItem}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
					<Form.Item
						label="Номер договора"
						name="contract_number"
						labelCol={{span: 24}}
						style={{...formItem}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
				</Row>
				{/*FIXME CREATE URL*/}
				{/*<Row align={'middle'}>*/}
				{/*	<Form.Item*/}
				{/*		label="Ссылка для входа"*/}
				{/*		name="url"*/}
				{/*		style={{...formItem}}*/}
				{/*	>*/}
				{/*		<Input style={{...inputStyle}}/>*/}
				{/*	</Form.Item>*/}
				{/*	/!*FIXME! check value*!/*/}
				{/*	<Button onClick={generateRandomPassword} style={{width: 'auto', backgroundColor: '#3C64B1', color: '#fff', borderRadius: '8px'}}>Сгенерировать ссылку</Button>*/}
				{/*</Row>*/}

				<Form.Item
					label="Описание"
					name="description"
					labelCol={{span: 24}}
					style={{...formItem, width: '70%'}}
				>
					<Input.TextArea style={{...inputStyle}} rows={3}/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

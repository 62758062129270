import {Component} from "react";
import {districts_dictionary} from "./MobileDoubleParentWidget/Districts/districts_dictionary";
import carto from '@carto/carto-vl';
import {connect} from "dva";

const colors = '' +
    '[rgba(0, 102, 255, 0.5),' +
    'rgba(0, 149, 255, 0.5),' +
    'rgba(71, 178, 255, 0.5),' +
    'rgba(94, 202, 239, 0.5),' +
    'rgba(240, 216, 30, 0.5),' +
    'rgba(255, 188, 0, 0.5),' +
    'rgba(255, 137, 3, 0.5),' +
    'rgba(255, 84, 0, 0.5),' +
    'rgba(255, 43, 0, 0.5),' +
    'rgba(255, 0, 0, 0.7)]'

const layer_counters_names = {
    $cbh_and_competitors_avg_receipt_amt: 'cbh_and_competitors_avg_receipt_amt',
    $cbh_and_no_competitors_avg_receipt_amt: 'cbh_and_no_competitors_avg_receipt_amt',
    $no_cbh_but_competitors_avg_receipt_amt: 'no_cbh_but_competitors_avg_receipt_amt',
    $others_only_avg_receipt_amt: 'others_only_avg_receipt_amt',
    $cbh_and_competitors_category_sales_amt: 'cbh_and_competitors_category_sales_amt',
    $cbh_and_no_competitors_category_sales_amt: 'cbh_and_no_competitors_category_sales_amt',
    $no_cbh_but_competitors_category_sales_amt: 'no_cbh_but_competitors_category_sales_amt',
    $others_only_category_sales_amt: 'others_only_category_sales_amt',
    $cbh_and_competitors_place_count: 'cbh_and_competitors_place_count',
    $cbh_and_no_competitors_place_count: 'cbh_and_no_competitors_place_count',
    $no_cbh_but_competitors_place_count: 'no_cbh_but_competitors_place_count',
    $others_only_place_count: 'others_only_place_count',
    $cbh_and_competitors_receipts_count: 'cbh_and_competitors_receipts_count',
    $cbh_and_no_competitors_receipts_count: 'cbh_and_no_competitors_receipts_count',
    $no_cbh_but_competitors_receipts_count: 'no_cbh_but_competitors_receipts_count',
    $others_only_receipts_count: 'others_only_receipts_count'
}

@connect((state) => {
    let sublayer = state.geo.almaty.active_sublayer
    if(state.geo.almaty.start_data?.zids?.features.length > 0){
        console.log('Zids current state: - ', state)
        let filtered_zids = []
        if (state.geo.almaty.active_filters.districts.length > 0) {

            if (state.geo.almaty.active_filters.districts.length === 1) {
                filtered_zids = state.geo.almaty.start_data.zids.features.filter(
                    item => item.properties.iddistrict == state.geo.almaty.active_filters.districts
                )
            } else {
                state.geo.almaty.start_data.zids.features.map(item => {
                    state.geo.almaty.active_filters.districts.map(el => {
                        if (item.properties.iddistrict == el) {
                            return filtered_zids.push(item)
                        }
                    })
                })
            }
        } else {
            filtered_zids = state.geo.almaty.start_data.zids.features
        }


        if (state.geo.almaty.active_filters.gradient.min != '' && state.geo.almaty.active_filters.gradient.max != '') {
            if (filtered_zids.length > 0) {
                let max = (state.geo.almaty.active_filters.gradient.max == 'infinity0.5' ?
                    10000000000000 : state.geo.almaty.active_filters.gradient.max)

                filtered_zids = filtered_zids.filter(
                    item => item.properties[sublayer] > state.geo.almaty.active_filters.gradient.min &&
                        item.properties[sublayer] <= max
                )

            }
        }

        if (layer_counters_names) {
            filtered_zids = filtered_zids.filter(item => item.properties[state.geo.almaty.active_sublayer] > 0)
        }




        return {
            zids: filtered_zids,
            current_layer: state.geo.almaty.active_layer,
            current_sublayer: state.geo.almaty.active_sublayer,
            dynamic_gradient: state.geo.almaty.start_data.dynamic_gradient.mobile

        }
    } else {
        return {
            zids: [],
            current_layer: 'receipts_count',
            current_sublayer: 'others_only_receipts_count',
            dynamic_gradient: []
        }
    }
})



class Zids extends Component {
    createLayer(update){
        window.current_layer = this.props.current_layer
        window.current_sublayer = '$' + this.props.current_sublayer
        let dynamic_buckets = this.props.dynamic_gradient[this.props.current_layer][this.props.current_sublayer].backets
        // let half_buckets = dynamic_buckets.map(item => item + 0.5)
        let half_buckets = dynamic_buckets
        let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
        let active_layer_abriviature_name = layer_counters_names[window.current_sublayer]

        let zids = JSON.parse(JSON.stringify(this.props.zids))
        let not_zero_counter_zids = this.props.zids.filter(item => item.properties[active_layer_abriviature_name])
        const new_layer = {
            // features: not_zero_counter_zids,
            features: zids,
            // features: this.props.zids.filter(item => item.properties[active_layer_abriviature_name]),
            type: "FeatureCollection"
        }


        const source = new carto.source.GeoJSON(new_layer)
        const viz = new carto.Viz(
            `
          	strokeWidth: 0
          	color: ramp(buckets(${window.current_sublayer}, ${final_dynamic_buckets}), ${colors})
         	@avg_categ_sales_amt: $avg_categ_sales_amt
			@avg_categ_sales_qty: $avg_categ_sales_qty
			@avg_receipt_amt: $avg_receipt_amt
			@buratino_avg_price: $buratino_avg_price
			@buratino_percent: $buratino_percent
			@buratino_receipt_count: $buratino_receipt_count
			@cartodb_id: $cartodb_id
			@category_sales_amt: $category_sales_amt
			@category_sales_qty: $category_sales_qty
			@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
			@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
			@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
			@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
			@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
			@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
			@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
			@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
			@cola_avg_price: $cola_avg_price
			@cola_percent: $cola_percent
			@cola_receipt_count: $cola_receipt_count
			@extra_big_count: $extra_big_count
			@iddistrict: $iddistrict
			@iddistrict_area: $iddistrict_area
			@kkm_count: $kkm_count
			@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
			@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
			@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
			@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
			@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
			@organisation_count: $organisation_count
			@other_avg_price: $other_avg_price
			@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
			@others_only_category_sales_amt: $others_only_category_sales_amt
			@others_only_place_count: $others_only_place_count
			@others_only_receipts_count: $others_only_receipts_count
			@others_percent: $others_percent
			@others_receipt_count: $others_receipt_count
			@pepsi_avg_price: $pepsi_avg_price
			@pepsi_percent: $pepsi_percent
			@pepsi_receipt_count: $pepsi_receipt_count
			@pop_count: $pop_count
			@receipts_count: $receipts_count
			@receipts_per_thousand_people: $receipts_per_thousand_people
			@retail_place_count: $retail_place_count
			@retail_places_per_person: $retail_places_per_person
			@segment_azs_count: $segment_azs_count
			@segment_azs_count_2gis: $segment_azs_count_2gis
			@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
			@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
			@segment_coffee_shop_count: $segment_coffee_shop_count
			@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
			@segment_mini_super_market_count: $segment_mini_super_market_count
			@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
			@segment_other_count: $segment_other_count
			@segment_other_count_2gis: $segment_other_count_2gis
			@segment_relevant_count: $segment_relevant_count
			@segment_relevant_count_2gis: $segment_relevant_count_2gis
			@segment_smoking_market_count: $segment_smoking_market_count
			@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
			@total_amount: $total_amount
			@total_receipt_count: $total_receipt_count
			@zid_id: $zid_id
        `
        )

        if (window.others_only_place_count) {
            window.others_only_place_count.update(source, viz)
        } else {
            const others_only_place_count = new carto.Layer('others_only_place_count', source, viz);
            window.others_only_place_count = others_only_place_count
        }

        window.zids_interactivity = new carto.Interactivity(window.others_only_place_count);
        const delay = 50;
        let clickedFeatureId = null;
        zids_interactivity.on('featureClick', (event) => {
            if (event.features.length) {
                const feature = event.features[0];
                clickedFeatureId = feature.id;
                feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
                window.feature = feature
            }
        })

        zids_interactivity.on('featureClickOut', event => {
            if (event.features.length) {
                // flyOut(coordinates)
                const feature = event.features[0];
                clickedFeatureId = '';
                feature.color.reset(delay);
                window.feature = feature
            }
        });

        function updatePopupMobileZids(event) {
            const popup = window.zids_popup
            setTimeout(() => {
                if (event.features.length > 0) {
                    const vars = event.features[0].variables;
                    let current_layer = window.current_layer
                    const current_zid = vars.zid_id.value
                    popup.setHTML(`
                  <div>
                      <h3>Информация о секторе</h3>
                      <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
                      	<div>
							  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
							  <p className="description open-sans" style="${current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
							  <p className="description open-sans" style="${current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
							  <p className="description open-sans" style="${current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
							  <p className="description open-sans" style="${current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
							  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
							  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  </ul>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
							  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
							  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
							  </ul>
							  </ul>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
						</div>
					</div>
                  </div>
              `);
                    popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
                    if (!popup.isOpen()) {
                        popup.addTo(map);
                    } else {
                        if (event.coordinates.lng == popup._lngLat.lng &&
                            event.coordinates.lat == popup._lngLat.lat) {
                        } else {
                            popup.remove();
                        }
                    }
                } else {
                    popup.remove();
                }
            }, 100)
        }
        zids_interactivity.on('featureClick', updatePopupMobileZids);
        window.others_only_place_count.addTo(window.map);
    }

    componentDidMount() {
        // if(this.props.dataset.start_data?.zids?.features > 0){
        //     this.createLayer(false)
        // }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.zids?.length > 0){
            this.createLayer(false)
        }

    }

    render() {
        return null
    }
}

export default Zids;

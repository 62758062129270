import notification from "antd/es/notification";

export const showError = (error) => {
	try {
		notification.error({
			message: "Error",
			description: error.response.data.detail,
			placement: 'topRight'
		});
	} catch (e) {
		notification.error({
			message: "Error",
			description: 'Unknown Error',
			placement: 'topRight'
		});
	}
}

import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {infoActions} from "./infoActions";
import {infoApi} from "./infoApi";
import {showError} from "../../Tools/showError";

export const infoModel = {
	namespace: 'info',
	state: {
		data: {
			currentUser: null,
			productTypes: [
				{id: 1, type: 'files', name: 'Files'},
				{id: 2, type: 'dashboard', name: 'Dashboard'},
				{id: 3, type: 'map', name: 'Map'}
			]
		},
		CRUDStatus: null
	},
	reducers: {
		getInfoPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getInfoSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: {
					...state.data,
					...payload
				}
			}
		},
		getInfoError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getcurrentUserPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getcurrentUserSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: {
					...state.data,
					currentUser: payload
				}
			}
		},
		getcurrentUserError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
	},

	effects: {
		* getInfoFetch({payload}, {put, call}) {
			yield put(infoActions.getInfoPending())
			try {
				const info = yield call(() => infoApi.getInfo())
				yield put(infoActions.getInfoSuccess(info))
			} catch (e) {
				showError(e)
				yield put(infoActions.getInfoError())

			}
		},

		* getcurrentUserFetch({payload}, {put, call}) {
			yield put(infoActions.getcurrentUserPending())
			try {
				const user = yield call(() => infoApi.getCurrentUser())
				yield put(infoActions.getcurrentUserSuccess(user))

			} catch (e) {
				showError(e)
				yield put(infoActions.getcurrentUserError())

			}
		},
	}
}

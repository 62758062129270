import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {clientsActions} from "./clientsActions";
import {clientsApi} from "./clientsApi";
import {showError} from "../../Tools/showError";

export const clientsModel = {
	namespace: 'clients',
	state: {
		data: [ ],
		CRUDStatus: null
	},
	reducers: {
		getClientsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getClientsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: payload
			}
		},
		getClientsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		createClientsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		createClientsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		createClientsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
		changeClientsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		changeClientsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data.map(item => item.id === payload.id ?
						{
							...payload,
							key: `item-${item.id}`
						} : item)
				]
			}
		},
		changeClientsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		deleteClientsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		deleteClientsSuccess(state, {payload}) {
			return {
				...state,
				data: [
					...state.data.filter(item => item.id !== payload)
				],
				CRUDStatus: SUCCESS
			}
		},
		deleteClientsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
	},

	effects: {

		* deleteClientsFetch({payload}, {put, call}) {
			yield put(clientsActions.deleteClientsPending())
			try {
				const clientId = yield call(() => clientsApi.deleteClient(payload))

				yield put(clientsActions.deleteClientsSuccess(clientId))

			} catch (e) {
				showError(e.message)
				yield put(clientsActions.deleteClientsError())
			}

		},

		* changeClientsFetch({payload}, {put, call}) {
			yield put(clientsActions.changeClientsPending())
			try {
				const client = yield call(() => clientsApi.changeClient(payload.client, payload.id))
				yield put(clientsActions.changeClientsSuccess(client))
			} catch (e) {
				showError(e)
				yield put(clientsActions.changeClientsError())
			}
		},

		* createClientsFetch({payload}, {put, call}) {
			yield put(clientsActions.createClientsPending())
			try {
				const client = yield call(() => clientsApi.createClient(payload))
				yield put(clientsActions.createClientsSuccess(client))
			} catch (e) {
				showError(e)
				yield put(clientsActions.createClientsError())
			}
		},

		* getClientsFetch({payload}, {put, call}) {
			yield put(clientsActions.getClientsPending())
			try {
				const clients = yield call(() => clientsApi.getClientsList(payload))
				yield put(clientsActions.getClientsSuccess(clients))

			} catch (e) {
				showError(e)
				yield put(clientsActions.getClientsError())

			}
		},
	}
}

import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {mapActions} from "./mapActions";
import {mapApi} from "./mapApi";
import {showError} from "../../Tools/showError";
import {geoModel} from "./geoModel"

export const mapModel = {
	namespace: 'map',
	state: {
		data: [],
		dataset: {},
		CRUDStatus: null
	},
	reducers: {
		mapPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		mapSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					...payload
				]
			}
		},
		mapError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getGeoPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getGeoSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					...payload
				]
			}
		},
		getGeoError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		datasetPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		datasetSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				dataset: payload
			}
		},
		datasetError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

	},


	effects: {

		* datasetFetch({payload}, {take, select, put, call}) {
			yield put(mapActions.datasetPending())
			try {

				yield put(geoModel.getGeoFetch())
				// yield put({type: 'products/getProductsForCurrentUserFetch'})
				// yield take(['products/getProductsForCurrentUserSuccess'])
				// yield take(['products/getProductsForCurrentUserSuccess'])

				const {view} = yield select(state => state.products)

				const dataset = view.viewData.find(product => product.id === parseInt(payload.id)).datasets[0]

				const datasetExport = yield call(() => mapApi.getExportDataset(dataset.id, payload.randomMath))

				yield put(mapActions.datasetSuccess(datasetExport))
			} catch (e) {
				yield put(mapActions.mapError())
			}
		},

		// * datasetFetch({payload}, {take, select, put, call}) {
		// 	yield put(mapActions.datasetPending())
		// 	try {
		// 		// yield put({type: 'products/getProductsForCurrentUserFetch'})
		// 		// yield take(['products/getProductsForCurrentUserSuccess'])
		//
		// 		const {view} = yield select(state => state.products)
		//
		// 		const dataset = view.viewData.find(product => product.id === parseInt(payload.id)).datasets[0]
		//
		// 		const datasetExport = yield call(() => mapApi.getExportDataset(dataset.id, payload.randomMath))
		//
		// 		yield put(mapActions.datasetSuccess(datasetExport))
		// 	} catch (e) {
		// 		yield put(mapActions.mapError())
		// 	}
		// },

		* mapFetch({payload}, {put, call}) {
			yield put(mapActions.mapPending())
			try {
				const data = yield call(() => mapApi.getMapData(payload.dateFrom, payload.dateTo))
				yield put(mapActions.mapSuccess(data))

			} catch (e) {
				showError(e)
				yield put(mapActions.mapError())

			}
		},


		* getGeoFetch({payload}, {put, call}) {
			yield put(mapActions.getGeoPending())
			try {
				const data = yield call(() => mapApi.getGeoData(payload.dateFrom, payload.dateTo))

				yield put(mapActions.getGeoSuccess(data))

			} catch (e) {
				showError(e)
				yield put(mapActions.getGeoError())

			}
		},
	}
}

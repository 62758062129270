import {instance} from "../../api/api";

export const mapApi = {
	getMapData: (dateFrom, dateTo) => {
		return instance().post(`/url/`, {dateFrom, dateTo}).then(res => res.data)
	},
	getExportDataset: (id, randomMath) => {
		// return instance().get(`/datasets/${id}/export?q=${randomMath}`).then(res => res.data)
		// return instance().get(`/datasets/23/export?q=${randomMath}`).then(res => res.data)
		return instance().get(`/datasets/export/23?q=${randomMath}`).then(res => res.data)
	},

	getGeoData: (dateFrom, dateTo) => {
		// return instance().get(`/datasets/${id}/export?q=${randomMath}`).then(res => res.data)
		// return instance().get(`/datasets/23/export?q=${randomMath}`).then(res => res.data)
		return instance().get(`/datasets/export/23?q=${randomMath}`).then(res => res.data)
	},
};

import React from "react";
import style from "./LayerChanger.css";
import {connect} from "dva";
// import {my_off_accent_color} from '../../../utils/accent_color'
import Select from "antd/es/select";
import {sub} from "@carto/carto-vl/src/utils/geometry";
// import { flyOut } from '../../../../popup_events';
// import { mapName, mobileMapModelStateName } from '../../../utils/map-variables';





@connect((state) => {
    // if(state.mobile[mobileMapModelStateName()].active_layer) {
    //
    //     return {
    //         active_layer: state.mobile[mobileMapModelStateName()].active_layer,
    //         DPCategory: state.mobile[mobileMapModelStateName()].DPCategory,
    //         map_name: state.mobile[mobileMapModelStateName()].map_name,
    //         mode_DP: state.mobile[mobileMapModelStateName()].mode_DP,
    //     }
    // }
    // return {
    //     active_layer: 'region',
    //     DPCategory: 'pop_dist',
    //     map_name: state.mobile[mobileMapModelStateName()].map_name,
    //     mode_DP: state.mobile[mobileMapModelStateName()].mode_DP,
    // }
    return {
        active_layer: 'receipts_count',
        DPCategory: 'pop_dist',
        map_name: 'mobile_almaty',
        mode_DP: 'deficit',
    }
})

// export default class MobileLayerSelection extends React.Component {
class MobileLayerSelection extends React.Component {
    TabSwitch = (selectedOption) => {
        console.log('selectedOption', selectedOption)
        let coordinates = [76.9286100, 43.2566700]

        if(document.getElementsByClassName("mapboxgl-popup-content").length > 0){
            window.popupRoadViolation._pos ? window.popupRoadViolation.remove() : ''
            window.mobile_zids_popup._pos ? window.mobile_zids_popup.remove() : ''
            window.mobile_zids_population_popup._pos ? window.mobile_zids_population_popup.remove() : ''
            window.popupSergek._pos ? window.popupSergek.remove() : ''
            window.popupCrimes._pos ? window.popupSergek.remove() : ''
        }
        if(window.map.getZoom() > 10){
            // FIXME
            // flyOut(coordinates)

        }
        let value = selectedOption
        let sublayer_value = ''
        if (selectedOption == 0) {
            value = 'place_count'
            sublayer_value = 'cbh_and_competitors_place_count'
        }
        if (selectedOption == 1) {
            value = 'avg_receipt_amt'
            sublayer_value = 'cbh_and_no_competitors_avg_receipt_amt'
        }
        if (selectedOption == 2) {
            value = 'category_sales_amt'
            sublayer_value = 'cbh_and_competitors_category_sales_amt'
        }
        if (selectedOption == 3) {
            value = 'receipts_count'
            sublayer_value = 'cbh_and_no_competitors_receipts_count'
        }

        this.props.dispatch(
            {
                type: 'geo/changeMapLayer',
                // payload: selectedOption.payload
                payload: value
            }
        )

        this.props.dispatch(
            {
                type: 'geo/changeActiveFilter',
                payload: {
                    name: 'gradient',
                    value: {min: '', max: ''}
                }
            }
        )

        this.props.dispatch(
            {
                type: `geo/changeActiveFilter`,
                payload: {
                    name: 'data',
                    value: sublayer_value,
                }
            }
        )
            // FIXME GO TO WIDGET
        // this.props.dispatch(
        //     {
        //         type: 'geo/changeSubLayerEffect',
        //         // payload: selectedOption.payload
        //         payload: value
        //     }
        // )
    }
    render() {

        const options = [
            { value: 0, label: 'Торговые точки', payload: 'place_count' },
            { value: 1, label: 'Средний чек', payload:  'avg_receipt_amt'},
            { value: 2, label: 'Сумма продаж', payload: 'category_sales_amt' },
            { value: 3, label: 'Количество чеков', payload: 'receipts_count' },
        ]

        // if(this.props.map_name == 'Pavlodar') {
        //     options.pop()
        // }

        const chosenLayer = {
            place_count: 0,
            avg_receipt_amt: 1,
            category_sales_amt: 2,
            receipts_count: 3
        }
        const chosenCategory = {
            pop_dist: 0,
            dist_5: 1,
        }

        return (
            <div className="wrapper">
                <div className="dropdown">
                    <Select className={`select_ant_single select_ant_map select_container`} defaultValue={chosenLayer[this.props.active_layer]} onChange={this.TabSwitch}>
                        <Option className="select_ant_dropdown" value={0}>Торговые точки</Option>
                        <Option className="select_ant_dropdown" value={1}>Средний чек</Option>
                        <Option className="select_ant_dropdown" value={2}>Сумма продаж</Option>
                        <Option className="select_ant_dropdown" value={3}>Количество чеков</Option>
                        {/*{mapName() === 'MobilePavlodar' ? ''  :  <Option className="select_ant_dropdown" value={3}>Население</Option>}*/}
                        {/*{mapName() === 'MobilePavlodar' || window.location.pathname.includes('public') ? ''  :  <Option className="select_ant_dropdown" value={4}>Дефицит - Профицит</Option>}*/}

                    </Select>
                </div>
            </div>
        )
    }

    /* componentDidUpdate(prevProps, prevState, snapshot) {
       console.log('layer changer', this.props.active_layer.toString())
       this.props.dispatch(
         {
           type: 'mobile/popupStatus',
           payload: {
             name: this.props.active_layer.toString(),
             value: this.props.active_layer == "region" ? true : false ||
                    this.props.active_layer == "city" ? true : false ||
                    this.props.active_layer == "selfiso" ? true : false ||
                    this.props.active_layer == "population" ? true : false
           }
         }
       )
     }*/
}


export default MobileLayerSelection;

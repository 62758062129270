import React from 'react';
import style from './MobileRightbar.css';
import DistrictHistogramWidget from "../DistrictWidget/DistrictHistogramWidget"
import DataHistogramWidget from "../DataWidget/DataHistogramWidget"
import DistrictsWidgetWrapper from "../MobileDoubleParentWidget/Districts/DistrictsWidgetWrapper";
import DataWidgetWrapper from "../MobileDoubleParentWidget/Data/DataWidgetWrapper";
import { connect } from 'dva';

@connect((state) => {
  if (state.geo.almaty.start_data) {
    if (state.geo.almaty.start_data.city || state.geo.almaty.start_data.road || state.geo.almaty.start_data.types
        || state.geo.almaty.start_data.provinces || state.geo.almaty.start_data.sergek || state.geo.almaty.start_data.crime) {
      return {
        districts: state.geo.almaty.start_data.city.districts.length,
        district_area: state.geo.almaty.start_data.city.district_area.length,
        district_hours: state.geo.almaty.start_data.city.hours.length,
        road_is_public: state.geo.almaty.start_data.road.road_is_public.length,
        road_district: state.geo.almaty.start_data.road.road_district.length,
        road_info: state.geo.almaty.start_data.road.road_violation_info.length,
        road_types: state.geo.almaty.start_data.road.road_violation_types.length,
        //fixme old api
        // types: state.mobile.start_data.types.types.length,
        //fixme new api
        types: state.geo.almaty.start_data.types.length,
        provinces: state.geo.almaty.start_data.provinces.provinces.length,
        provinces_way: state.geo.almaty.start_data.provinces.provinces_ways.length,
        //FIXME new api
        // sergek: state.mobile.start_data.sergek.sergek_data.length,
        crimes_hardcode: state.geo.almaty.start_data.crime.crimes_hardcode.length,
        crimes_district: state.geo.almaty.start_data.crime.crimes_district.length,
        crimes_stat: state.geo.almaty.start_data.crime.crimes_stat.length,
        active_filters: state.geo.almaty.active_filters,
        active_layer: state.geo.almaty.active_layer,
        map_name: 'almaty',
        mode_DP: state.geo.almaty.mode_DP,
      };
    }
    return {
      active_filters: state.geo.almaty.active_filters,
      active_layer: state.geo.almaty.active_layer,
      map_name: 'almaty',
      mode_DP: state.geo.almaty.mode_DP,
    };

  }

})

class MobileTabsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: 0,
      deficit: 0
    };
  }

  TabSwitch = (e) => {

    let content_scroll_id = e.target.getAttribute('data-scroll');
    let content_offset = document.getElementById(content_scroll_id).offsetLeft;
    let row = document.getElementById('map_tab_row');
    // let content_row = document.getElementById('mobile_column_row')

    // if (!e.target.hasAttribute('data-scrollcheck')) {
    // if (!e.target.getAttribute('data-scrollcheck').length > 0) {
    //   content_row.scrollTo({left: content_offset})
    // }

    this.setState({
      ...this.state,
      active_tab: +e.target.getAttribute('data-id')
    });

    // this.props.dispatch(
    //   {
    //     type: 'mobile/setRightTopNavActiveTab',
    //     payload: +e.target.getAttribute('data-id')
    //   }
    // )
  };

  componentDidUpdate(prevProps){
    if(this.props.active_layer !== prevProps.active_layer){
      if(this.props.active_layer === 'deficit' || this.props.active_layer === 'proficit'){
        this.setState(state => {
          return {
            ...state,
            active_tab: 8,
          }
        })
      } else {
        this.setState(state => {
          return {
            ...state,
            active_tab: 0,
          }
        })
      }
    }
  }

  render(props) {
    const active_class_tab = style.tab_button + ' ' + style.tab_button_active;
    const active_class_tab_activity = style.tab_button + ' ' + style.tab_button_active_activity;
    const active_class_tab_business = style.tab_button + ' ' + style.tab_button_active_business;
    const active_class_tab_region = style.tab_button + ' ' + style.tab_button_active_region;
    const active_class_tab_sergek = style.tab_button + ' ' + style.tab_button_active_sergek;
    const active_class_tab_crimes = style.tab_button + ' ' + style.tab_button_active_crimes;
    const active_class_tab_dtp = style.tab_button + ' ' + style.tab_button_active_dtp;
    const active_class_tab_deficit = style.tab_button + ' ' + style.tab_button_active_deficit;

    const default_class_tab = style.tab_button;
    const default_class_tab_activity = style.tab_button + ' ' + style.tab_button_activity;
    const default_class_tab_business = style.tab_button + ' ' + style.tab_button_business;
    const default_class_tab_region = style.tab_button + ' ' + style.tab_button_region;
    const default_class_tab_sergek = style.tab_button + ' ' + style.tab_button_sergek;
    const default_class_tab_crimes = style.tab_button + ' ' + style.tab_button_crimes;
    const default_class_tab_dtp = style.tab_button + ' ' + style.tab_button_dtp;
    const default_class_tab_deficit = style.tab_button + ' ' + style.tab_button_deficit;

    const animation_left = style.animation_left;
    const animation_right = style.animation_right;
    const active_class_tab_content = style.column_box + ' ' + style.column_box_active;
    const default_class_tab_content = style.column_box;

    const active_badge = style.activeBadge;
    const inActiveBadge = style.inActiveBadge;
    const noneBadge = style.badge;
    const activeTab = this.state.active_tab;
    const notAllowed = style.not_allowed_dtp;

    const { active_filters } = this.props;
    let height = '100%';
    if (window.location.href.includes('public')) {
      height = 'calc(100vh - 100px)';
    }

    // if (this.props.active_layer == 'deficit') {
    //   if (this.state.active_tab != 8) {
    //     if (this.state.deficit == 0) {
    //       this.setState({
    //         ...this.state,
    //         active_tab: 8,
    //         deficit: 1
    //       });
    //     }
    //
    //   }
    // } else {
    //   if (this.state.active_tab == 8) {
    //     if (this.state.deficit == 1) {
    //       this.setState({
    //         ...this.state,
    //         active_tab: 1,
    //         deficit: 0
    //       });
    //     }
    //   }
    //
    // }

    return (
        <div
            className="tabs"
          style={{
            height: '100%'
          }}
        >

          <div className="column_row" id="mobile_column_row" style={{ height: height }}>
            <div
                id="map_tab_0"
                className="active_class_tab_content"
                style={{
                  width: '100%'
                }}
            >
              <DataWidgetWrapper />
              <DistrictsWidgetWrapper />
            </div>
          </div>
        </div>
    );
  }
}


export default MobileTabsMap;

import Modal from "antd/es/modal/Modal";
import Form from "antd/es/form";
import {DatePicker, Select, Space} from "antd";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import * as PropTypes from "prop-types";
import {usersActions} from "../../../../models/usersModel/usersActions";
import {connect} from "dva";
import {BindProductsToUserList} from "../components/BindProductsToUserList";
import Card from "antd/es/card";
import {formatDate} from "../../../../Tools/refactorDate";
import {useEffect} from "react";


const {Option} = Select


const { RangePicker } = DatePicker;

RangePicker.propTypes = {
	onOk: PropTypes.any,
	onChange: PropTypes.any,
	showTime: PropTypes.shape({format: PropTypes.string}),
	format: PropTypes.string
};
const BindProductsToUserConnect = (props) => {
	const {
		bindProductsToUserModal,
		dispatch,
		onCancel,
		userProducts,
		products,
		onDeleteBindUser
	} = props

	const [form] = Form.useForm();

	const bindProducts = (product) => {
		const payload = {
			products_id: product.products,
			from_date: formatDate(product.date_from_to[0]),
			to_date: formatDate(product.date_from_to[1]),
			users_id: [bindProductsToUserModal.id]
		}
		dispatch(usersActions.setProductsToUserFetch(payload))
		form.resetFields()
	}

	return (
		<>
			<Modal title="Set/Edit bind products"
				   destroyOnClose={true}
				   visible={bindProductsToUserModal}
				   onCancel={onCancel}
				   width={1000}
				   footer={[
					   <Button key="hide" type="primary" onClick={onCancel}>
						   Hide
					   </Button>,
				   ]}
			>
				<Form
					id="productsForm"
					form={form}
					layout={'inline'}
					name="productsForm"
					onFinish={bindProducts}
					style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}
				>
					<Form.Item
						label="Products"
						name="products"
						rules={[{required: true, message: 'Enter products!'}]}
						style={{width: 400}}
					>
						<Select
							showSearch
							allowClear
							mode={'multiple'}
							placeholder="Search to Select"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{products.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						label="Date"
						name="date_from_to"
						style={{width: '40%'}}
					>
						<RangePicker
							showTime={{ format: 'HH:mm' }}
							format="YYYY-MM-DD HH:mm"
						/>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit">Add <PlusOutlined/></Button>
					</Form.Item>
				</Form>

				<Card style={{marginTop: 30}}>
					<BindProductsToUserList
						userProducts={userProducts}
						onDeleteBindUser={onDeleteBindUser}
						products={products}
					/>
				</Card>
			</Modal>
		</>
	)
}

function mapStateToProps(state) {
	return {
		userProducts: state.users.userProducts
	}
}

export const BindProductsToUserModal = connect(mapStateToProps)(BindProductsToUserConnect);


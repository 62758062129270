const datasetsTypes = {
	getDatasetsFetch: 'datasets/getDatasetsFetch',
	getDatasetsPending: 'getDatasetsPending',
	getDatasetsSuccess: 'getDatasetsSuccess',
	getDatasetsError: 'getDatasetsError',

	createDatasetsFetch: 'datasets/createDatasetsFetch',
	createDatasetsPending: 'createDatasetsPending',
	createDatasetsSuccess: 'createDatasetsSuccess',
	createDatasetsError: 'createDatasetsError',

	changeDatasetsFetch: 'datasets/changeDatasetsFetch',
	changeDatasetsPending: 'changeDatasetsPending',
	changeDatasetsSuccess: 'changeDatasetsSuccess',
	changeDatasetsError: 'changeDatasetsError',

	deleteDatasetsFetch: 'datasets/deleteDatasetsFetch',
	deleteDatasetsPending: 'deleteDatasetsPending',
	deleteDatasetsSuccess: 'deleteDatasetsSuccess',
	deleteDatasetsError: 'deleteDatasetsError',

	getExportDatasetFileFetch: 'datasets/getExportDatasetFileFetch',
	getExportDatasetFilePending: 'getExportDatasetFilePending',
	getExportDatasetFileSuccess: 'getExportDatasetFileSuccess',
	getExportDatasetFileError: 'getExportDatasetFileError',
}

export const datasetsActions = {

	getExportDatasetFileFetch: (id, randomMath) => {
		return {
			type: datasetsTypes.getExportDatasetFileFetch,
			payload: {id, randomMath}
		}
	},


	getExportDatasetFilePending: () => {
		return {
			type: datasetsTypes.getExportDatasetFilePending,
		}
	},

	getExportDatasetFileSuccess: (payload) => {
		return {
			type: datasetsTypes.getExportDatasetFileSuccess,
			payload: payload
		}
	},

	getExportDatasetFileError: () => {
		return {
			type: datasetsTypes.getExportDatasetFileError,
		}
	},

	deleteDatasetsFetch: (id) => {
		return {
			type: datasetsTypes.deleteDatasetsFetch,
			payload: id
		}
	},
	deleteDatasetsPending: () => {
		return {
			type: datasetsTypes.deleteDatasetsPending
		}
	},
	deleteDatasetsSuccess: (id) => {
		return {
			type: datasetsTypes.deleteDatasetsSuccess,
			payload: id
		}
	},
	deleteDatasetsError: () => {
		return {
			type: datasetsTypes.deleteDatasetsError
		}
	},

	changeDatasetsFetch: (dataset, id) => {
		return {
			type: datasetsTypes.changeDatasetsFetch,
			payload: {dataset, id}
		}
	},
	changeDatasetsPending: () => {
		return {
			type: datasetsTypes.changeDatasetsPending
		}
	},
	changeDatasetsSuccess: (client) => {
		return {
			type: datasetsTypes.changeDatasetsSuccess,
			payload: client
		}
	},
	changeDatasetsError: () => {
		return {
			type: datasetsTypes.changeDatasetsError
		}
	},

	createDatasetsFetch: (payload) => {
		return {
			type: datasetsTypes.createDatasetsFetch,
			payload: payload
		}
	},
	createDatasetsPending: () => {
		return {
			type: datasetsTypes.createDatasetsPending
		}
	},
	createDatasetsSuccess: (payload) => {
		return {
			type: datasetsTypes.createDatasetsSuccess,
			payload: payload
		}
	},
	createDatasetsError: () => {
		return {
			type: datasetsTypes.createDatasetsError
		}
	},


	getDatasetsFetch: (query = '') => {
		return {
			type: datasetsTypes.getDatasetsFetch,
			payload: query
		}
	},

	getDatasetsPending: () => {
		return {
			type: datasetsTypes.getDatasetsPending,
		}
	},

	getDatasetsSuccess: (payload) => {
		return {
			type: datasetsTypes.getDatasetsSuccess,
			payload: payload
		}
	},

	getDatasetsError: () => {
		return {
			type: datasetsTypes.getDatasetsError,
		}
	},


}

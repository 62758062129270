import {Divider, List, Space, Tooltip} from "antd";
import Title from "antd/es/typography/Title";

export const UserInterfaceHelpListComponent = (props) => {
    // const {
    //
    // } = props

    return (
        <>
            <Title level={3} className={'layout_page_header'} style={{fontWeight:'500'}}>
                Помощь
            </Title>
            <Divider/>
            <div className='container'>
                <p>
                    Уважаемые пользователи! В случае возникновения вопросов или проблем, просим Вас обращаться по контактам ниже:
                </p>
                <p style={{fontWeight: '500', display: 'flex'}}>
                    Телефон:
                    <a href="tel:+77017274240" style={{display: 'block',marginLeft: '5px', color: '#292974'}}>
                        + 7 (701) 727 42 40
                    </a>
                </p>

                <p style={{fontWeight: '500', display: 'flex'}}>
                    E-mail:
                    <a href="mailto:tbekshin@saudata.kz" style={{display: 'block', marginLeft: '5px', color: '#292974'}}>
                        tbekshin@saudata.kz
                    </a>
                </p>
            </div>

        </>
    )
}

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
	// Автоматическое определение языка
	.use(LanguageDetector)
	// модуль инициализации
	.use (initReactI18next)
	.init({
		react: {
			useSuspense: false
		},
		// Стандартный язык
		fallbackLng: 'ru',
		debug: true,
		interpolation: {
			escapeValue: false
		}
	})

export default i18n;

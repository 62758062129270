import {Popconfirm, Table, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import {Row} from "antd";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import CopyOutlined from "@ant-design/icons/CopyOutlined"
import Button from "antd/es/button";
import {formatDate} from "../../../../Tools/refactorDate";
import {PENDING} from "../../../../models/globalStatuses";
import {useClipboard} from "use-clipboard-copy";
export const ClientsListComponent = (props) => {
	const {
		clients,
		onOpenModal,
		onDelete,
		CRUDStatus
	} = props

	const clipboard = useClipboard();

	const copyUrl = (value) => {
		const a = value?.split('\\')
		clipboard.copy(a[0] + '//' + a[1] + '/' + a[2])
	}

	const makeUrl = (value) => {
		const a = value?.split('\\')
		return a[0] + '//' + a[1] + '/' + a[2]
	}

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',

		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			key: 'address',
			render: (_, client) => (client.address || '-')
		},
		{
			//FIXME Index and key must be changed
			title: 'Номер договора',
			dataIndex: 'agreement_num',
			key: 'agreement num',
			render: (_, client) => (client.contract_number || '-')
		},
		{
			//FIXME Index and key must be changed
			title: 'БИН',
			dataIndex: 'bin',
			key: 'bin',
			render: (_, client) => (client.bin || '-')
		},
		/*{
			//FIXME Index and key must be changed
			title: 'Создано',
			dataIndex: 'created_by',
			key: 'created_by'
		},*/
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
			render: (_, client) => (client.description || '-')
		},
		{
			title: 'Дата создания',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (_, client) => `${formatDate(client.created_at)}`
		},
		// {
		//	FIXME This column is not in Figma
		// 	title: 'Дата изменения',
		// 	dataIndex: 'updated_at',
		// 	key: 'updated_at',
		// 	render: (_, client) => `${formatDate(client.updated_at)}`
		// },
		{
			//FIXME Need to check if prop is right
			title: 'Ссылка для входа',
			dataIndex: 'registration_link',
			key: 'registration_link',
			// render: (_, url) => {
				// debugger
				// console.log('url', url.registration_link.toString())
				// return url
			// },
			// render: (_, url) => `${url.registration_link.toString()}`
			render: (_, url) => makeUrl(url.registration_link)

		},
		{
			title: 'Действия',
			align: 'center',
			key: 'actions',
			render: (_, client) => (
				<Button.Group>
						<Tooltip placement="topLeft" title={'Edit client'}>
								<Button type="default" onClick={() => onOpenModal('update', client)}>
									<EditOutlined/>
								</Button>
						</Tooltip>
						<Popconfirm
							title="Are you sure to delete this Image?"
							onConfirm={() => onDelete(client.id)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip placement="topLeft" title={'Delete this item'}>
								<Button type="primary" danger>
									<DeleteOutlined/>
								</Button>
							</Tooltip>
						</Popconfirm>

					<Tooltip placement="topLeft" title={'Copy link'}>
						<Button type="default" onClick={() => copyUrl(client?.registration_link)}>
							<CopyOutlined />
						</Button>
					</Tooltip>


				</Button.Group>
			),
			width: 120
		},
	];

	const sideBarMenuItems = [ //FIXME action for page after refactor remove this code fragment
		{
			title: 'Create',
			icon:  <PlusCircleOutlined/>,
			action: () => onOpenModal('create')
		}
	]

	return (
		<>
			<Row justify={"space-between"}>
				<Title level={3} className={'layout_page_header'} style={{fontWeight: '500',}}>Компании (Клиенты)</Title>
				<Button type="default" style={{borderRadius:'10px', alignSelf:'center'}} onClick={() => onOpenModal('create')}>
					Добавить Клиента
				</Button>

			</Row>
			<div className='container'>
				<Table
					columns={columns}
					dataSource={clients}
					rowKey="id"
					loading={CRUDStatus === PENDING}
					style={{userSelect: 'text'}}
				/>
			</div>
		</>
	)
}

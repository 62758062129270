import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import styles from './Widget.css';

const WidgetFunc = (props) => {
  const [widgetActiveFilter, setWidgetActiveFilter] = useState([]);
  const [chosenItems, setChosenItems] = useState([]);
  const [itemsArray, setItemsArray] = useState([]);
  const containerRef = React.createRef();

  const onSelect = (e) => {
    let itemValue = '';
    let itemNode;
    if (e.target.classList.contains('widget_container')) {
      itemValue = e.target.firstChild.firstChild.innerText;
      itemNode = e.target;
    } else if (e.target.classList.contains('widget_text')) {
      itemValue = e.target.parentNode.parentNode.firstChild.firstChild.innerText;
      itemNode = e.target.parentNode.parentNode;
    } else {
      itemValue = e.target.parentNode.firstChild.firstChild.innerText;
      itemNode = e.target.parentNode;
    }
    const dictionaryKey = Object.entries(props.dictionary).find((item) => item.includes(itemValue))[0];
    if(props.filter_name === 'dot_id'){
      if (!widgetActiveFilter.includes(dictionaryKey)) {
        setWidgetActiveFilter([dictionaryKey]);
        setChosenItems([itemNode]);
        props.setFilters(props.filter_name, [dictionaryKey]);
      } else {
        setWidgetActiveFilter([...widgetActiveFilter.filter((item) => item !== dictionaryKey)]);
        setChosenItems([...chosenItems.filter((item) => item !== itemNode)]);
        props.setFilters(props.filter_name, [...widgetActiveFilter.filter((item) => item !== dictionaryKey)]);
      }
    } else {
      if (!widgetActiveFilter.includes(dictionaryKey)) {
        setWidgetActiveFilter([...widgetActiveFilter, dictionaryKey]);
        setChosenItems([...chosenItems, itemNode]);
        props.setFilters(props.filter_name, [...widgetActiveFilter, dictionaryKey]);
      } else {
        if (!props.deficit == true) {
          setWidgetActiveFilter([...widgetActiveFilter.filter((item) => item !== dictionaryKey)]);
          setChosenItems([...chosenItems.filter((item) => item !== itemNode)]);
          props.setFilters(props.filter_name, [...widgetActiveFilter.filter((item) => item !== dictionaryKey)]);
        }
      }
    }
  };

  useEffect(() => {
    const tempArr = Array.from(containerRef.current.children);
    setItemsArray(tempArr);
  }, [JSON.stringify(props.data)]);

  useEffect(() => {
    itemsArray.map((item) => {
      Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.remove('widget_selected'));
      Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.add('widget_not_selected'));
    });
    chosenItems.map((item) => {
      Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.add('widget_selected'));
      Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.remove('widget_not_selected'));
    });
    if (chosenItems.length === 0) {
      itemsArray.map((item) => {
        Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.add('widget_selected'));
        Array.from(item.children).filter(elem => !Array.from(elem.classList).includes('tooltip')).map(itm => itm.classList.remove('widget_not_selected'));
      });
    }
  }, [chosenItems]);

  useEffect(() => {
    const tempObj = Array.isArray(props.active_filter) ? props.active_filter : props.active_filter.length > 0 ? props.active_filter.split(', ') : [];
    setWidgetActiveFilter(tempObj);
    const valuesFromActiveFilter = Object.keys(props.dictionary).filter((item) => {
      if (props.active_filter.includes(item)) {
        return item;
      }
    }).map((item) => props.dictionary[item]);
    const tempChosenItems = itemsArray.filter((item) => valuesFromActiveFilter.includes(item.dataset.filter));
    setChosenItems(tempChosenItems);
  }, [JSON.stringify(props.active_filter), itemsArray.length]);

  let max = props.data.length > 0 ? props.data[0].value : 0;
  for (let i = 0; i < props.data.length; i++) {
    if (props.data[i].value > max) {
      max = props.data[i].value;
    }
  }

  let elements = props.data.length > 0 ? props.data.map((item) =>
    <WidgetItem
      key={item.name}
      name={item.name}
      value={item.value}
      percent={Math.floor((item.value * 100) / max)}
      onSelect={onSelect}
    />) : '';

  if (elements.length > 0 && widgetActiveFilter.length > 0) {
    const tempArr = widgetActiveFilter.map((elem) => props.dictionary[elem]);
    const chosenItemsArr = elements.filter((item) => tempArr.includes(item.props.name)).sort((a, b) => b.props.value - a.props.value);
    const notChosenArr = elements.filter((item) => !tempArr.includes(item.props.name));
    elements = [...chosenItemsArr, ...notChosenArr];
  }

  return (
    <div ref={containerRef} style={{height: '100%'}}>
      {
        props.data.length === 0 ? <Skeleton active className="widget_container" />
          : elements
      }
    </div>
  );
};


const WidgetItem = (props) => {
  let {
    name, value, percent, onSelect,
  } = props;
  if (value > 1000000000) {
    value = Math.round(value / 1000000000) + ' млрд';
  } else if (value > 1000000 && value < 1000000000) {
    value = Math.round(value / 1000000) + ' млн';
  } else if (value > 1000 && value < 1000000) {
    value = Math.round(value / 1000) + ' тыс';
  }
  return (
    <div className="widget_container widget_selected" onClick={onSelect} data-filter={name} >
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="widget_header">
        <div className="widget_text">{name}</div>
        <div className="widget_text">{value}</div>
      </div>
      <div style={{ width: `${percent}%` }} className="widget_line" />
      <div className="tooltip">{name}</div>
    </div>
  );
};

export default WidgetFunc;

import {ClientsListComponent} from "./components/ClientsListComponent";
import {useEffect, useState} from "react";
import {clientsActions} from "../../../models/clientsModel/clientsActions";
import {connect} from "dva";
import {ClientsModal} from "./modals/ClientsModal";
import {changeFormFieldsData} from "../../../Tools/changeFormFieldsData";
import Form from "antd/es/form";

const initialFields = [
	{
		name: 'name',
		value: ''
	},
	{
		name: 'description',
		value: ''
	},
	{
		name: 'address',
		value: ''
	},
	{
		name: 'url',
		value: ''
	},
	{
		name: 'bin',
		value: ''
	},
	{
		name: 'contract_number',
		value: ''
	}
]


export const ClientsConnector = (props) => {
	const {
		clients,
		dispatch
	} = props

	const [createClientsModal, setCreateClientsModal] = useState(false)
	const [updateClientsModal, setUpdateClientsModal] = useState(false)
	const [fields, setFields] = useState(initialFields);
	const [form] = Form.useForm()
	useEffect(() => {
		document.title = 'Admin - Clients'
		dispatch(clientsActions.getClientsFetch());
	}, [])

	const onCancel = () => {
		setFields(initialFields)
		setCreateClientsModal(false)
		setUpdateClientsModal(false)
	}

	const onDeleteClient = (id) => {
		dispatch(clientsActions.deleteClientsFetch(id))
	}

	const onOpenModal = (type, value) => {
		if (type === "create") {
			setCreateClientsModal(true)
		} else if (type === "update") {
			setFields(changeFormFieldsData(fields, value))
			setUpdateClientsModal(value)
		}
	}

	const onSubmit = (value) => {
		if (createClientsModal) {
			dispatch(clientsActions.createClientsFetch(value))
		} else if (updateClientsModal) {
			dispatch(clientsActions.changeClientsFetch(value, updateClientsModal.id))
		}
		onCancel()
	}
	return (
		<>
			<ClientsModal
				createClientsModal={createClientsModal}
				updateClientsModal={updateClientsModal}
				clients={clients.data}
				onSubmit={onSubmit}
				fields={fields}
				form={form}
				onCancel={onCancel}
			/>
			<ClientsListComponent
				onOpenModal={onOpenModal}
				onDelete={onDeleteClient}
				clients={clients.data}
				CRUDStatus={clients.data.CRUDStatus}
			/>
		</>
	)
}

function mapStateToProps(state) {
	return {
		clients: state.clients
	}
}

export const ClientsContainer = connect(mapStateToProps)(ClientsConnector);

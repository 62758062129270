import React, { Component } from 'react';
// import '@carto/airship-style/dist/airship.css';
import { connect } from "dva";

import { defineCustomElements } from '@carto/airship-components/dist/loader';

defineCustomElements(window);

export default class WidgetWrapper extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    return <as-category-widget
      ref={this.ref}
      heading={this.props.heading}
      description={this.props.description}
      show-header={this.props.showHeader}
      show-clear-button={this.props.showClearButton}
      use-total-percentage={this.props.useTotalPercentage}
      visible-categories={this.props.visibleCategories}
    />

  }

  /**
   * Use this method to set element properties and callbacks
   */
  // componentDidMount() {
  //   const widget = this.ref.current;
  //   // Bind complex objects and event listeners
  //   widget.addEventListener('categoriesSelected', this._onSelectedChanged.bind(this));
  // }

  _onSelectedChanged(event) {
    const { onSelectedChanged } = this.props;
    onSelectedChanged && onSelectedChanged(event);
  }

  /**
   * Delegate function Сalls
   */
  async clearSelection() {
    await this.ref.current.clearSelection();
  }
}

import {PageHeader} from "antd";
import Card from "antd/es/card";
import {Component} from "react";
import * as React from 'react';
import mapboxgl from  '../../../../mapbox-gl_v1.0.0';
import carto from '@carto/carto-vl';
import MobileLayerSelection from './MobileLayerSelection/MobileLayerSelection'
import MobileRightBar from './MobileRightBar/MobileRightBar'
import MobileBottomBar from "./MobileBottomBar/MobileBottomBar";
import {districts_dictionary} from "./MobileDoubleParentWidget/Districts/districts_dictionary";
import style from "../components/MobileParentWidget/react_popup_redesign.css"
import {connect} from "dva";
import Map from "./Map";
import Zids from "./Zids";

window.zids_popup = new mapboxgl.Popup({
	closeButton: true,
	closeOnClick: true,
	anchor: 'bottom',
	className: 'zids_popup'
});

const voyager = 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json';
const colors = '' +
	'[rgba(0, 102, 255, 0.5),' +
	'rgba(0, 149, 255, 0.5),' +
	'rgba(71, 178, 255, 0.5),' +
	'rgba(94, 202, 239, 0.5),' +
	'rgba(240, 216, 30, 0.5),' +
	'rgba(255, 188, 0, 0.5),' +
	'rgba(255, 137, 3, 0.5),' +
	'rgba(255, 84, 0, 0.5),' +
	'rgba(255, 43, 0, 0.5),' +
	'rgba(255, 0, 0, 0.7)]'


const layer_counters_names = {
	$cbh_and_competitors_avg_receipt_amt: 'cbh_and_competitors_avg_receipt_amt',
	$cbh_and_no_competitors_avg_receipt_amt: 'cbh_and_no_competitors_avg_receipt_amt',
	$no_cbh_but_competitors_avg_receipt_amt: 'no_cbh_but_competitors_avg_receipt_amt',
	$others_only_avg_receipt_amt: 'others_only_avg_receipt_amt',
	$cbh_and_competitors_category_sales_amt: 'cbh_and_competitors_category_sales_amt',
	$cbh_and_no_competitors_category_sales_amt: 'cbh_and_no_competitors_category_sales_amt',
	$no_cbh_but_competitors_category_sales_amt: 'no_cbh_but_competitors_category_sales_amt',
	$others_only_category_sales_amt: 'others_only_category_sales_amt',
	$cbh_and_competitors_place_count: 'cbh_and_competitors_place_count',
	$cbh_and_no_competitors_place_count: 'cbh_and_no_competitors_place_count',
	$no_cbh_but_competitors_place_count: 'no_cbh_but_competitors_place_count',
	$others_only_place_count: 'others_only_place_count',
	$cbh_and_competitors_receipts_count: 'cbh_and_competitors_receipts_count',
	$cbh_and_no_competitors_receipts_count: 'cbh_and_no_competitors_receipts_count',
	$no_cbh_but_competitors_receipts_count: 'no_cbh_but_competitors_receipts_count',
	$others_only_receipts_count: 'others_only_receipts_count'
}

let gradient = []



 class MapComponent extends Component {

	//FIXME в этой компоненте будет карта
	mapCoordinates() {
		let coordinates = [76.9286100, 43.2566700]
		return coordinates
	}

	// initMap() {
	// 	console.log('Map render')
	// 	console.log('Map render', this.props)
	// 	window.map = new mapboxgl.Map({
	// 		container: 'map',
	// 		style: voyager,
	// 		center: this.mapCoordinates(),
	// 		zoom: 10,
	// 		scrollZoom: true,
	// 		accessToken: null
	// 	});
	// }


	createLayer(update){
		let current_layer = 'receipts_count'
		let current_sublayer = 'others_only_receipts_count'
		let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		let half_buckets = dynamic_buckets.map(item => item + 0.5)
		let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
		let active_layer_abriviature_name = layer_counters_names.$others_only_place_count
		let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		const new_layer = {
			features: not_zero_counter_zids,
			type: "FeatureCollection"
		}

		const source = new carto.source.GeoJSON(new_layer)
		const viz = new carto.Viz(
			`
          	strokeWidth: 0
          	color: ramp(buckets($others_only_receipts_count, ${final_dynamic_buckets}), ${colors})
         	@avg_categ_sales_amt: $avg_categ_sales_amt
			@avg_categ_sales_qty: $avg_categ_sales_qty
			@avg_receipt_amt: $avg_receipt_amt
			@buratino_avg_price: $buratino_avg_price
			@buratino_percent: $buratino_percent
			@buratino_receipt_count: $buratino_receipt_count
			@cartodb_id: $cartodb_id
			@category_sales_amt: $category_sales_amt
			@category_sales_qty: $category_sales_qty
			@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
			@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
			@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
			@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
			@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
			@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
			@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
			@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
			@cola_avg_price: $cola_avg_price
			@cola_percent: $cola_percent
			@cola_receipt_count: $cola_receipt_count
			@extra_big_count: $extra_big_count
			@iddistrict: $iddistrict
			@iddistrict_area: $iddistrict_area
			@kkm_count: $kkm_count
			@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
			@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
			@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
			@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
			@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
			@organisation_count: $organisation_count
			@other_avg_price: $other_avg_price
			@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
			@others_only_category_sales_amt: $others_only_category_sales_amt
			@others_only_place_count: $others_only_place_count
			@others_only_receipts_count: $others_only_receipts_count
			@others_percent: $others_percent
			@others_receipt_count: $others_receipt_count
			@pepsi_avg_price: $pepsi_avg_price
			@pepsi_percent: $pepsi_percent
			@pepsi_receipt_count: $pepsi_receipt_count
			@pop_count: $pop_count
			@receipts_count: $receipts_count
			@receipts_per_thousand_people: $receipts_per_thousand_people
			@retail_place_count: $retail_place_count
			@retail_places_per_person: $retail_places_per_person
			@segment_azs_count: $segment_azs_count
			@segment_azs_count_2gis: $segment_azs_count_2gis
			@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
			@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
			@segment_coffee_shop_count: $segment_coffee_shop_count
			@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
			@segment_mini_super_market_count: $segment_mini_super_market_count
			@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
			@segment_other_count: $segment_other_count
			@segment_other_count_2gis: $segment_other_count_2gis
			@segment_relevant_count: $segment_relevant_count
			@segment_relevant_count_2gis: $segment_relevant_count_2gis
			@segment_smoking_market_count: $segment_smoking_market_count
			@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
			@total_amount: $total_amount
			@total_receipt_count: $total_receipt_count
			@zid_id: $zid_id
        `
		)

		if (window.others_only_place_count) {
			window.others_only_place_count.update(source, viz)
		} else {
			const others_only_place_count = new carto.Layer('others_only_place_count', source, viz);
			window.others_only_place_count = others_only_place_count
		}

		window.zids_interactivity = new carto.Interactivity(window.others_only_place_count);
		const delay = 50;
		let clickedFeatureId = null;
		zids_interactivity.on('featureClick', (event) => {
			if (event.features.length) {
				const feature = event.features[0];
				clickedFeatureId = feature.id;
				feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
				window.feature = feature
			}
		})

		zids_interactivity.on('featureClickOut', event => {
			if (event.features.length) {
				// flyOut(coordinates)
				const feature = event.features[0];
				clickedFeatureId = '';
				feature.color.reset(delay);
				window.feature = feature
			}
		});

		function updatePopupMobileZids(event) {
			const popup = window.zids_popup
			setTimeout(() => {
				if (event.features.length > 0) {
					const vars = event.features[0].variables;

					const current_zid = vars.zid_id.value
					console.log('vars', vars)
					popup.setHTML(`
                  <div>
                      <h3>Информация о секторе</h3>
                      <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
                      	<div>
							  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
							  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
							  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
							  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
							  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
							  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
							  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
							  </ul>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
							  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
							  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
							  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
							  </ul>
							  </ul>
							  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
							  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
							  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
						</div>
					</div>
                  </div>
              `);
					popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
					if (!popup.isOpen()) {
						popup.addTo(map);
					} else {
						if (event.coordinates.lng == popup._lngLat.lng &&
							event.coordinates.lat == popup._lngLat.lat) {
						} else {
							popup.remove();
						}
					}
				} else {
					popup.remove();
				}
			}, 400)
		}
		zids_interactivity.on('featureClick', updatePopupMobileZids);
		window.others_only_place_count.addTo(window.map);
	}


	 // createLayer_others_only_receipts_count(dataset) {
	 //
		// console.log('start create layer others_only_receipts_count')
	 //
		//  let current_layer = 'receipts_count'
		//  let current_sublayer = 'others_only_receipts_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
		//  let active_layer_abriviature_name = layer_counters_names.$others_only_place_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($others_only_receipts_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.others_only_place_count) {
		// 	 window.others_only_place_count.update(source, viz)
		//  } else {
		// 	 const others_only_place_count = new carto.Layer('others_only_place_count', source, viz);
		// 	 window.others_only_place_count = others_only_place_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.others_only_place_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.others_only_place_count.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_no_competitors_receipts_count(dataset) {
	 //
		//  console.log('start create layer cbh_and_no_competitors_receipts_count')
	 //
		//  let current_layer = 'receipts_count'
		//  let current_sublayer = 'cbh_and_no_competitors_receipts_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_no_competitors_receipts_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_no_competitors_receipts_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_no_competitors_receipts_count) {
		// 	 window.cbh_and_no_competitors_receipts_count.update(source, viz)
		//  } else {
		// 	 const cbh_and_no_competitors_receipts_count = new carto.Layer('cbh_and_no_competitors_receipts_count', source, viz);
		// 	 window.cbh_and_no_competitors_receipts_count = cbh_and_no_competitors_receipts_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_no_competitors_receipts_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_no_competitors_receipts_count.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_competitors_receipts_count(dataset) {
	 //
		//  console.log('start create layer cbh_and_competitors_receipts_count')
	 //
		//  let current_layer = 'receipts_count'
		//  let current_sublayer = 'cbh_and_competitors_receipts_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_competitors_receipts_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_competitors_receipts_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_competitors_receipts_count) {
		// 	 window.cbh_and_competitors_receipts_count.update(source, viz)
		//  } else {
		// 	 const cbh_and_competitors_receipts_count = new carto.Layer('cbh_and_competitors_receipts_count', source, viz);
		// 	 window.cbh_and_competitors_receipts_count = cbh_and_competitors_receipts_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_competitors_receipts_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_competitors_receipts_count.addTo(window.map);
	 // }
	 //
	 // createLayer_no_cbh_but_competitors_receipts_count(dataset) {
	 //
		//  console.log('start create layer no_cbh_but_competitors_receipts_count')
	 //
		//  let current_layer = 'receipts_count'
		//  let current_sublayer = 'no_cbh_but_competitors_receipts_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$no_cbh_but_competitors_receipts_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($no_cbh_but_competitors_receipts_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.no_cbh_but_competitors_receipts_count) {
		// 	 window.no_cbh_but_competitors_receipts_count.update(source, viz)
		//  } else {
		// 	 const no_cbh_but_competitors_receipts_count = new carto.Layer('no_cbh_but_competitors_receipts_count', source, viz);
		// 	 window.no_cbh_but_competitors_receipts_count = no_cbh_but_competitors_receipts_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.no_cbh_but_competitors_receipts_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.no_cbh_but_competitors_receipts_count.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_competitors_place_count(dataset) {
	 //
		//  console.log('start create layer cbh_and_competitors_place_count')
	 //
		//  let current_layer = 'place_count'
		//  let current_sublayer = 'cbh_and_competitors_place_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_competitors_place_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_competitors_place_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_competitors_place_count) {
		// 	 window.cbh_and_competitors_place_count.update(source, viz)
		//  } else {
		// 	 const cbh_and_competitors_place_count = new carto.Layer('cbh_and_competitors_place_count', source, viz);
		// 	 window.cbh_and_competitors_place_count = cbh_and_competitors_place_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_competitors_place_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_competitors_place_count.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_no_competitors_place_count(dataset) {
	 //
		//  console.log('start create layer cbh_and_no_competitors_place_count')
	 //
		//  let current_layer = 'place_count'
		//  let current_sublayer = 'cbh_and_no_competitors_place_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_no_competitors_place_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_no_competitors_place_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_no_competitors_place_count) {
		// 	 window.cbh_and_no_competitors_place_count.update(source, viz)
		//  } else {
		// 	 const cbh_and_no_competitors_place_count = new carto.Layer('cbh_and_no_competitors_place_count', source, viz);
		// 	 window.cbh_and_no_competitors_place_count = cbh_and_no_competitors_place_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_no_competitors_place_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_no_competitors_place_count.addTo(window.map);
	 // }
	 //
	 // createLayer_no_cbh_but_competitors_place_count(dataset) {
	 //
	 //
		//  console.log('start create layer no_cbh_but_competitors_place_count')
	 //
		//  let current_layer = 'place_count'
		//  let current_sublayer = 'no_cbh_but_competitors_place_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$no_cbh_but_competitors_place_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($no_cbh_but_competitors_place_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.no_cbh_but_competitors_place_count) {
		// 	 window.no_cbh_but_competitors_place_count.update(source, viz)
		//  } else {
		// 	 const no_cbh_but_competitors_place_count = new carto.Layer('no_cbh_but_competitors_place_count', source, viz);
		// 	 window.no_cbh_but_competitors_place_count = no_cbh_but_competitors_place_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.no_cbh_but_competitors_place_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.no_cbh_but_competitors_place_count.addTo(window.map);
	 // }
	 //
	 // createLayer_others_only_place_count(dataset) {
	 //
		//  console.log('start create layer others_only_place_count')
	 //
		//  let current_layer = 'place_count'
		//  let current_sublayer = 'others_only_place_count'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$others_only_place_count
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($others_only_place_count, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.others_only_place_count) {
		// 	 window.others_only_place_count.update(source, viz)
		//  } else {
		// 	 const others_only_place_count = new carto.Layer('others_only_place_count', source, viz);
		// 	 window.others_only_place_count = others_only_place_count
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.others_only_place_count);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.others_only_place_count.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_no_competitors_avg_receipt_amt(dataset) {
	 //
		//  console.log('start create layer cbh_and_no_competitors_avg_receipt_amt')
	 //
		//  let current_layer = 'avg_receipt_amt'
		//  let current_sublayer = 'cbh_and_no_competitors_avg_receipt_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_no_competitors_avg_receipt_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_no_competitors_avg_receipt_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_no_competitors_avg_receipt_amt) {
		// 	 window.cbh_and_no_competitors_avg_receipt_amt.update(source, viz)
		//  } else {
		// 	 const cbh_and_no_competitors_avg_receipt_amt = new carto.Layer('cbh_and_no_competitors_avg_receipt_amt', source, viz);
		// 	 window.cbh_and_no_competitors_avg_receipt_amt = cbh_and_no_competitors_avg_receipt_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_no_competitors_avg_receipt_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_no_competitors_avg_receipt_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_competitors_avg_receipt_amt(dataset) {
	 //
		//  console.log('start create layer cbh_and_competitors_avg_receipt_amt')
	 //
		//  let current_layer = 'avg_receipt_amt'
		//  let current_sublayer = 'cbh_and_competitors_avg_receipt_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_competitors_avg_receipt_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_competitors_avg_receipt_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_competitors_avg_receipt_amt) {
		// 	 window.cbh_and_competitors_avg_receipt_amt.update(source, viz)
		//  } else {
		// 	 const cbh_and_competitors_avg_receipt_amt = new carto.Layer('cbh_and_competitors_avg_receipt_amt', source, viz);
		// 	 window.cbh_and_competitors_avg_receipt_amt = cbh_and_competitors_avg_receipt_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_competitors_avg_receipt_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_competitors_avg_receipt_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_no_cbh_but_competitors_avg_receipt_amt(dataset) {
	 //
		//  console.log('start create layer no_cbh_but_competitors_avg_receipt_amt')
	 //
		//  let current_layer = 'avg_receipt_amt'
		//  let current_sublayer = 'no_cbh_but_competitors_avg_receipt_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$no_cbh_but_competitors_avg_receipt_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($no_cbh_but_competitors_avg_receipt_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.no_cbh_but_competitors_avg_receipt_amt) {
		// 	 window.no_cbh_but_competitors_avg_receipt_amt.update(source, viz)
		//  } else {
		// 	 const no_cbh_but_competitors_avg_receipt_amt = new carto.Layer('no_cbh_but_competitors_avg_receipt_amt', source, viz);
		// 	 window.no_cbh_but_competitors_avg_receipt_amt = no_cbh_but_competitors_avg_receipt_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.no_cbh_but_competitors_avg_receipt_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.no_cbh_but_competitors_avg_receipt_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_others_only_avg_receipt_amt(dataset) {
	 //
		//  console.log('start create layer others_only_avg_receipt_amt')
	 //
		//  let current_layer = 'avg_receipt_amt'
		//  let current_sublayer = 'others_only_avg_receipt_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$others_only_avg_receipt_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($others_only_avg_receipt_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.others_only_avg_receipt_amt) {
		// 	 window.others_only_avg_receipt_amt.update(source, viz)
		//  } else {
		// 	 const others_only_avg_receipt_amt = new carto.Layer('others_only_avg_receipt_amt', source, viz);
		// 	 window.others_only_avg_receipt_amt = others_only_avg_receipt_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.others_only_avg_receipt_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.others_only_avg_receipt_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_competitors_category_sales_amt(dataset) {
	 //
		//  console.log('start create layer no_cbh_but_competitors_receipts_count')
	 //
		//  let current_layer = 'category_sales_amt'
		//  let current_sublayer = 'cbh_and_competitors_category_sales_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_competitors_category_sales_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_competitors_category_sales_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_competitors_category_sales_amt) {
		// 	 window.cbh_and_competitors_category_sales_amt.update(source, viz)
		//  } else {
		// 	 const cbh_and_competitors_category_sales_amt = new carto.Layer('cbh_and_competitors_category_sales_amt', source, viz);
		// 	 window.cbh_and_competitors_category_sales_amt = cbh_and_competitors_category_sales_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_competitors_category_sales_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_competitors_category_sales_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_cbh_and_no_competitors_category_sales_amt(dataset) {
	 //
		//  console.log('start create layer no_cbh_but_competitors_receipts_count')
	 //
		//  let current_layer = 'category_sales_amt'
		//  let current_sublayer = 'cbh_and_no_competitors_category_sales_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$cbh_and_no_competitors_category_sales_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($cbh_and_no_competitors_category_sales_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.cbh_and_no_competitors_category_sales_amt) {
		// 	 window.cbh_and_no_competitors_category_sales_amt.update(source, viz)
		//  } else {
		// 	 const cbh_and_no_competitors_category_sales_amt = new carto.Layer('cbh_and_no_competitors_category_sales_amt', source, viz);
		// 	 window.cbh_and_no_competitors_category_sales_amt = cbh_and_no_competitors_category_sales_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.cbh_and_no_competitors_category_sales_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.cbh_and_no_competitors_category_sales_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_no_cbh_but_competitors_category_sales_amt(dataset) {
	 //
		//  console.log('start create layer no_cbh_but_competitors_category_sales_amt')
	 //
		//  let current_layer = 'category_sales_amt'
		//  let current_sublayer = 'no_cbh_but_competitors_category_sales_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$no_cbh_but_competitors_category_sales_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($no_cbh_but_competitors_category_sales_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.no_cbh_but_competitors_category_sales_amt) {
		// 	 window.no_cbh_but_competitors_category_sales_amt.update(source, viz)
		//  } else {
		// 	 const no_cbh_but_competitors_category_sales_amt = new carto.Layer('no_cbh_but_competitors_category_sales_amt', source, viz);
		// 	 window.no_cbh_but_competitors_category_sales_amt = no_cbh_but_competitors_category_sales_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.no_cbh_but_competitors_category_sales_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.no_cbh_but_competitors_category_sales_amt.addTo(window.map);
	 // }
	 //
	 // createLayer_others_only_category_sales_amt(dataset) {
	 //
		//  console.log('start create layer others_only_category_sales_amt')
	 //
		//  let current_layer = 'category_sales_amt'
		//  let current_sublayer = 'others_only_category_sales_amt'
		//  let dynamic_buckets = dataset.dynymic_gradient[current_layer][current_sublayer].backets
		//  let half_buckets = dynamic_buckets.map(item => item + 0.5)
		//  let final_dynamic_buckets = '[' + half_buckets.toString() + ']'
	 //
		//  let active_layer_abriviature_name = layer_counters_names.$others_only_category_sales_amt
		//  let not_zero_counter_zids = dataset.zids.filter(item => item.properties[active_layer_abriviature_name])
		//  const new_layer = {
		// 	 features: not_zero_counter_zids,
		// 	 type: "FeatureCollection"
		//  }
	 //
		//  const source = new carto.source.GeoJSON(new_layer)
		//  const viz = new carto.Viz(
		// 	 `
     //      	strokeWidth: 0
     //      	color: ramp(buckets($others_only_category_sales_amt, ${final_dynamic_buckets}), ${colors})
     //     	@avg_categ_sales_amt: $avg_categ_sales_amt
		// 	@avg_categ_sales_qty: $avg_categ_sales_qty
		// 	@avg_receipt_amt: $avg_receipt_amt
		// 	@buratino_avg_price: $buratino_avg_price
		// 	@buratino_percent: $buratino_percent
		// 	@buratino_receipt_count: $buratino_receipt_count
		// 	@cartodb_id: $cartodb_id
		// 	@category_sales_amt: $category_sales_amt
		// 	@category_sales_qty: $category_sales_qty
		// 	@cbh_and_competitors_avg_receipt_amt: $cbh_and_competitors_avg_receipt_amt
		// 	@cbh_and_competitors_category_sales_amt: $cbh_and_competitors_category_sales_amt
		// 	@cbh_and_competitors_place_count: $cbh_and_competitors_place_count
		// 	@cbh_and_competitors_receipts_count: $cbh_and_competitors_receipts_count
		// 	@cbh_and_no_competitors_avg_receipt_amt: $cbh_and_no_competitors_avg_receipt_amt
		// 	@cbh_and_no_competitors_category_sales_amt: $cbh_and_no_competitors_category_sales_amt
		// 	@cbh_and_no_competitors_place_count: $cbh_and_no_competitors_place_count
		// 	@cbh_and_no_competitors_receipts_count: $cbh_and_no_competitors_receipts_count
		// 	@cola_avg_price: $cola_avg_price
		// 	@cola_percent: $cola_percent
		// 	@cola_receipt_count: $cola_receipt_count
		// 	@extra_big_count: $extra_big_count
		// 	@iddistrict: $iddistrict
		// 	@iddistrict_area: $iddistrict_area
		// 	@kkm_count: $kkm_count
		// 	@no_cbh_but_competitors_avg_receipt_amt: $no_cbh_but_competitors_avg_receipt_amt
		// 	@no_cbh_but_competitors_category_sales_amt: $no_cbh_but_competitors_category_sales_amt
		// 	@no_cbh_but_competitors_place_count: $no_cbh_but_competitors_place_count
		// 	@no_cbh_but_competitors_receipts_count: $no_cbh_but_competitors_receipts_count
		// 	@ofd_to_2gis_ratio: $ofd_to_2gis_ratio
		// 	@organisation_count: $organisation_count
		// 	@other_avg_price: $other_avg_price
		// 	@others_only_avg_receipt_amt: $others_only_avg_receipt_amt
		// 	@others_only_category_sales_amt: $others_only_category_sales_amt
		// 	@others_only_place_count: $others_only_place_count
		// 	@others_only_receipts_count: $others_only_receipts_count
		// 	@others_percent: $others_percent
		// 	@others_receipt_count: $others_receipt_count
		// 	@pepsi_avg_price: $pepsi_avg_price
		// 	@pepsi_percent: $pepsi_percent
		// 	@pepsi_receipt_count: $pepsi_receipt_count
		// 	@pop_count: $pop_count
		// 	@receipts_count: $receipts_count
		// 	@receipts_per_thousand_people: $receipts_per_thousand_people
		// 	@retail_place_count: $retail_place_count
		// 	@retail_places_per_person: $retail_places_per_person
		// 	@segment_azs_count: $segment_azs_count
		// 	@segment_azs_count_2gis: $segment_azs_count_2gis
		// 	@segment_cafe_restaurant_count: $segment_cafe_restaurant_count
		// 	@segment_cafe_restaurant_count_2gis: $segment_cafe_restaurant_count_2gis
		// 	@segment_coffee_shop_count: $segment_coffee_shop_count
		// 	@segment_coffee_shop_count_2gis: $segment_coffee_shop_count_2gis
		// 	@segment_mini_super_market_count: $segment_mini_super_market_count
		// 	@segment_mini_super_market_count_2gis: $segment_mini_super_market_count_2gis
		// 	@segment_other_count: $segment_other_count
		// 	@segment_other_count_2gis: $segment_other_count_2gis
		// 	@segment_relevant_count: $segment_relevant_count
		// 	@segment_relevant_count_2gis: $segment_relevant_count_2gis
		// 	@segment_smoking_market_count: $segment_smoking_market_count
		// 	@segment_smoking_market_count_2gis: $segment_smoking_market_count_2gis
		// 	@total_amount: $total_amount
		// 	@total_receipt_count: $total_receipt_count
		// 	@zid_id: $zid_id
     //    `
		//  )
	 //
		//  if (window.others_only_category_sales_amt) {
		// 	 window.others_only_category_sales_amt.update(source, viz)
		//  } else {
		// 	 const others_only_category_sales_amt = new carto.Layer('others_only_category_sales_amt', source, viz);
		// 	 window.others_only_category_sales_amt = others_only_category_sales_amt
		//  }
	 //
		//  window.zids_interactivity = new carto.Interactivity(window.others_only_category_sales_amt);
		//  const delay = 50;
		//  let clickedFeatureId = null;
		//  zids_interactivity.on('featureClick', (event) => {
		// 	 if (event.features.length) {
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = feature.id;
		// 		 feature.color.blendTo('opacity(LimeGreen, 0.5)', delay)
		// 		 window.feature = feature
		// 	 }
		//  })
	 //
		//  zids_interactivity.on('featureClickOut', event => {
		// 	 if (event.features.length) {
		// 		 // flyOut(coordinates)
		// 		 const feature = event.features[0];
		// 		 clickedFeatureId = '';
		// 		 feature.color.reset(delay);
		// 		 window.feature = feature
		// 	 }
		//  });
	 //
		//  function updatePopupMobileZids(event) {
		// 	 const popup = window.zids_popup
		// 	 setTimeout(() => {
		// 		 if (event.features.length > 0) {
		// 			 const vars = event.features[0].variables;
	 //
		// 			 const current_zid = vars.zid_id.value
		// 			 console.log('vars', vars)
		// 			 popup.setHTML(`
     //              <div>
     //                  <h3>Информация о секторе</h3>
     //                  <div style="padding-top: 0; height: 300px; width: 100%; overflow-y: scroll">
     //                  	<div>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">ZID ID: </span><span>${vars.zid_id.value}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Район: </span><span>${districts_dictionary[vars.iddistrict.value]}</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество населения: </span><span>${vars.pop_count.value.toLocaleString()} чел.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество ТТ: </span><span>${vars.retail_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество чеков:  </span><span>${vars.receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Средний чек:  </span><span>${vars.avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cумма продаж по категории:  </span><span>${vars.category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Количество продаж по категории:  </span><span>${vars.category_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cредняя сумма продаж по категории:  </span><span>${vars.avg_categ_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cреднее количество продаж по категории:  </span><span>${vars.avg_categ_sales_qty.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}">Представленность по Торговым точкам</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'place_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_place_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'avg_receipt_amt' ? 'display: block' : 'display: none'}">Представленность по Сред. Чеку</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px" >ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'avg_receipt_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_avg_receipt_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'category_sales_amt' ? 'display: block' : 'display: none'}">Представленность по Сумме продаж</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'category_sales_amt' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_category_sales_amt.value.toLocaleString()} тг.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255); ${dataset.current_layer === 'receipts_count' ? 'display: block' : 'display: none'}">Представленность по Количеству чеков</p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и ЕСТЬ конкуренты:  </span><span>${vars.cbh_and_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">ЕСТЬ СВН и НЕТ конкурентов:  </span><span>${vars.cbh_and_no_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН, но ЕСТЬ конкуренты:  </span><span>${vars.no_cbh_but_competitors_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="description open-sans" style="${dataset.current_layer === 'receipts_count' ? 'display: flex' : 'display: none'}"><span style="margin-right: 5px;">НЕТ СВН и НЕТ конкурентов, но ЕСТЬ другие:  </span><span>${vars.others_only_receipts_count.value.toLocaleString()} шт.</span></p>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Количество чеков</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.others_receipt_count.value.toLocaleString()} шт.</span></p></li>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">Средняя цена</p>
		// 					  <ul style="margin: 0; padding: 0; padding-left: 15px; color: rgb(255, 255, 255)">
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Настоящий Буратино  </span><span>${vars.buratino_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Cola  </span><span>${vars.cola_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Pepsi  </span><span>${vars.pepsi_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  <li><p className="description open-sans"><span style="margin-right: -2px">Другие напитки  </span><span>${vars.other_avg_price.value.toLocaleString()} тг.</span></p></li>
		// 					  </ul>
		// 					  </ul>
		// 					  <p className="subheading" style="font-weight: 600; font-size: 16px; margin-bottom: 12px; color: rgb(255, 255, 255);">% соотношение</p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Cola:  </span><span>${vars.cola_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Pepsi:  </span><span>${vars.pepsi_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Буратино:  </span><span>${vars.buratino_percent.value} %</span></p>
		// 					  <p className="description open-sans"><span style="margin-right: 5px">Другие напитки:  </span><span>${vars.others_percent.value} %</span></p>
		// 				</div>
		// 			</div>
     //              </div>
     //          `);
		// 			 popup.setLngLat([event.coordinates.lng, event.coordinates.lat]);
		// 			 if (!popup.isOpen()) {
		// 				 popup.addTo(map);
		// 			 } else {
		// 				 if (event.coordinates.lng == popup._lngLat.lng &&
		// 					 event.coordinates.lat == popup._lngLat.lat) {
		// 				 } else {
		// 					 popup.remove();
		// 				 }
		// 			 }
		// 		 } else {
		// 			 popup.remove();
		// 		 }
		// 	 }, 400)
		//  }
		//  zids_interactivity.on('featureClick', updatePopupMobileZids);
		//  window.others_only_category_sales_amt.addTo(window.map);
	 // }

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}


	render() {
		return <div className="container">
			<PageHeader
				className="site-page-header"
				style={{paddingLeft: '0'}}
				title={this.props.current_product?.name}
			/>
			<div className="container" dangerouslySetInnerHTML={{__html: this.props.current_product?.description}}>

			</div>
			<Card
				style={{
					height: '85vh',
					position: 'relative',
					overflow: 'hidden'
				}}


			>
				<div
					style={{
						position: 'absolute',
						left: 0,
						right: 0,
						bottom: 0,
						top: 0,
					}}
					id='map'

				/>
				<Map/>
				<Zids/>
				<MobileLayerSelection />
				<MobileRightBar />
				<MobileBottomBar />
			</Card>
		</div>
	}
}


export default MapComponent;

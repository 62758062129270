import {UsersListComponent} from "./components/UsersListComponent.js";
import {useEffect, useState} from "react";
import {usersActions} from "../../../models/usersModel/usersActions";
import {connect} from "dva";
import {UsersModal} from "./modals/UsersModal";
import {changeFormFieldsData} from "../../../Tools/changeFormFieldsData";
import {BindProductsToUserModal} from "./modals/BindProductsToUserModal";
import {MassBindProductsToUserModal} from "./modals/MassBindProductsToUserModal";
import {formatDate} from "../../../Tools/refactorDate";
import Form from "antd/es/form";

const initialFields = [
	{
		name: 'username',
		value: ''
	},
	{
		name: 'name',
		value: ''
	},
	{
		name: 'phone',
		value: ''
	},
	{
		name: 'email',
		value: ''
	},
	{
		name: 'is_blocked',
		value: false
	},
	{
		name: 'is_admin',
		value: false
	},
	{
		name: 'client_id',
		value: []
	},
]

const massInitialFields = [
	{
		name: 'products',
		value: []
	},
	{
		name: 'users',
		value: []
	},
	{
		name: 'date_from_to',
		value: []
	}
]

export const UsersConnector = (props) => {
	const {
		users,
		products,
		dispatch,
		clients
	} = props

	const [createUsersModal, setCreateUsersModal] = useState(false)
	const [updateUsersModal, setUpdateUsersModal] = useState(false)
	const [bindProductsToUserModal, setBindProductsToUserModal] = useState(false)
	const [massBindProductsToUserModal, setMassBindProductsToUserModal] = useState(false)
	const [fields, setFields] = useState(initialFields);
	const [massFields, setMassFields] = useState(massInitialFields);
	const [userForm] = Form.useForm()

	useEffect(() => {
		document.title = 'Admin - Users'
		dispatch(usersActions.getUsersFetch());
	}, [])


	const onBlock = (id, status) => {
		if (status == false) {
			dispatch(usersActions.blockUsersFetch(id))
		}
		if (status == true) {
			dispatch(usersActions.unBlockUsersFetch(id))
		}
	}

	const onCancel = () => {
		setFields(initialFields)
		setCreateUsersModal(false)
		setUpdateUsersModal(false)
		setBindProductsToUserModal(false)
		setMassBindProductsToUserModal(false)
		setMassFields(massInitialFields)
		userForm.resetFields()
	}

	const onDeleteUser = (id) => {
		dispatch(usersActions.deleteUsersFetch(id))
	}

	const onDeleteBindUser = (user_id, product_id) => {
		const payload = {
			user_id,
			product_id
		}
		dispatch(usersActions.deleteUsersBindFetch(payload))
	}

	const onOpenModal = (type, value) => {
		if (type === "create") {
			setCreateUsersModal(true)
		} else if (type === "update") {
			setFields(changeFormFieldsData(fields, value))
			setUpdateUsersModal(value)
		}
	}

	const onOpenBindProductsToUserModal = (value) => {
		setBindProductsToUserModal(value)
		dispatch(usersActions.getProductsForUserFetch(value.id))
	}

	const onOpenCreateUserModal = (value) => {
		setCreateUsersModal(value)
		// dispatch(usersActions.getProductsForUserFetch(value.id))
	}

	const onOpenMassBindProductsToUserModal = (value) => {
		setMassBindProductsToUserModal(value)
	}

	const onSubmit = (value) => {
		if (createUsersModal) {
			dispatch(usersActions.createUsersFetch(value))
		} else if (updateUsersModal) {
			dispatch(usersActions.changeUsersFetch(value, updateUsersModal.id))
		}
		onCancel()
	}

	const onSubmitCreateMassBindProducts = (value) => {
		const payload = {
			products_id: value.products,
			from_date: formatDate(value.date_from_to[0]),
			to_date: formatDate(value.date_from_to[1]),
			users_id: value.users
		}

		dispatch(usersActions.createMassProductsToUserFetch(payload))
		onCancel()
	}

	return (
		<>
			<MassBindProductsToUserModal
				onSubmitCreateMassBindProducts={onSubmitCreateMassBindProducts}
				massBindProductsToUserModal={massBindProductsToUserModal}
				products={products}
				fields={massFields}
				users={users.data}
				onCancel={onCancel}
			/>
			<BindProductsToUserModal
				bindProductsToUserModal={bindProductsToUserModal}
				massBindProductsToUserModal={massBindProductsToUserModal}
				onDeleteBindUser={onDeleteBindUser}
				dispatch={dispatch}
				products={products}
				onCancel={onCancel}
			/>
			<UsersModal
				createUsersModal={createUsersModal}
				updateUsersModal={updateUsersModal}
				onSubmit={onSubmit}
				fields={fields}
				onCancel={onCancel}
				dispatch={dispatch}
				clients={clients}
				users={users.data}
				form={userForm}
			/>
			<UsersListComponent
				onOpenBindProductsToUserModal={onOpenBindProductsToUserModal}
				onOpenMassBindProductsToUserModal={onOpenMassBindProductsToUserModal}
				onOpenModal={onOpenModal}
				onOpenCreateUserModal={onOpenCreateUserModal}
				onDelete={onDeleteUser}
				users={users.data.filter(item => item)}
				CRUDStatus={users.CRUDStatus}
				onBlock={onBlock}
			/>
		</>
	)
}

function mapStateToProps(state) {
	return {
		users: state.users,
		products: state.products.data,
		clients: state.clients.data
	}
}

export const UsersContainer = connect(mapStateToProps)(UsersConnector);

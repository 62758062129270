import React, {useState} from 'react'
import Row from 'antd/es/row'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import Checkbox from 'antd/es/checkbox';
import Button from 'antd/es/button'
import Slider from 'antd/es/slider'
import {ERROR, PENDING} from "../../../../models/globalStatuses";

import Col from 'antd/es/col'

import styles from "../../../../assets/style.css"

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 11},
};

const leftStyle = {
    display: 'flex',
    width: '40%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    paddingTop: '30px'
}

const container = {
    margin: '0 auto',
    // width: '1360px',
    backgroundColor: '#3C64B1'
}

export const Cabinet = (props, {regStatus, onEdit}) => {

    const {
        fields,
        form,
        onSubmit,
        currentUser,
        Cancel,
        changePassword,
        checkPassword
    } = props

    // const [checkPassword, setCheckPassword] = useState({
    //     password: '',
    //     confirm_password: '',
    //     check: false
    // })

    console.log('checkPassword', checkPassword.check)


    const checkName = (_, value) => {
        if (checkPassword.password === checkPassword.confirm_password) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('Пароли не совпадают'));
        }
    }

    const changeCheckboxPassword = (value) => {
        console.log('changeCheckboxPassword', value.target)
    }

    const onFinish = ({name, username, phone, position, new_report, change_password, password, confirm_password}) => {
        if (password === undefined || password === '') {
            password = null
        }


        if (confirm_password === undefined || confirm_password === '') {
            confirm_password = null
        }
        let payload = {
            name,
            username,
            // email,
            phone,
            position,
            password,
            mailing_settings: {
                new_report,
                change_password,
            }
        }
        if (password == confirm_password) {
            onSubmit(payload)
        }
    };

    if (currentUser) {
        // form.setFieldsValue({change_password: currentUser.mailing.change_password})
    }
    form.setFieldsValue({change_password: true})

    // const changePassword = (value, type) => {
    //     setCheckPassword({
    //         ...checkPassword,
    //         [type]: value
    //     })
    // }


    // form.setFieldsValue({name: 'qweasd'})

    const none = {
        display: 'none'
    }

    const block = {
        display: block,
        color: 'red'
    }


    const sliderValue = Math.floor((Math.floor((new Date() - new Date(2022,0,0)) / 86400000) * 100) / 365)

    return <>
        <div>
            <Row align={'middle'} justify={'space-around'}  className="row" id="cabinet">

                <Form
                    {...layout}
                    initialValues={{remember: true}}
                    fields={fields}
                    form={form}
                    onFinish={onSubmit}
                    layout="horizontal"
                    id='cabinet_form'
                    style={{
                        width: '600px'
                    }}
                >
                    <p style={{
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '700',
                        margin: '0',
                        marginBottom: '20px'
                    }}>
                        Личные данные
                    </p>


                    <Row justify={"space-between"}>
                        <Col span={10}>
                            <Form.Item
                                label="Введите ФИО"
                                name="username"
                                rules={[{required: true, message: 'Введите ФИО'}]}
                                validateStatus={regStatus === ERROR && "error"}
                                // span={6}
                                labelCol={{
                                    span: 24
                                }}
                                wrapperCol={{
                                    span: 24
                                }}
                            >
                                <Input disabled={regStatus === PENDING} style={{
                                    borderRadius: '8px',
                                    padding: '7px',
                                }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="Логин"
                                name="name"
                                validateStatus={regStatus === ERROR && "error"}
                                // span={6}
                                labelCol={{
                                    span: 24
                                }}
                                wrapperCol={{
                                    span: 24
                                }}
                            >
                                <Input disabled={true} style={{
                                    borderRadius: '8px',
                                    padding: '7px'
                                }}
                                />
                            </Form.Item>
                        </Col>




                    </Row>










                    <Row justify={"space-between"}>
                        {/*<Form.Item*/}
                        {/*    label="E-mail"*/}
                        {/*    name="email"*/}
                        {/*    // rules={[{required: true, message: 'Введите E-mail'}]}*/}
                        {/*    validateStatus={regStatus === ERROR && "error"}*/}
                        {/*    style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexDirection: 'column',*/}
                        {/*        width: '48%',*/}
                        {/*        marginRight: '4%',*/}
                        {/*        alignItems: 'flex-start',*/}
                        {/*        marginBottom: '10px'*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Input disabled={regStatus === PENDING} style={{*/}
                        {/*        width: '100%',*/}
                        {/*        borderRadius: '8px',*/}
                        {/*        padding: '7px',*/}
                        {/*        marginTop: '4px',*/}
                        {/*    }}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}

                        <Col span={10}>
                            <Form.Item
                                label="Введите номер телефона"
                                name="phone"
                                // rules={[{required: true, message: 'Введите номер телефона'}]}
                                validateStatus={regStatus === ERROR && "error"}
                                labelCol={{
                                    span: 24
                                }}
                                wrapperCol={{
                                    span: 24
                                }}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Input disabled={regStatus === PENDING} style={{
                                    borderRadius: '8px',
                                    padding: '7px'
                                }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="Введите должность"
                                name="position"
                                // rules={[{required: true, message: 'Введите должность'}]}
                                validateStatus={regStatus === ERROR && "error"}
                                labelCol={{
                                    span: 24
                                }}
                                wrapperCol={{
                                    span: 24
                                }}
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Input disabled={regStatus === PENDING} style={{
                                    borderRadius: '8px',
                                    padding: '7px'
                                }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify={"space-between"}>
                        <Col span={10}>
                            <p>
                                Пароль
                            </p>
                            <Input.Password
                                value={checkPassword.password}
                                autoComplete={'off'}
                                onChange={(e) => changePassword(e.target.value, 'password')}
                                disabled={!checkPassword.check}
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    padding: '7px',
                                    marginTop: '4px',
                                    height: '38px'
                                }}
                            />
                        </Col>

                        <Col span={10}>
                            <p>
                                Повторите пароль
                            </p>
                            <Input.Password
                                value={checkPassword.confirm_password}
                                onChange={(e) => changePassword(e.target.value, 'confirm_password')}
                                disabled={!checkPassword.check}
                                // onChange={(e) => changePassword(e.target.value, 'confirm_password')}
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    padding: '7px',
                                    marginTop: '4px',
                                }}
                            />
                            <p style={
                                checkPassword.confirm === true ? none : block
                            }>
                                Пароли не совпадают
                            </p>
                        </Col>
                    </Row>

                    <br/>
                    <Row justify={"space-around"} style={{width: '70%', margin: '0 auto'}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <p>
                                Новые отчеты
                            </p>
                            <Checkbox
                                onChange={() => changePassword(event.target.checked, 'reports')}
                                value={checkPassword.reports}
                                defaultChecked={true}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <p>
                                Смена пароля
                            </p>
                            <Checkbox onChange={() => changePassword(event.target.checked, 'check')}/>
                        </div>
                    </Row>

                    {/*<Row>*/}
                    {/*    <Form.Item>*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                fontSize: '15px',*/}
                    {/*                fontWeight: '600',*/}
                    {/*                lineHeight: '24px',*/}
                    {/*            }}*/}
                    {/*        >Доступ истекает</div>*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                lineHeight: '18px',*/}
                    {/*                marginTop: '10px',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            31 Декабря, 2022 года*/}
                    {/*        </div>*/}
                    {/*        <Slider*/}
                    {/*            className='cabinet_slider'*/}
                    {/*            style={{*/}
                    {/*                width: '260px'*/}
                    {/*            }}*/}
                    {/*            defaultValue={sliderValue}*/}
                    {/*            disabled={true}*/}
                    {/*            handleStyle={{*/}
                    {/*                display: 'none'*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Row>*/}



                    <Row justify={"space-around"}>
                        {/*<Form.Item>*/}
                        {/*    <Button type={regStatus === PENDING ? 'default' : 'primary'}*/}
                        {/*            disabled={regStatus === PENDING}*/}
                        {/*            loading={regStatus === PENDING}*/}
                        {/*            onClick={Cancel}*/}
                        {/*            style={{*/}
                        {/*                // width: '100%',*/}
                        {/*                marginTop: '30px',*/}
                        {/*                backgroundColor: '#ff4d4f',*/}
                        {/*                border: '1px solid #ff4d4f',*/}
                        {/*                borderRadius: '8px',*/}
                        {/*                padding: '7px',*/}
                        {/*                height: 'auto'*/}
                        {/*            }}*/}
                        {/*    >*/}
                        {/*        Сбросить*/}
                        {/*    </Button>*/}
                        {/*</Form.Item>*/}
                        <Form.Item >
                            <Button type={regStatus === PENDING ? 'default' : 'primary'}
                                    htmlType="submit"
                                    disabled={!checkPassword.confirm}
                                    loading={regStatus === PENDING}
                                    className='cabinet-btn'
                                    style={{
                                        // width: '100%',

                                    }}
                            >
                                Сохранить изменения
                            </Button>

                        </Form.Item>

                    </Row>


                </Form>

            </Row>

        </div>

    </>
}

const infoTypes = {
	getInfoFetch: 'info/getInfoFetch',
	getInfoPending: 'getInfoPending',
	getInfoSuccess: 'getInfoSuccess',
	getInfoError: 'getInfoError',

	getcurrentUserFetch: 'info/getcurrentUserFetch',
	getcurrentUserPending: 'getcurrentUserPending',
	getcurrentUserSuccess: 'getcurrentUserSuccess',
	getcurrentUserError: 'getcurrentUserError',
}

export const infoActions = {
	getInfoFetch: () => {
		return {
			type: infoTypes.getInfoFetch,
		}
	},

	getInfoPending: () => {
		return {
			type: infoTypes.getInfoPending,
		}
	},

	getInfoSuccess: (payload) => {
		return {
			type: infoTypes.getInfoSuccess,
			payload: payload
		}
	},

	getInfoError: () => {
		return {
			type: infoTypes.getInfoError,
		}
	},

	getcurrentUserFetch: (query = '') => {
		return {
			type: infoTypes.getcurrentUserFetch,
			payload: query
		}
	},

	getcurrentUserPending: () => {
		return {
			type: infoTypes.getcurrentUserPending,
		}
	},

	getcurrentUserSuccess: (payload) => {
		return {
			type: infoTypes.getcurrentUserSuccess,
			payload: payload
		}
	},

	getcurrentUserError: () => {
		return {
			type: infoTypes.getcurrentUserError,
		}
	},


}

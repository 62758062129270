import mapboxgl from "../../../../mapbox-gl_v1.0.0";
import {Component} from "react";

const voyager = 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json';

export default class Map extends Component {
    mapCoordinates() {
        let coordinates = [76.9286100, 43.2566700]
        return coordinates
    }

    initMap() {
        console.log('Map render')
        console.log('Map render', this.props)
        window.map = new mapboxgl.Map({
            container: 'map',
            style: voyager,
            center: this.mapCoordinates(),
            zoom: 10,
            scrollZoom: true,
            accessToken: null
        });
    }

    componentDidMount() {
        this.initMap()
    }

    render() {
        return null
    }
}
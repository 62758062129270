import {Popconfirm, Table, Tooltip} from "antd";
import Button from "antd/es/button";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {formatDate} from "../../../../Tools/refactorDate";

export const BindProductsToUserList = (props) => {

	const {
		userProducts,
		products,
		onDeleteBindUser
	} = props

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (_, value) => <p>{products.find(product => product.id === value.product_id).name}</p>
		},
		{
			title: 'Start date',
			dataIndex: 'from_date',
			render: date => formatDate(date)
		},
		{
			title: 'End date',
			dataIndex: 'to_date',
			render: date => formatDate(date)
		},
		{
			title: 'Actions',
			align: 'center',
			key: 'actions',
			render: (_, product) => (
				<Button.Group>
					<Popconfirm
						title="Are you sure to delete this product?"
						onConfirm={() => onDeleteBindUser(product.user_id, product.product_id)}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip  placement="topLeft" title={'Delete this product'}>
							<Button type="primary" danger>
								<DeleteOutlined/>
							</Button>
						</Tooltip>
					</Popconfirm>
				</Button.Group>
			),
			width: 120
		},
	];

	return (
		<>
			<Table columns={columns} dataSource={userProducts} />
		</>
	)
}

import React from 'react';
import {connect} from "dva";
import { districts_dictionary, districts_dictionary_pvl } from './districts_dictionary';
// import { district_to_district_area_dictionary } from '../District_area_region/district_to_district_district_area_dictionary';
// import MobileParentWidget from "../MobileParentWidget/MobileParentWidget";
import MobileParentWidget from "../MobileParentWidget/MobileParentWidget";
// import { districtAvgCounter } from './config_part';
// import { mapName, mobileMapModelStateName } from '../../../../../utils/map-variables';


let data = []
let active_filter = []

const emptyData = []
const DistrictHistogramWidget = connect((state, props) => {
  if (state.geo.almaty.start_data.widget_data) {
    // data = state.geo.almaty.start_data.widget_data.avg_receipt_amt.districts.cbh_and_competitors_avg_receipt_amt.map(item => {
    //   return {
    //     item_name: Object.entries(item)[0][0],
    //     item_value: Object.entries(item)[0][1]
    //   }
    // })
    // data = []
  }
  console.log('districts data', data)

  return {
    // ...props,
    data: data,
    active_filter: state.geo.almaty.active_filters.districts,
    dictionary: districts_dictionary,
    item_name: 'item_name',
    filter_name: 'districts',
    widget_name: 'Cтатистика по адм.районам',
    item_value: 'item_value',
    height_wrapper: '50%',
    widget_height: 'calc(100% - 40px)',
    absolute_left: 210,
  }
})(MobileParentWidget)
export default DistrictHistogramWidget


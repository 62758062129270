import React from 'react';
import style from './MobileRightbar.css';
import { connect } from 'dva';
import MobileTabsMap from './MobileTabsMap';
// import MobileRightBarSectorWindow from './MobileRightBarSectorWindow';
// import ReactTooltip from "react-tooltip";
// import { mapName, mobileMapModelStateName } from '../../../../utils/map-variables';
// import HistoryModal from '../../../HistoryModal/HistoryModal';


@connect((state) => {
    return {
        right_top_nav_active_tab: state.geo.almaty.right_top_nav_active_tab,
        active_tab: state.geo.almaty.zid_click_for_active_tab,
        flagForActiveTab: state.geo.almaty.flagForActiveTab,
        isHistory: state.geo.almaty.isHistory,
    }
})

class MobileRightTopNav extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            active_tab: 0,
        }
    }
    TabSwitch = (e) => {
        // this.setState({
        //   active_tab: +e.target.getAttribute('data-id')
        // })
        if(e)
            this.props.dispatch(
                {
                    type: 'mobile/setRightTopNavActiveTab',
                    payload: +e.target.getAttribute('data-id')
                }
            )

        this.props.dispatch(
            {
                type: 'mobile/zidClickForActiveTab',
                payload: +e.target.getAttribute('data-id')
            }
        )
        // if(this.props.active_tab == 1) {
        //   this.props.dispatch(
        //     {
        //       type: 'mobile/setupFlagForActiveTab',
        //       payload: 1
        //     }
        //   )
        // }
    }
    render() {

        const active_class_tab = style.li +' '+ style.li_active
        const default_class_tab = style.li

        const active_class_tab_content = style.widgets_frame + ' ' + style.widgets_frame_active
        const default_class_tab_content = style.widgets_frame
        let height = '100%'
        if (window.location.href.includes('public')) {
            height = '100vh'
        }
        return (
            <div
                className="top_nav"
                style={{
                    height: this.props.isDP ? '100%' : 'calc(100%)',
                    boxSizing: 'border-box',
                    paddingBottom: '0px',
                    paddingTop: this.props.isDP ? '15px' : '4px',
                }}
            >
                {/*<div style={{position: 'relative'}}>*/}
                {/*    <ul className="ul">*/}
                {/*        <li*/}
                {/*            className={this.props.right_top_nav_active_tab === 0 ? active_class_tab : default_class_tab}*/}
                {/*            data-id="0"*/}
                {/*            onClick={this.TabSwitch}*/}
                {/*        >*/}
                {/*            Город*/}
                {/*        </li>*/}
                {/*        <li*/}
                {/*            className={*/}

                {/*                [this.props.right_top_nav_active_tab === 1 ? active_class_tab : default_class_tab,*/}
                {/*                    this.props.flagForActiveTab == 1  ? '' : style.not_allowed].join(' ')*/}
                {/*            }*/}
                {/*            data-id="1"*/}
                {/*            onClick={this.props.flagForActiveTab == 1 ? this.TabSwitch : () => {}}*/}
                {/*        >*/}
                {/*            Сектор*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*    {*/}
                {/*        this.props.isHistory ? <HistoryModal map={'mobile'}/> : null*/}
                {/*    }*/}
                {/*</div>*/}
                <div
                    style={{
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    <div
                        className="widgets_frame widgets_frame_active"
                        style={{height}}
                    >
                        <MobileTabsMap />
                    </div>
                </div>
            </div>
        );
    }



    componentDidUpdate(prevProps) {
        if(
            (prevProps.active_tab !== this.props.active_tab) &&
            (this.state.active_tab !== this.props.active_tab)
        ){
            if(this.props.active_tab == 2){
                if(mapName() == 'BusinessDotsAlmaty'){
                    this.setState({
                        active_tab: this.props.active_tab
                    })
                }
            } else {
                this.setState({
                    active_tab: this.props.active_tab
                })
            }
        }
        // if(prevProps.active_tab !== this.props.active_tab){
        //   this.setState({
        //     active_tab: this.props.active_tab
        //   )}
        // }
    }

// this.setState({
//   active_tab: +e.target.getAttribute('data-id')
// })
}

export default MobileRightTopNav;

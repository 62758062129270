import {instance} from "../../api/api";

export const productsApi = {
	getProductsList: () => {
		return instance().get(`/products/`).then(res => res.data)
	},

	getProductsListForCurrentUser: () => {
		return instance().get('/products/get_current_user_list').then(res => res.data)
	},

	createProduct: (payload) => {
		return instance().post(`/products/`,payload).then(res => res.data)
	},

	changeProduct: (client, id) => {
		return instance().put(`/products/${id}`, client).then(res => res.data)
	},

	deleteProduct: (id) => {
		return instance().delete(`/products/purge/${id}`).then(res => res.data)
	},

	getBiAccessToken: (id) => {
		return instance().post(`products/access_pb/${id}`).then(res => res.data)
	},

	getPublicList: () => {
		return instance().get(`/products/get_public/${id}`).then(res => res.data)
	},
};

import {instance} from "../../api/api";

export const infoApi = {
	getInfo: () => {
		return instance().get(`/info/info`).then(res => res.data)
	},
	getCurrentUser: () => {
		return instance().get(`/users/current`).then(res => res.data)
	},
};

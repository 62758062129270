const usersTypes = {
	getUsersFetch: 'users/getUsersFetch',
	getUsersPending: 'getUsersPending',
	getUsersSuccess: 'getUsersSuccess',
	getUsersError: 'getUsersError',

	createUsersFetch: 'users/createUsersFetch',
	createUsersPending: 'createUsersPending',
	createUsersSuccess: 'createUsersSuccess',
	createUsersError: 'createUsersError',

	blockUsersFetch: 'users/blockUsersFetch',
	blockUsersPending: 'blockUsersPending',
	blockUsersSuccess: 'blockUsersSuccess',
	blockUsersError: 'blockUsersError',

	unBlockUsersFetch: 'users/unBlockUsersFetch',
	unBlockUsersPending: 'unBlockUsersPending',
	unBlockUsersSuccess: 'unBlockUsersSuccess',
	unBlockUsersError: 'unBlockUsersError',

	editUsersFetch: 'users/editUsersFetch',
	editUsersPending: 'editUsersPending',
	editUsersSuccess: 'editUsersSuccess',
	editUsersError: 'editUsersError',

	changeUsersFetch: 'users/changeUsersFetch',
	changeUsersPending: 'changeUsersPending',
	changeUsersSuccess: 'changeUsersSuccess',
	changeUsersError: 'changeUsersError',

	deleteUsersFetch: 'users/deleteUsersFetch',
	deleteUsersPending: 'deleteUsersPending',
	deleteUsersSuccess: 'deleteUsersSuccess',
	deleteUsersError: 'deleteUsersError',

	setProductsToUserFetch: 'users/setProductsToUserFetch',
	setProductsToUserPending: 'setProductsToUserPending',
	setProductsToUserSuccess: 'setProductsToUserSuccess',
	setProductsToUserError: 'setProductsToUserError',

	getProductsForUser: 'users/getProductsForUserFetch',
	getProductsForUserPending: 'getProductsForUserPending',
	getProductsForUserSuccess: 'getProductsForUserSuccess',
	getProductsForUsersError: 'getProductsForUsersError',

	createMassProductsToUserFetch: 'users/createMassProductsToUserFetch',
	createMassProductsToUserPending: 'createMassProductsToUserPending',
	createMassProductsToUserSuccess: 'createMassProductsToUserSuccess',
	createMassProductsToUserError: 'createMassProductsToUserError',

	deleteUsersBindFetch: 'users/deleteUsersBindFetch',
	deleteUsersBindPending: 'deleteUsersBindPending',
	deleteUsersBindSuccess: 'deleteUsersBindSuccess',
	deleteUsersBindError: 'deleteUsersBindError'
}

export const usersActions = {

	deleteUsersBindFetch: (id) => {
		return {
			type: usersTypes.deleteUsersBindFetch,
			payload: id
		}
	},
	deleteUsersBindPending: () => {
		return {
			type: usersTypes.deleteUsersBindPending
		}
	},
	deleteUsersBindSuccess: (id) => {
		return {
			type: usersTypes.deleteUsersBindSuccess,
			payload: id
		}
	},
	deleteUsersBindError: () => {
		return {
			type: usersTypes.deleteUsersBindError
		}
	},

	createMassProductsToUserFetch: (product) => {
		return {
			type: usersTypes.createMassProductsToUserFetch,
			payload: product
		}
	},

	createMassProductsToUserPending: () => {
		return {
			type: usersTypes.createMassProductsToUserPending
		}
	},
	createMassProductsToUserSuccess: (product) => {
		return {
			type: usersTypes.createMassProductsToUserSuccess,
			payload: product
		}
	},
	createMassProductsToUserError: () => {
		return {
			type: usersTypes.createMassProductsToUserError
		}
	},

	getProductsForUserFetch: (id) => {
		return {
			type: usersTypes.getProductsForUser,
			payload: id
		}
	},

	getProductsForUserPending: () => {
		return {
			type: usersTypes.getProductsForUserPending,
		}
	},

	getProductsForUserSuccess: (payload) => {
		return {
			type: usersTypes.getProductsForUserSuccess,
			payload: payload
		}
	},

	getProductsForUsersError: () => {
		return {
			type: usersTypes.getProductsForUsersError,
		}
	},

	setProductsToUserFetch: (product) => {
		return {
			type: usersTypes.setProductsToUserFetch,
			payload: product
		}
	},

	setProductsToUserPending: () => {
		return {
			type: usersTypes.setProductsToUserPending
		}
	},
	setProductsToUserSuccess: (product) => {
		return {
			type: usersTypes.setProductsToUserSuccess,
			payload: product
		}
	},
	setProductsToUserError: () => {
		return {
			type: usersTypes.setProductsToUserError
		}
	},

	deleteUsersFetch: (id) => {
		return {
			type: usersTypes.deleteUsersFetch,
			payload: id
		}
	},
	deleteUsersPending: () => {
		return {
			type: usersTypes.deleteUsersPending
		}
	},
	deleteUsersSuccess: (id) => {
		return {
			type: usersTypes.deleteUsersSuccess,
			payload: id
		}
	},
	deleteUsersError: () => {
		return {
			type: usersTypes.deleteUsersError
		}
	},

	changeUsersFetch: (product, id) => {
		return {
			type: usersTypes.changeUsersFetch,
			payload: {product, id}
		}
	},
	changeUsersPending: () => {
		return {
			type: usersTypes.changeUsersPending
		}
	},
	changeUsersSuccess: (user) => {
		return {
			type: usersTypes.changeUsersSuccess,
			payload: user
		}
	},
	changeUsersError: () => {
		return {
			type: usersTypes.changeUsersError
		}
	},

	createUsersFetch: (payload) => {
		return {
			type: usersTypes.createUsersFetch,
			payload: payload
		}
	},
	createUsersPending: () => {
		return {
			type: usersTypes.createUsersPending
		}
	},
	createUsersSuccess: (payload) => {
		return {
			type: usersTypes.createUsersSuccess,
			payload: payload
		}
	},
	createUsersError: () => {
		return {
			type: usersTypes.createUsersError
		}
	},


	getUsersFetch: (query = '') => {
		return {
			type: usersTypes.getUsersFetch,
			payload: query
		}
	},

	getUsersPending: () => {
		return {
			type: usersTypes.getUsersPending,
		}
	},

	getUsersSuccess: (payload) => {
		return {
			type: usersTypes.getUsersSuccess,
			payload: payload
		}
	},

	getUsersError: () => {
		return {
			type: usersTypes.getUsersError,
		}
	},


	blockUsersFetch: (payload) => {
		return {
			type: usersTypes.blockUsersFetch,
			payload: payload
		}
	},
	blockUsersPending: () => {
		return {
			type: usersTypes.blockUsersPending
		}
	},
	blockUsersSuccess: (payload) => {
		return {
			type: usersTypes.blockUsersSuccess,
			payload: payload
		}
	},
	blockUsersError: () => {
		return {
			type: usersTypes.blockUsersError
		}
	},

	unBlockUsersFetch: (payload) => {
		return {
			type: usersTypes.unBlockUsersFetch,
			payload: payload
		}
	},
	unBlockUsersPending: () => {
		return {
			type: usersTypes.unBlockUsersPending
		}
	},
	unBlockUsersSuccess: (payload) => {
		return {
			type: usersTypes.unBlockUsersSuccess,
			payload: payload
		}
	},
	unBlockUsersError: () => {
		return {
			type: usersTypes.unBlockUsersError
		}
	},


	editUsersFetch: (payload) => {
		return {
			type: usersTypes.editUsersFetch,
			payload: payload
		}
	},
	editUsersPending: () => {
		return {
			type: usersTypes.editUsersPending
		}
	},
	editUsersSuccess: (payload) => {
		return {
			type: usersTypes.editUsersSuccess,
			payload: payload
		}
	},
	editUsersError: () => {
		return {
			type: usersTypes.editUsersError
		}
	},


}

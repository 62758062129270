import {instance} from "../../api/api";




export const regApi = {

	onRegApi: (payload) => {
		return instance(false).post(`/users/signup`, payload).then(res => res.data)
	},

}

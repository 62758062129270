import {instance} from "../../api/api";


export const authApi = {
	onAuthApi: async (username, password) => {
		const config = {headers: {"Content-Type": "multipart/form-data"},}
		const payload = new FormData();
		payload.append('username', username);
		payload.append('password', password);
		return await instance(false).post('/token/', payload, config).then(res => res.data)
	},
	refreshTokenApi: async (refresh_token) => {
		const payload = {
			refresh_token
		}
		return await instance(false).post('/token/refresh', payload).then(res => res.data)
	},

	onRevoke: async () => {
		return await instance().post('/token/revoke').then(res => res.data)
	}
}

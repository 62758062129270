import Button from "antd/es/button";
import Modal from "antd/es/modal/Modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import {Row} from "antd";
import {Select} from "antd";
import {Option} from "antd/es/mentions";

const layout = {
	labelCol: {span: 4},
	wrapperCol: {span: 18},
};

export const DatasetsModal = (props) => {
	const {
		createDatasetsModal,
		updateDatasetsModal,
		onSubmit,
		fields,
		onCancel,
		products,
		datasets
	} = props

	const formItem = {
		width: '30%',
		marginRight: '3%',
		display: 'block',
		// flexDirection: 'column'
	}
	const inputStyle = {
		borderRadius: '8px'
	}

	return (
		<Modal title={updateDatasetsModal ? "Update Dataset" : "Добавить файл"}
			   visible={updateDatasetsModal || createDatasetsModal}
			   destroyOnClose={true}
			   onCancel={onCancel}
			   style={{top: 30}}
			   footer={[
				   <div key={1}>
					   <Button onClick={onCancel}>
						   Назад
					   </Button>
					   <Button form="datasetsForm" type={"primary"} htmlType="submit">
						   Готово
					   </Button>
				   </div>
			   ]}
			   width={850}>
			<Form
				id="datasetsForm"
				{...layout}
				name="datasetsForm"
				fields={fields}
				layout={"vertical"}
				onFinish={onSubmit}
			>
				<Row>
					<Form.Item
						label="Название"
						name="name"
						style={{...formItem, width: '30%'}}
						labelCol={{span: 24}}
						rules={[
							{
								required: true
							},
							() => ({
								validator(rule, value) {
									if (value && datasets.findIndex(item => item.name.toLowerCase() === value.toLowerCase() && item.id !== updateDatasetsModal.id) !== -1) {
										return Promise.reject(new Error('Enter unique name!'));
									}
									return Promise.resolve()
								}
							})
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
					<Form.Item
						label="Путь"
						name="path"
						labelCol={{span: 24}}
						style={{...formItem, width: '65%', marginRight: '0'}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>

				</Row>
				<Row>
					<Form.Item
						label="Продукт"
						name="product_id"
						labelCol={{span: 24}}
						style={{...formItem, width: '65%', marginRight: '0'}}
						rules={[{required: true, message: 'Select client!'}]}
					>
						<Select
							showSearch
							allowClear
							placeholder="Search to Select"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{products.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Row>

				<Form.Item
					label="Описание"
					name="description"
					labelCol={{span: 24}}
					style={{...formItem, width: '65%', marginRight: '0'}}
				>
					<Input.TextArea rows={2} style={{borderRadius: '8px'}}/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

const productsTypes = {
	getProductsFetch: 'products/getProductsFetch',
	getProductsPending: 'getProductsPending',
	getProductsSuccess: 'getProductsSuccess',
	getProductsError: 'getProductsError',

	createProductsFetch: 'products/createProductsFetch',
	createProductsPending: 'createProductsPending',
	createProductsSuccess: 'createProductsSuccess',
	createProductsError: 'createProductsError',

	changeProductsFetch: 'products/changeProductsFetch',
	changeProductsPending: 'changeProductsPending',
	changeProductsSuccess: 'changeProductsSuccess',
	changeProductsError: 'changeProductsError',

	deleteProductsFetch: 'products/deleteProductsFetch',
	deleteProductsPending: 'deleteProductsPending',
	deleteProductsSuccess: 'deleteProductsSuccess',
	deleteProductsError: 'deleteProductsError',

	getBiAccessTokenFetch: 'products/getBiAccessTokenFetch',
	getBiAccessTokenPending: 'getBiAccessTokenPending',
	getBiAccessTokenSuccess: 'getBiAccessTokenSuccess',
	getBiAccessTokenError: 'getBiAccessTokenError',

	getProductsForCurrentUserFetch: 'products/getProductsForCurrentUserFetch',
	getProductsForCurrentUserPending: 'getProductsForCurrentUserPending',
	getProductsForCurrentUserSuccess: 'getProductsForCurrentUserSuccess',
	getProductsForCurrentUserError: 'getProductsForCurrentUserError',

	getPublicFetch: 'products/getPublicFetch',
	getPublicPending: 'getPublicPending',
	getPublicSuccess: 'getPublicSuccess',
	getPublicError: 'getPublicError',
}

export const productsActions = {

	getProductsForCurrentUserFetch: (query = '') => {
		return {
			type: productsTypes.getProductsForCurrentUserFetch,
			payload: query
		}
	},

	getProductsForCurrentUserPending: () => {
		return {
			type: productsTypes.getProductsForCurrentUserPending,
		}
	},

	getProductsForCurrentUserSuccess: (payload) => {
		return {
			type: productsTypes.getProductsForCurrentUserSuccess,
			payload: payload
		}
	},

	getProductsForCurrentUserError: () => {
		return {
			type: productsTypes.getProductsForCurrentUserError,
		}
	},

	getBiAccessTokenFetch: (id) => {
		return {
			type: productsTypes.getBiAccessTokenFetch,
			payload: id
		}
	},


	getBiAccessTokenPending: () => {
		return {
			type: productsTypes.getBiAccessTokenPending,
		}
	},

	getBiAccessTokenSuccess: (payload) => {
		return {
			type: productsTypes.getBiAccessTokenSuccess,
			payload: payload
		}
	},

	getBiAccessTokenError: () => {
		return {
			type: productsTypes.getBiAccessTokenError,
		}
	},

	deleteProductsFetch: (id) => {
		return {
			type: productsTypes.deleteProductsFetch,
			payload: id
		}
	},
	deleteProductsPending: () => {
		return {
			type: productsTypes.deleteProductsPending
		}
	},
	deleteProductsSuccess: (id) => {
		return {
			type: productsTypes.deleteProductsSuccess,
			payload: id
		}
	},
	deleteProductsError: () => {
		return {
			type: productsTypes.deleteProductsError
		}
	},

	changeProductsFetch: (product, id) => {
		return {
			type: productsTypes.changeProductsFetch,
			payload: {product, id}
		}
	},
	changeProductsPending: () => {
		return {
			type: productsTypes.changeProductsPending
		}
	},
	changeProductsSuccess: (product) => {
		return {
			type: productsTypes.changeProductsSuccess,
			payload: product
		}
	},
	changeProductsError: () => {
		return {
			type: productsTypes.changeProductsError
		}
	},

	createProductsFetch: (payload) => {
		return {
			type: productsTypes.createProductsFetch,
			payload: payload
		}
	},
	createProductsPending: () => {
		return {
			type: productsTypes.createProductsPending
		}
	},
	createProductsSuccess: (payload) => {
		return {
			type: productsTypes.createProductsSuccess,
			payload: payload
		}
	},
	createProductsError: () => {
		return {
			type: productsTypes.createProductsError
		}
	},


	getProductsFetch: (query = '') => {
		return {
			type: productsTypes.getProductsFetch,
			payload: query
		}
	},

	getProductsPending: () => {
		return {
			type: productsTypes.getProductsPending,
		}
	},

	getProductsSuccess: (payload) => {
		return {
			type: productsTypes.getProductsSuccess,
			payload: payload
		}
	},

	getProductsError: () => {
		return {
			type: productsTypes.getProductsError,
		}
	},

	getPublicFetch: (query = '') => {
		return {
			type: productsTypes.getPublicFetch,
			payload: query
		}
	},

	getPublicPending: () => {
		return {
			type: productsTypes.getPublicPending,
		}
	},

	getPublicSuccess: (payload) => {
		return {
			type: productsTypes.getPublicSuccess,
			payload: payload
		}
	},

	getPublicError: () => {
		return {
			type: productsTypes.getPublicError,
		}
	},


}

import {Popconfirm, Table, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import {Row} from "antd";
import Button from "antd/es/button";
import {formatDate} from "../../../../Tools/refactorDate";
import {PENDING} from "../../../../models/globalStatuses";


export const DatasetsListComponent = (props) => {
	const {
		datasets,
		onOpenModal,
		onDelete,
		CRUDStatus
	} = props

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Путь',
			dataIndex: 'path',
			key: 'path'
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
			render: (_, dataset) => (dataset.description || '-')

		},
		{
			title: 'Дата создания',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (_, dataset) => `${formatDate(dataset.created_at)}`
		},
		// {
		//	FIXME This column is not in Figma
		// 	title: 'Дата изменения',
		// 	dataIndex: 'updated_at',
		// 	key: 'updated_at',
		// 	render: (_, dataset) => `${formatDate(dataset.updated_at)}`
		// },
		{
			//FIXME Index and key must be changed
			title: 'Привязка к продукту',
			dataIndex: 'product_bind',
			key: 'product_bind',
			render: (_, dataset) => (dataset.product_id || '-')

		},
		{
			title: 'Действия',
			align: 'center',
			key: 'actions',
			render: (_, dataset) => (
				<Button.Group>
						<Tooltip placement="topLeft" title={'Edit dataset'}>
								<Button type="default" onClick={() => onOpenModal('update', dataset)}>
									<EditOutlined/>
								</Button>
						</Tooltip>
						<Popconfirm
							title="Are you sure to delete this dataset?"
							onConfirm={() => onDelete(dataset.id)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip placement="topLeft" title={'Delete this item'}>
								<Button type="primary" danger>
									<DeleteOutlined/>
								</Button>
							</Tooltip>
						</Popconfirm>
				</Button.Group>
			),
			width: 120
		},
	];

	const sideBarMenuItems = [ //FIXME action for page after refactor remove this code fragment
		{
			title: 'Create',
			icon:  <PlusCircleOutlined/>,
			action: () => onOpenModal('create')
		}
	]

	return (
		<>
			<Row justify={"space-between"}>
				<Title level={3} className={'layout_page_header'} style={{fontWeight: '500'}}>Datasets</Title>
				<Button style={{borderRadius:'10px', alignSelf:'center'}} type="default" onClick={() => onOpenModal('create')}>
					Добавить Файл
				</Button>
			</Row>
			<div className='container'>
				<Table
					columns={columns}
					dataSource={datasets}
					rowKey="name"
					loading={CRUDStatus === PENDING}
				/>
			</div>
		</>
	)
}

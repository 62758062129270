const authTypes = {
	onAuth: 'auth/onAuth',
	authPending: 'authPending',
	authSuccess: 'authSuccess',
	authError: 'authError',

	onRevoke: 'auth/onRevoke'
}


export const authActions = {

	onRevoke: () => {
		return {
			type: authTypes.onRevoke
		}
	},

	onAuth: (username, password) => {
		return {
			type: authTypes.onAuth,
			payload: {username, password}
		}
	},
	authPending: () => {
		return {
			type: authTypes.authPending
		}
	},
	authSuccess: () => {
		return {
			type: authTypes.authSuccess
		}
	},
	authError: () => {
		return {
			type: authTypes.authError
		}
	},
}

const mapTypes = {
	mapFetch: 'map/mapFetch',
	mapPending: 'mapPending',
	mapSuccess: 'mapSuccess',
	mapError: 'mapError',

	getGeoFetch: 'map/getGeoFetch',
	getGeoPending: 'getGeoPending',
	getGeoSuccess: 'getGeoSuccess',
	getGeoError: 'getGeoError',

	datasetFetch: 'map/datasetFetch',
	datasetPending: 'datasetPending',
	datasetSuccess: 'datasetSuccess',
	datasetError: 'datasetError',
}

export const mapActions = {

	datasetFetch: (id, randomMath) => {
		return {
			type: mapTypes.datasetFetch,
			payload: {id, randomMath}
		}
	},

	datasetPending: () => {
		return {
			type: mapTypes.datasetPending,
		}
	},

	datasetSuccess: (payload) => {
		return {
			type: mapTypes.datasetSuccess,
			payload: payload
		}
	},

	datasetError: () => {
		return {
			type: mapTypes.mapError,
		}
	},

	mapFetch: (payload) => {
		return {
			type: mapTypes.mapFetch,
			payload: payload
		}
	},

	mapPending: () => {
		return {
			type: mapTypes.mapPending,
		}
	},

	mapSuccess: (payload) => {
		return {
			type: mapTypes.mapSuccess,
			payload: payload
		}
	},

	mapError: () => {
		return {
			type: mapTypes.mapError,
		}
	},

	getGeoFetch: (payload) => {
		return {
			type: mapTypes.mapFetch,
			payload: payload
		}
	},

	getGeoPending: () => {
		return {
			type: mapTypes.mapPending,
		}
	},

	getGeoSuccess: (payload) => {
		return {
			type: mapTypes.mapSuccess,
			payload: payload
		}
	},

	getGeoError: () => {
		return {
			type: mapTypes.mapError,
		}
	},


}

import {useEffect, useState} from "react";
import {productsActions} from "../../../models/productsModel/productsActions";
import {connect} from "dva";
import {changeFormFieldsData} from "../../../Tools/changeFormFieldsData";
import {ProductsListComponent} from "./components/ProductsListComponent";
import {ProductsModal} from "./modals/ProductsModal";

const initialFields = [
	{
		name: 'name',
		value: ''
	},
	{
		name: 'description',
		value: ''
	},
	{
		name: 'description_full',
		value: ''
	},
	{
		name: 'path',
		value: ''
	},
	{
		name: 'type',
		value: ''
	},
	{
		name: 'is_demo',
		value: false
	},
]


export const ProductsConnector = (props) => {
	const {
		products,
		dispatch,
		productTypes
	} = props




	const [createProductsModal, setCreateProductsModal] = useState(false)
	const [updateProductsModal, setUpdateProductsModal] = useState(false)
	const [fields, setFields] = useState(initialFields);
	const [editorValue, setEditorValue] = useState('');
	const [editorValueFull, setEditorValueFull] = useState('');


	useEffect(() => {
		document.title = 'Admin - Products'
		dispatch(productsActions.getProductsFetch());
	}, [])

	const onCancel = () => {
		setFields(initialFields)
		setCreateProductsModal(false)
		setUpdateProductsModal(false)
		setEditorValue('')
	}

	const onDeleteClient = (id) => {
		dispatch(productsActions.deleteProductsFetch(id))
	}

	const onOpenModal = (type, value) => {
		if (type === "create") {
			setCreateProductsModal(true)
		} else if (type === "update") {

			setFields(changeFormFieldsData(
				fields,
				{
					...value,
					}
				))
			setUpdateProductsModal(value)
		}
	}

	const onSubmit = (value) => {
		if (createProductsModal) {
			dispatch(productsActions.createProductsFetch(value))
		} else if (updateProductsModal) {
			dispatch(productsActions.changeProductsFetch(value, updateProductsModal.id))
		}
		onCancel()
	}

	return (
		<>
			<ProductsModal
				createProductsModal={createProductsModal}
				updateProductsModal={updateProductsModal}
				productTypes={productTypes}
				onSubmit={onSubmit}
				fields={fields}
				onCancel={onCancel}
				products={products.data}
				setEditorValue={setEditorValue}
				editorValue={editorValue}
				editorValueFull={editorValueFull}
				setEditorValueFull={setEditorValueFull}
			/>
			<ProductsListComponent
				onOpenModal={onOpenModal}
				onDelete={onDeleteClient}
				products={products.data}
				CRUDStatus={products.data.CRUDStatus}
			/>
		</>
	)
}

function mapStateToProps(state) {
	return {
		products: state.products,
		productTypes: state.info.data.product_types
	}
}

export const ProductsContainer = connect(mapStateToProps)(ProductsConnector);


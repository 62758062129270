import axios from 'axios'
import {instance} from "../../api/api";

export const clientsApi = {
	getClientsList: () => {
		return instance().get(`/clients/`).then(res => res.data)
	},

	createClient: (payload) => {
		return instance().post(`/clients/`,payload).then(res => res.data)
	},

	changeClient: (client, id) => {
		return instance().put(`/clients/${id}`, client).then(res => res.data)
	},

	deleteClient: (id) => {
		return instance().delete(`/clients/purge/${id}`).then(res => res.data)
	}

};


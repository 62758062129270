import {Divider, List, Space, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import {formatDate} from "../../../../Tools/refactorDate";
import Card from "antd/es/card";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import FolderOpenOutlined from "@ant-design/icons/FolderOpenOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import Spin from "antd/es/spin";
import {PENDING} from "../../../../models/globalStatuses";
import Search from "antd/es/input/Search";
import DotChartOutlined from "@ant-design/icons/DotChartOutlined";

export const UserInterfaceProductsListComponent = (props) => {
	const {
		products,
		CRUDStatus,
		navigate,
		exportDatasetFile,
		onSearch,
		filteredData,
	} = props

	// if(products && products.length > 0){
	// 	console.log('products', products)
	// }

	return (
		<>
				<Title level={3} className={'layout_page_header'} style={{fontWeight: '500'}}>
					Дэшборд
				</Title>

				{CRUDStatus === PENDING ? <Spin /> :
					<>
						<Search placeholder="Введите запрос" onSearch={onSearch} enterButton />
						<div className="client_row container">
							{
								(filteredData.length ? filteredData : products  || []).map((product, index) => {

										if (product.type === 'dashboard') {
											return <Card
												type="inner"
												title={
													<span style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}>
									<p style={{margin: 0, fontSize: 18, fontWeight:'400', whiteSpace: 'initial'}}>{product.type === 'dashboard' &&
										<BarChartOutlined style={{fontSize: 24}}/>} {product.name}</p>
									{/*<p className={'product_tag'}*/}
									{/*   style={{margin: 0}}>{formatDate(product.created_at)}</p>*/}
								</span>
												}
												key={`card-key:${index}`}
											>
												<div style={{textAlign: 'left', height: '100%', overflow: 'hidden'}}>
													<div style={{marginTop: '10px'}}>Дата создания: {product.created_at.slice(0, product.created_at.indexOf('T'))}</div>
													{/*<Tooltip placement="topLeft">*/}
														<div style={{marginBottom: '15px', marginTop: '5px'}}>
															<div className={'product_card_p'} dangerouslySetInnerHTML={{__html: product.description}}></div>
														</div>
													{/*</Tooltip>*/}

													<Tooltip placement="topLeft" title={product.is_demo == true ? 'Перейти на демо дашборд' : 'Перейти на дашборд'}>
														<a
															className={product.is_demo == true ? 'products_button_demo' : 'products_button'}
														   onClick={() => navigate(`/dashboard/${product.id}`)}
														   type="default"
															style={{
																position: 'absolute',
																bottom: '22px',
																left: '16px',
															}}
														>
															{product.is_demo == true ? 'Демо' : 'Перейти'}
														</a>
													</Tooltip>

												</div>
											</Card>
										} else if (product.type === 'files') {
											return <Card
												type="inner"
												title={
													<span style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}>
										<p style={{margin: 0, fontSize: 18, whiteSpace: 'initial'}}>{product.type === 'files' &&
											<FolderOpenOutlined/>} {product.name}</p>
										{/*<p className={'product_tag'}*/}
										{/* 	style={{margin: 0}}>{formatDate(product.created_at)}</p>*/}
									</span>
												}
												key={`card-key:${index}`}
											>
												<div style={{display: 'flex', justifyContent: 'space-between'}}>
													<div style={{overflow: 'hidden'}}>
														<div style={{marginTop: '10px'}}>
															Дата создания: {product.created_at.slice(0, product.created_at.indexOf('T'))}
														</div>
														<div className={'product_card_p'}
															 dangerouslySetInnerHTML={{__html: product.description}}>

														</div>
														<Space/>
														{product.datasets.length > 0 &&
															<Divider style={{color: '#9370DB'}}>Datasets</Divider>
														}
														<List
															itemLayout="horizontal"
															bordered={true}
															dataSource={[product.datasets]}
															renderItem={datasetArray => (
																datasetArray.map(dataset =>
																	<List.Item
																		style={{position: 'relative'}}
																		actions={[
																			<Tooltip placement="topLeft"
																					 title={'Скачать датасет'}>
																				<a className='products_button' onClick={() => exportDatasetFile(dataset.id)}
																				   key={`dataset-${dataset.id}`}
																				>
																					<DownloadOutlined style={{fontSize: 20}}/>
																				</a>
																			</Tooltip>
																		]}
																	>
																		<List.Item.Meta
																			title={<p
																				style={{margin: 0}}>{`${dataset.name}`}</p>}
																			description={dataset.description}
																		/>
																		{/*<p style={{*/}
																		{/*	fontSize: 11,*/}
																		{/*	fontWeight: 500,*/}
																		{/*	position: 'absolute',*/}
																		{/*	right: 5,*/}
																		{/*	bottom: 2,*/}
																		{/*	margin: 0,*/}
																		{/*	color: '#1d39c4'*/}
																		{/*}}>*/}
																		{/*	{formatDate(dataset.created_at)}*/}
																		{/*</p>*/}
																	</List.Item>
																)
															)}
														/>
													</div>
												</div>
											</Card>
										} else {
											return <Card
												type="inner"
												title={
													<span style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}>
									<p style={{margin: 0, fontSize: 18, fontWeight:'400', whiteSpace: 'initial'}}>{product.type === 'map' &&
										<DotChartOutlined style={{fontSize: 24}}/>} {product.name}</p>
									{/*<p className={'product_tag'}*/}
									{/*   style={{margin: 0}}>{formatDate(product.created_at)}</p>*/}
								</span>
												}
												key={`card-key:${index}`}
											>
												<div style={{textAlign: 'left'}}>
													<div style={{marginTop: '10px'}}>Дата создания: {product.created_at.slice(0, product.created_at.indexOf('T'))}</div>

													<Tooltip placement='topLeft' >
														<div >
															<div className={'product_card_p'} dangerouslySetInnerHTML={{__html: product.description}}></div>
														</div>
													</Tooltip>
													<div style={{display: 'flex', gap: 10, position: 'absolute', bottom: '22px', left: '16px'}}>
														<Tooltip placement="topLeft" title={product.is_demo == true ? 'Перейти на демо карту' : 'Перейти на карту'}>
															<a className={product.is_demo == true ? 'products_button_demo' : 'products_button'} onClick={() =>
																navigate(`/map/${product.id}`)
															} type="default">
																{product.is_demo == true ? 'Демо' : 'Перейти'}
															</a>
														</Tooltip>
													</div>
												</div>
											</Card>
										}
									}
								)
							}
						</div>

					</>
				}
		</>
	)
}

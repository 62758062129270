export const districts_dictionary = {
  1: 'Алатауский район',
  2: 'Алмалинский район',
  3: 'Ауэзовский район',
  4: 'Бостандыкский район',
  5: 'Жетысуский район',
  6: 'Медеуский район',
  7: 'Наурызбайский район',
  8: 'Турксибский район'
}

export const districts_dictionary_pvl = {
  1: 'Центральный',
  2: 'Северный',
  3: 'Южный',
  4: 'Восточный',
  5: 'Западный',
}

import {authActions} from "./authActions";
import {authApi} from "./authApi";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import cookies from "react-cookies";

export const authModel = {
	namespace: 'auth',
	state: {
		authStatus: null
	},
	reducers: {
		authPending(state) {
			return {
				...state,
				authStatus: PENDING
			}
		},
		authSuccess(state) {
			return {
				...state,
				authStatus: SUCCESS
			}
		},
		authError(state) {
			return {
				...state,
				authStatus: ERROR
			}
		}
	},
	effects: {
		* onAuth(action, {put, call}) {
			const {username, password} = action.payload
			yield put(authActions.authPending())
			try {
				const data = yield call(() => authApi.onAuthApi(username, password))
				cookies.save('token', data, {path: '/'})
				yield put(authActions.authSuccess())
				document.location.reload()
			} catch (e) {
				yield put(authActions.authError())
				cookies.remove('token', {path: '/'})
			}
		},

		* onRevoke(action, {call}) {
			try {
				yield call(authApi.onRevoke)
				cookies.remove('token', {path: '/'})
				document.location.reload()
			} catch (e) {
				cookies.remove('token', {path: '/'})
				document.location.reload()
			}
		}
	}
}

import {instance} from "../../api/api";
import axios from "axios";
import {API} from "../../constants/api";
import cookies from "react-cookies";

// const FileDownload = require('js-file-download');

export const datasetsApi = {
	getDatasetsList: () => {
		return instance().get(`/datasets/`).then(res => res.data)
	},

	createDataset: (payload) => {
		return instance().post(`/datasets/`,payload).then(res => res.data)
	},

	changeDataset: (client, id) => {
		return instance().put(`/datasets/${id}`, client).then(res => res.data)
	},

	deleteDataset: (id) => {
		return instance().delete(`/datasets/purge/${id}`).then(res => res.data)
	},

	getExportDatasetFile: (id, randomMath) => axios({
		  url: `${API.url}v1/datasets/export/${id}?q=${randomMath}`, //your url
		  method: 'GET',
		  responseType: 'blob', // important
		  withCredentials: true
		  }).then((res) => {
			  console.log('res', res)
		const url = window.URL.createObjectURL(new Blob([res.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', ''); //or any other extension
		document.body.appendChild(link);
		link.click();
	}),

};


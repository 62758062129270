import React from 'react';
import {connect} from "dva";
import { data_dictionary, districts_dictionary_pvl } from './data_dictionary';
// import { district_to_district_area_dictionary } from '../District_area_region/district_to_district_district_area_dictionary';
// import MobileParentWidget from "../MobileParentWidget/MobileParentWidget";
import MobileParentWidget from "../MobileParentWidget/MobileParentWidget";
// import { districtAvgCounter } from './config_part';
// import { mapName, mobileMapModelStateName } from '../../../../../utils/map-variables';


let data = []
let active_filter = []

const emptyData = []
const DataHistogramWidget = connect((state, props) => {

  if (state.geo.almaty.start_data.widget_data) {

    // data = districts_amt_array.map(item => {
    //   return {
    //     item_name: item.district,
    //     item_value: item.avg_
    //   }
    // })

  }

  return {
    // ...props,
    data: data,
    active_filter: state.geo.almaty.active_filters.districts,
    dictionary: data_dictionary,
    item_name: 'item_name',
    filter_name: 'districts',
    widget_name: 'Дата',
    item_value: 'item_value',
    height_wrapper: '50%',
    widget_height: 'calc(100% - 40px)',
    absolute_left: 210,
    widget_type: 'sublayer'
  }
})(MobileParentWidget)
export default DataHistogramWidget


import moment from "moment";


export const formatDate = (data, format = 'YYYY-MM-DD HH:mm') => {
	if (data) {
		const date = new moment(data).format(format)
		return date.toString()
	}
	return '-'
}

export const getOnlyTime = (data) => {
	const date = new moment(data).format('HH:mm:ss')
	return date.toString()
}

export const addZeroToDate = (date) => {
	return date.toString().length === 1 ? `0${date}` : date
}

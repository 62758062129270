import React, {Fragment} from "react";
// import ActivityDays from "./Activity/ActivityDays";
// import ActivityHours from "./Activity/ActivityHours";
// import CrimesDays from "./Crimes/CrimesDays";
// import PassengerTrafficDays from "./PassengerTraffic/PassengerTrafficDays";
// import RoadViolationDays from "./RoadViolation/RoadViolationDays";
import style from "./MobileBottomBar.css"
import MobileGradientLine from './LegendBox/MobileGradientLine';
// import CrimesHours from './Crimes/CrimesHours';
// import PassengerTrafficHours from './PassengerTraffic/PassengerTrafficHours';
import { connect } from 'dva';
// import LayerToggler from './LayerToggler/LayerToggler';
// import AnimationPlayer from './AnimationPlayer/AnimationPlayer';
// import AnimationMode from '../../../AnimationMode/AnimationMode';
// import DatePicker from 'react-datepicker'
// import CalendarActivity from "./CalendarWrapper/CalendarActivity";
// import CalendarCrimes from "./CalendarWrapper/CalendarCrimes";
// import CalendarDtp from "./CalendarWrapper/CalendarDtp";
// import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";
// import {week_dictionary} from "../../../Dictionaries/week_dictionary";
// import { mapName, mobileMapModelStateName } from '../../../../utils/map-variables';
// import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
// import BottomBarPopup from '../../../BottomBarPopup/BottomBarPopup';
// import { popup_dict } from '../../../BottomBarPopup/popup_dict';

@connect((state) => {
  let active_layer = ''
  if (state.geo.almaty.active_layer) {
    active_layer = state.geo.almaty.active_layer
    return {
      // data,
      active_layer: active_layer,
      map_name: 'mobile',
      removing_zids_count: state.geo.almaty.active_filters.removing_zids.length,
      draw_mode: state.geo.almaty.draw_mode,
      DPCategory: state.geo.almaty.DPCategory,
    }
  }

  if(state.geo.almaty.layers[state.geo.almaty.active_layer].dates.length > 0){

    return {
      // data,
        map_name: 'mobile',
        active_layer: state.geo.almaty.active_layer,
      removing_zids_count: state.geo.almaty.active_filters.removing_zids.length,
      draw_mode: state.geo.almaty.draw_mode,
      DPCategory: state.geo.almaty.DPCategory,
    }
    //
    // return {
    //   data: data,
    //   current_date: state.mobile.dates.activity.current,
    //   active_layer: state.mobile.active_layer
    // }

  }

  return {
    // data: {
    //   startDate: new Date("2021/02/08"),
    //   endDate: new Date("2021/02/08")
    // },
    active_layer: state.geo.almaty.active_layer,
    map_name: '',
    removing_zids_count: 0,
    draw_mode: state.geo.almaty.draw_mode,
    DPCategory: state.geo.almaty.DPCategory,
  }
})

 class MobileBottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      activeTab: 1,
      startDate: new Date("2014/02/08"),
      endDate: new Date("2021/10/08")
    }
  }





  handleTab = (e) => {
    this.setState({
      activeTab: +e.target.getAttribute('data-name')
    })
    if (e.target.getAttribute('data-name') == 3) {
      this.props.dispatch(
          {
            type: 'geo/switchCrimesDtp',
            payload: 'road'
          }
      )
    }
    if (e.target.getAttribute('data-name') == 2) {
      this.props.dispatch(
          {
            type: 'geo/switchCrimesDtp',
            payload: 'crimes'
          }
      )
    }
  }

  componentDidUpdate() {
    if(this.props.active_layer == 'population' && this.state.activeTab == 1){
      this.setState({activeTab: 2})
    }
    if(this.props.active_layer == 'deficit' && this.state.activeTab == 1){
      this.setState({activeTab: 2})
    }
  }

  render () {
    const {activeTab} = this.state;
    console.log('active tab', activeTab)
    console.log('MobileBottomBar:: this.props', this.props)

    let message = '';
    switch (this.props.draw_mode){
      case 'draw_polygon': message = 'Включен режим выделения области (esc чтобы выключить)'; break;
      case 'multiple_dots': message = 'Включен режим добавления секторов (esc чтобы выключить)'; break;
      case 'remove_zid': message = 'Включен режим удаления сектора (esc чтобы выключить)'; break;
      case 'remove_multiple_dots': message = 'Включен режим удаления точек (esc чтобы выключить)'; break;
      default: this.props.removing_zids_count > 0 ? message = 'Несколько зидов удалены' : message = ''; break;
    }

    return (
        <div className="container" id="map_bottom_bar">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '5px',
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 1000
          }} className="top_row">
            <MobileGradientLine/>
              {/*<p>lore   </p>*/}

          </div>

        </div>
    )
  }
}

export default MobileBottomBar;

import {regActions} from "./regActions";
import {regApi} from "./regApi";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import cookies from "react-cookies";
import {clientsActions} from "../clientsModel/clientsActions";
import {clientsApi} from "../clientsModel/clientsApi";
import {showError} from "../../Tools/showError";
import {authActions} from "../authModel/authActions";

export const regModel = {
	namespace: 'reg',
	state: {
		regStatus: null
	},
	reducers: {
		regPending(state) {
			return {
				...state,
				regStatus: PENDING
			}
		},
		regSuccess(state) {
			return {
				...state,
				regStatus: SUCCESS
			}
		},
		regError(state) {
			return {
				...state,
				regStatus: ERROR
			}
		}
	},
	effects: {


		* onReg({payload}, {put, call}) {
			try {
				const data = yield call(() => regApi.onRegApi(payload))
				yield put(regActions.regSuccess(data))
				yield put(authActions.onAuth(payload.email, payload.password))
			} catch (e) {
				showError(e)
				if (e.message.includes('409')) {
					console.log('conflict')
				} else {
					console.log('unknown error')
				}
			}
		},




		// * onReg(action, {put, call}) {
		// 	const {registration_token, username, name, email, phone, password, position} = action.payload
		// 	yield put(regActions.regPending())
		// 	try {
		// 		const data = yield call(() => regApi.onRegApi(registration_token, username, name, email, phone, password, position))
		// 		cookies.save('token', data, {path: '/'})
		// 		yield put(regActions.regSuccess())
		// 		document.location.reload()
		// 	} catch (e) {
		// 		yield put(regActions.regError())
		// 		cookies.remove('token', {path: '/'})
		// 	}
		// },

		* onRevoke(action, {call}) {
			try {
				yield call(regApi.onRevoke)
				cookies.remove('token', {path: '/'})
				document.location.reload()
			} catch (e) {
				cookies.remove('token', {path: '/'})
				document.location.reload()
			}
		}
	}
}

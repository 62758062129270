import Button from "antd/es/button";
import Modal from "antd/es/modal/Modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import {Row, Switch, Col, Select} from "antd";
import {useEffect, useState} from "react";
import {clientsActions} from "../../../../models/clientsModel/clientsActions";
import {Option} from "antd/es/mentions";
import generatePassword from "password-generator";
import InputMask from 'react-input-mask';
import ModalStyles from './ModalStyles.css'

const layout = {
	labelCol: {span: 4},
	wrapperCol: {span: 18},
};





export const UsersModal = (props) => {
	const {
		createUsersModal,
		updateUsersModal,
		onSubmit,
		fields,
		onCancel,
		dispatch,
		clients,
		users,
		form
	} = props


	useEffect(() => {
		dispatch(clientsActions.getClientsFetch())
	}, [])

	const generateRandomPassword = () => {
		form.setFieldsValue({password: generatePassword(12, false)})
	}

	form.setFieldsValue({password: ''})

	const formItem = {
		width: '30%',
		marginRight: '3%',
		display: 'flex',
		flexDirection: 'column'
	}
	const inputStyle = {
		borderRadius: '8px'
	}

	const formRow = {
		display: 'flex',
		alignItems: 'center'
	}


	return (
		<Modal title={updateUsersModal ? "Update User" : "Создать пользователя"}
			   visible={updateUsersModal || createUsersModal}
			   destroyOnClose={true}
			   onCancel={onCancel}
			   forceRender
			   style={{top: 30}}
			   footer={[
				   <div key={1}>
					   <Button onClick={onCancel} className='basic-btn' type={"primary"}>
						   Назад
					   </Button>
					   <Button form="usersForm" htmlType="submit" className='basic-btn' type={"primary"}>
						   Создать пользователя
					   </Button>
				   </div>
			   ]}
			   width={850}>
			<Form
				id="usersForm"
				{...layout}
				name="usersForm"
				fields={fields}
				form={form}
				onFinish={onSubmit}
			>

				<div style={{...formRow}}>
					<Form.Item
						label="ФИО"
						name="name"
						rules={[{required: true, message: 'Enter name!'}]}
						labelCol={{span: 24}}
						style={{...formItem}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>

					<Form.Item
						label="Логин"
						name="username"
						style={{...formItem}}
						labelCol={{span: 24}}
						rules={[
							{
								required: true
							},
							() => ({
								validator(rule, value) {
									if (value && users.findIndex(item => item.username.toLowerCase() === value.toLowerCase() && item.id !== updateUsersModal.id) !== -1) {
										return Promise.reject(new Error('Enter unique name!'));
									}
									return Promise.resolve()
								}
							})
						]}
					>
						<Input autoComplete="off" style={{...inputStyle}}/>
					</Form.Item>
				</div>

				<div style={{...formRow}}>
					<Form.Item
						label="Номер телефона"
						name="phone"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[{
							required: true,
						},
							() => ({
								validator(rule, value) {
									const reg = new RegExp('^[0-9]+$');
									if (value && users.findIndex(item => item.phone === value && !reg.test(value) && item.id !== updateUsersModal.id) !== -1) {
										return Promise.reject(new Error('Enter unique phone number!'));
									}
									return Promise.resolve()
								}
							})
						]}
					>
						<InputMask
							style={{
								width: '100%',
								border: '1px solid #d9d9d9',
								fontSize: 13,
								height: 30,
								padding: '0 10px',
								outline: 'none',
								borderRadius: '8px'
							}}
							className="input_focus"
							mask='(+7) 999 999 9999'
						>
						</InputMask>
					</Form.Item>

					<Form.Item
						label="Email"
						name="email"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[
							{
								required: true,
								type: 'email'
							},
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
				</div>


				<div style={{...formRow}}>
					<Form.Item
						label="Компания (Клиент)"
						name="client_id"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[{required: true, message: 'Select client!'}]}
					>
						<Select
							showSearch
							allowClear
							placeholder="Search to Select"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}

						>
							{clients.map(item => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>


					<Form.Item
						label="Номер договора"
						name="contract"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>

					<Form.Item
						label="Должность"
						name="position"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>

				</div>


				<div style={{...formRow}}>
					<Form.Item
						label="Password"
						name="password"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[{required: createUsersModal ? true : false, message: 'Enter password!'}]}
					>
						<Input.Password autoComplete={'off'} style={{...inputStyle}}/>
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						labelCol={{span: 24}}
						style={{...formItem}}
						rules={[{required: createUsersModal ? true : false, message: 'Enter password!'}]}
					>
						<Input.Password autoComplete={'off'} style={{...inputStyle}}/>
					</Form.Item>
					<Button onClick={generateRandomPassword} className='basic-btn' type={"primary"}>Сгенерировать пароль</Button>
				</div>

				<Row justify={'center'} align={'middle'}>
					<Col flex={'0 0 20%'} span={8}>
						<Form.Item
							labelCol={24}
							label="Is blocked"
							name="is_blocked"
							valuePropName={"checked"}
						>
							<Switch defaultChecked  style={{backgroundColor: '#292974'}}/>
						</Form.Item>
					</Col>
					<Col flex={'0 0 20%'} span={8}>
						<Form.Item
							labelCol={24}
							label="Is admin"
							name="is_admin"
							valuePropName={"checked"}
						>
							<Switch defaultChecked style={{backgroundColor: '#292974'}}/>
						</Form.Item>
					</Col>
				</Row>





				{/*<Form.Item*/}
				{/*	label="Номер телефона"*/}
				{/*	name="phone"*/}
				{/*	rules={[{*/}
				{/*		required: true,*/}
				{/*	},*/}
				{/*		() => ({*/}
				{/*			validator(rule, value) {*/}
				{/*				const reg = new RegExp('^[0-9]+$');*/}
				{/*				if (value && users.findIndex(item => item.phone === value && !reg.test(value) && item.id !== updateUsersModal.id) !== -1) {*/}
				{/*					return Promise.reject(new Error('Enter unique phone number!'));*/}
				{/*				}*/}
				{/*				return Promise.resolve()*/}
				{/*			}*/}
				{/*		})*/}
				{/*	]}*/}
				{/*>*/}
				{/*	<InputMask*/}
				{/*		style={{*/}
				{/*			width: 150,*/}
				{/*			border: '1px solid #d9d9d9',*/}
				{/*			fontSize: 13,*/}
				{/*			height: 30,*/}
				{/*			padding: '0 10px',*/}
				{/*			outline: 'none'*/}
				{/*		}}*/}
				{/*		className="input_focus"*/}
				{/*		mask='(+7) 999 999 9999'*/}
				{/*	>*/}
				{/*	</InputMask>*/}
				{/*</Form.Item>*/}
				{/*<Form.Item*/}
				{/*	label="Client"*/}
				{/*	name="client_id"*/}
				{/*	rules={[{required: true, message: 'Select client!'}]}*/}
				{/*>*/}
				{/*	<Select*/}
				{/*		showSearch*/}
				{/*		allowClear*/}
				{/*		placeholder="Search to Select"*/}
				{/*		optionFilterProp="children"*/}
				{/*		filterOption={(input, option) =>*/}
				{/*			option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
				{/*		}*/}
				{/*		filterSort={(optionA, optionB) =>*/}
				{/*			optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())*/}
				{/*		}*/}
				{/*	>*/}
				{/*		{clients.map(item => (*/}
				{/*			<Option key={item.id} value={item.id}>*/}
				{/*				{item.name}*/}
				{/*			</Option>*/}
				{/*		))}*/}
				{/*	</Select>*/}
				{/*</Form.Item>*/}
				{/*<Form.Item*/}
				{/*	label="Email"*/}
				{/*	name="email"*/}
				{/*	rules={[*/}
				{/*		{*/}
				{/*			required: true,*/}
				{/*			type: 'email'*/}
				{/*		},*/}
				{/*	]}*/}
				{/*>*/}
				{/*	<Input/>*/}
				{/*</Form.Item>*/}
				{/*<div style={{position: 'unset', display: 'flex', justifyContent: 'center'}}>*/}
				{/*<Form.Item*/}
				{/*	label="Password"*/}
				{/*	name="password"*/}
				{/*	style={{width: '67.5%'}}*/}
				{/*	rules={[{required: createUsersModal ? true : false, message: 'Enter password!'}]}*/}
				{/*>*/}
				{/*	<Input.Password autoComplete={'off'}/>*/}
				{/*</Form.Item>*/}
				{/*<Button onClick={generateRandomPassword} style={{marginRight: 20}}>Generate password</Button>*/}
				{/*</div>*/}

			</Form>
		</Modal>
	)
}

import style from './MobileGradientLine.css';
import React, {Component} from 'react';
import { formatNumberWithoutSeparator } from './fnum';

export default class MobileGradientLineItem extends Component {
  constructor(props) {
    super(props);
  }
  onClick = (counter, active) => {
    this.props.changeActiveItem(counter, active)
    // this.setState({
    //   active: !this.state.active
    // })
  }
  // checkActive = () => {
  //   if(this.props.active == this.props.counter){
  //     this.setState({active: true})
  //   }
  // }
  render() {
    const { color, maxGradient, counter, active } = this.props

    return <li className="relativeBox" onClick={() => this.onClick(counter, active)}>
      <div className={"colorBox" + ' ' + (counter === active ? "active" : '')} style={{backgroundColor: color}}>
        <span/>
      </div>
      <div className="intSeparator">
            <span className="intColor">
              {formatNumberWithoutSeparator(maxGradient == 'infinity' ? '∞' : maxGradient)}
            </span>
      </div>
    </li>
  }

  componentDidUpdate() {
    if(this.props.active == this.props.counter){
      this.setState({active: true})
    }
  }
}

import {Popconfirm, Table, Tooltip} from "antd";
import Layout from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Button from "antd/es/button";
import {formatDate} from "../../../../Tools/refactorDate";
import {UnorderedListOutlined} from "@ant-design/icons";
import {PENDING} from "../../../../models/globalStatuses";
import moment from "moment";
import Switch from "antd/es/switch";
import {usersActions} from "../../../../models/usersModel/usersActions";

export const UsersListComponent = (props) => {
	const {
		users,
		onOpenModal,
		onOpenBindProductsToUserModal,
		onOpenMassBindProductsToUserModal,
		createUsersModal,
		onOpenCreateUserModal,
		onDelete,
		CRUDStatus,
		onBlock
	} = props


	const columns = [
		{
			title: 'Логин',
			dataIndex: 'username',
			key: 'username',
			render: (_, user) => (<Tooltip placement='topLeft' title={user.username}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100px'}}>{user.username || '-'}</div></Tooltip>)
		},
		{
			title: 'ФИО',
			dataIndex: 'name',
			key: 'name',
			render: (_, user) => (<Tooltip placement='topLeft' title={user.name}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100px'}}>{user.name || '-'}</div></Tooltip>)
		},
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			render: (_, user) => (<Tooltip placement='topLeft' title={user.email}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100px'}}>{user.email || '-'}</div></Tooltip>)
		},
		{
			title: 'Телефон',
			dataIndex: 'phone',
			key: 'phone',
			render: (_, user) => `${user.phone || '-'}`,
		},
		{
			title: 'Компания (Клиент)',
			dataIndex: 'client_name',
			key: 'client_name',
		},
		// {
		// 	//FIXME Index and key must be changed
		// 	title: '№ догов.',
		// 	dataIndex: 'agreement_num',
		// 	key: 'agreement_num',
		// },
		{
			//FIXME Index and key must be changed
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
			render: (_, user) => `${(user.is_admin? 'Admin':'User')}`,
		},
		//FIXME This column is not in Figma and users objects doesn't have created_by prop
		// {
		// 	title: 'Кто создал',
		// 	dataIndex: 'created_by',
		// 	key: 'created_by'
		// },
		{
			title: 'Дата создания',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (_, user) => `${formatDate(user.created_at)}`,
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
			width: 120,
			align: 'center'
		},
		{
			title: 'Дата изменения',
			dataIndex: 'updated_at',
			key: 'updated_at',
			render: (_, user) => `${formatDate(user.updated_at)}`,
			defaultSortOrder: 'descend',
			sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
			width: 120,
			align: 'center'
		},
		{
			//FIXME Index and key must be changed
			title: 'Посл. посещ.',
			dataIndex: 'last_login',
			key: 'last_login',
			render: (_, user) => `${formatDate(user.login_at)}`,
		},
		{
			title: 'Блок.',
			key: 'block',
			width: 44,
			render: (_, user) => (<Switch
				className={
					user.is_blocked ? 'block' : 'active'
				}
				onChange={() => { onBlock(user.id, user.is_blocked) }}
				checked={user.is_blocked}
			/>)
		},
		{
			title: 'Действия',
			align: 'center',
			key: 'actions',
			render: (_, user) => (
				<Button.Group>
					<Tooltip placement="topLeft" title={'Bind products to this user'}>
						<Button type="default" onClick={() => onOpenBindProductsToUserModal(user)}>
							<UnorderedListOutlined />
						</Button>
					</Tooltip>
						<Tooltip placement="topLeft" title={'Edit user'}>
								<Button type="default" onClick={() => onOpenModal('update', user)}>
									<EditOutlined/>
								</Button>
						</Tooltip>
						<Popconfirm
							title="Are you sure to delete this user?"
							onConfirm={() => onDelete(user.id)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip placement="topLeft" title={'Delete this item'}>
								<Button type="primary" danger>
									<DeleteOutlined/>
								</Button>
							</Tooltip>
						</Popconfirm>
				</Button.Group>
			),
			width: 120
		},
	];

	const sideBarMenuItems = [ //FIXME action for page after refactor remove this code fragment
		{
			title: 'Create',
			icon:  <PlusCircleOutlined/>,
			action: () => onOpenModal('create')
		}
	]


	const downloadReport = () => {
		window.open(`${window.location.origin}/api/v1/users/get_users_info`, "_blank")
	}

	return (
		<>
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#fff'}}>
				<Title level={3} className={'layout_page_header'} style={{fontWeight: '500'}}>Пользователи</Title>
				<div className="box">
					<Button type="default" style={{marginRight: '10px', borderRadius:'10px'}} onClick={() => downloadReport()}>Скачать отчёт</Button>
					<Button type="default" style={{marginRight: '10px', borderRadius:'10px'}} onClick={() => onOpenMassBindProductsToUserModal(true)}>Привязка продукта</Button>
					<Button type="default" style={{borderRadius:'10px'}} onClick={() => onOpenCreateUserModal(true)}>Создать пользователя</Button>
				</div>

			</div>
			<div className='container' style={{fontWeight: '400'}}>
				<Table
					columns={columns}
					dataSource={users}
					rowKey="id"
					loading={CRUDStatus === PENDING}
					pagination={{ pageSize: 10 }}
				/>
			</div>
		</>
	)
}

import Modal from "antd/es/modal/Modal";
import Form from "antd/es/form";
import {DatePicker, Select, Space} from "antd";
import Button from "antd/es/button";
import * as PropTypes from "prop-types";

const {Option} = Select

const layout = {
	labelCol: {span: 6},
	wrapperCol: {span: 24},
};

const { RangePicker } = DatePicker;

RangePicker.propTypes = {
	onOk: PropTypes.any,
	onChange: PropTypes.any,
	showTime: PropTypes.shape({format: PropTypes.string}),
	format: PropTypes.string
};

export const MassBindProductsToUserModal = (props) => {
	const {
		massBindProductsToUserModal,
		onSubmitCreateMassBindProducts,
		onCancel,
		products,
		fields,
		users
	} = props

	console.log('MassBindProductsToUserModal render')

	const [form] = Form.useForm();

	return (
		<>
			<Modal  title="Привязка продукта"
				   destroyOnClose={true}
				   visible={massBindProductsToUserModal}
				   onCancel={onCancel}
				   width={600}
				   footer={[
					   <div key={1} id='bind-products'>
						   <Button className='basic-btn' onClick={onCancel}>
							   Назад
						   </Button>
						   <Button className='basic-btn' form="massProductsForm" type={"primary"} htmlType="submit">
							   Связать продукты
						   </Button>
					   </div>
				   ]}
			>

				<Form
					id="massProductsForm"
					form={form}
					{...layout}
					name="massProductsForm"
					fields={fields}
					onFinish={onSubmitCreateMassBindProducts}
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						flexDirection: 'column',
						alignItems: 'center',

				}}
				>
						<Form.Item
							label="Продукты"
							name="products"
							rules={[{required: true, message: 'Выберите продукты!'}]}
							style={{width: '400px'}}
						>
							<Select
								showSearch
								allowClear
								mode={'multiple'}
								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
							>
								{products.map(item => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Form.Item>


						<Form.Item
							label="Пользователи"
							name="users"
							rules={[{required: true, message: 'Выберите пользователей!'}]}
							style={{width: '400px'}}
						>
							<Select
								showSearch
								allowClear
								mode={'multiple'}
								placeholder="Search to Select"
								optionFilterProp="children"
								style={{
									width: '100%'
								}}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
							>
								{massBindProductsToUserModal ? users.map(item => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								)) : ''}
								{/*{users.map(item => (*/}
								{/*	<Option key={item.id} value={item.id}>*/}
								{/*		{item.name}*/}
								{/*	</Option>*/}
								{/*))}*/}
							</Select>
						</Form.Item>


					<Form.Item
						label="Даты"
						name="date_from_to"
						style={{width: '400px'}}
						rules={[{required: true, message: 'Выберите даты!'}]}
					>
						<RangePicker
							showTime={{format: 'HH:mm'}}
							format="YYYY-MM-DD HH:mm"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}


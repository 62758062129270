import React from 'react';
import WidgetWrapper from "./WidgetWrapper";
import { defineCustomElements } from '@carto/airship-components/dist/loader';
import style from "../OfdRightBar/Rightbar.css";


defineCustomElements(window);

const emptyData = []
let checkTwinData = null

 class MobileParentWidget extends WidgetWrapper {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  formatData() {
    if(this.props.data.length > 0){
      let checkDictionary = Object.keys(this.props.dictionary).length > 0 ? this.props.dictionary : ''
      // let data = this.props.data.map(item => {
      window.data = this.props.data.map(item => {
        if(this.props.item_name) {
          let dictionary = this.props.dictionary ? this.props.dictionary : ''
          return {
            name: Object.keys(this.props.dictionary).length > 0 ? dictionary[item[this.props.item_name]] : item[this.props.item_name],
            value: item[this.props.item_value]
          }
        }
      })
      window.data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
      // data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
      console.log('data', data)
      return window.data
    } else {
      return []
    }

  }

  render() {
    console.log('widget wrapper parent', this.props)
    // console.log('render component - ', this.props.widget_name)
    return (
      <div
        style={{
          height: this.props.height_wrapper ? this.props.height_wrapper : "100%",
          display: 'flex',
          flexDirection: 'column',
          marginTop: '2%',
        }}
      >
        <div className="widget_title">
          {this.props.widget_name}
        </div>
        <as-category-widget
          style={{
            height: this.props.widget_height,
            padding: '0 5px 0 10px',
            marginTop: '10px',
          }}
          data={[
            { start: 0, end: 10, value: 1050 },
            { start: 10, end: 20, value: 1150 },
            { start: 20, end: 30, value: 1250 },
            { start: 30, end: 40, value: 1350 },
            { start: 40, end: 50, value: 2000 },
          ]}
          ref={this.ref}
          // heading={this.props.widget_name}
          heading={this.props.widget_name}
          show-header={false}
          disable-interactivity={this.props.disable_interactivity == true ? true : false}
          selectedCategories={this.props.dictionary[this.props.active_filter]}
        />
      </div>
    );
  }

  _onSelectedChanged(event) {
    let filter_value = []

    //FIXME if user did cancel select, add to active_filters.types return selected only hospital
    // this.props.filter_name == "types" ? filter_value.push('9') : ''


    if(Object.keys(this.props.dictionary).length) {
      event.detail.map(item =>
        filter_value.push(Object.keys(this.props.dictionary).find(
            key => this.props.dictionary[key] === item
            )
        ))
    } else {
      filter_value = event.detail
    }

    if (this.props.widget_type === 'sublayer') {
        this.props.dispatch(
            {
                type: 'geo/changeSubLayerEffect',
                payload: value
            }
        )
    } else {
        if(
            filter_value.length != 0 && this.props.active_filter != 0 &&
            filter_value[0] == this.props.active_filter
        ) {
            this.props.dispatch(
                {
                    type: 'geo/changeActiveFilter',
                    payload: {
                        name: this.props.filter_name,
                        value: [],

                    }
                }
            )
        } else {
            this.props.dispatch(
                {
                    type: 'geo/changeActiveFilter',
                    payload: {
                        name: this.props.filter_name,
                        value: filter_value
                    }
                }
            )
        }
    }




  }

  componentDidMount() {
    // const widget = this.ref.current;
    window.widget = this.ref.current;
    widget.addEventListener('categoriesSelected', this._onSelectedChanged.bind(this));

    widget.categories = this.formatData()
    // if(this.props.widget_name == 'Cтатистика по адм.районам'){
    //   widget.selectedCategories = this.props.active_filter.length > 0 ? [this.props.dictionary[this.props.active_filter]] : false
    // }
    // this.props.dictionary[this.props.active_filter]
  }

  componentWillUnmount() {
    console.log('component unmout - ', this.props.widget_name)
  }

  componentDidUpdate(prevProps) {
    const widget = this.ref.current;
    // if(this.props.widget_name == 'Cтатистика по адм.районам'){
    // }
    if (JSON.stringify(prevProps.data) != JSON.stringify(this.props.data)) {
      widget.categories = this.formatData()}
    if(prevProps.active_filter.length > 0 && this.props.active_filter.length == 0){
      this.clearSelection()
    }
  }
}

export default MobileParentWidget;

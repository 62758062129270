import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {datasetsActions} from "./datasetsActions";
import {datasetsApi} from "./datasetsApi";
import {showError} from "../../Tools/showError";

export const datasetsModel = {
	namespace: 'datasets',
	state: {
		data: [],
		CRUDStatus: null
	},
	reducers: {
		getDatasetsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getDatasetsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: payload
			}
		},
		getDatasetsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		createDatasetsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		createDatasetsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					   payload
				]
			}
		},
		createDatasetsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
		changeDatasetsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		changeDatasetsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data.map(item => item.id === payload.id ?
						{
							...payload,
							key: `item-${item.id}`
						} : item)
				]
			}
		},
		changeDatasetsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		deleteDatasetsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		deleteDatasetsSuccess(state, {payload}) {
			return {
				...state,
				data: [
					...state.data,
					...state.data.filter(item => item.id !== payload)
				],
				CRUDStatus: SUCCESS
			}
		},
		deleteDatasetsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getExportDatasetFilePending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getExportDatasetFileSuccess(state) {
			return {
				...state,
				CRUDStatus: SUCCESS,
			}
		},
		getExportDatasetFileError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

	},

	effects: {

		* getExportDatasetFileFetch({payload}, {put, call}) {
			yield put(datasetsActions.getExportDatasetFilePending())
			try {
				yield call(() => datasetsApi.getExportDatasetFile(payload.id, payload.randomMath))

				yield put(datasetsActions.getExportDatasetFileSuccess())
			} catch (e) {
				showError(e)
				yield put(datasetsActions.getExportDatasetFileError())
			}
		},

		* deleteDatasetsFetch({payload}, {put, call}) {
			yield put(datasetsActions.deleteDatasetsPending())
			try {
				const datasetId = yield call(() => datasetsApi.deleteDataset(payload))
				yield put(datasetsActions.deleteDatasetsSuccess(datasetId))
				message.success('Dataset was deleted')
			} catch (e) {
				yield put(datasetsActions.deleteDatasetsError())
			}

		},

		* changeDatasetsFetch({payload}, {put, call}) {
			yield put(datasetsActions.changeDatasetsPending())
			try {
				const dataset = yield call(() => datasetsApi.changeDataset(payload.dataset, payload.id))
				yield put(datasetsActions.changeDatasetsSuccess(dataset))
			} catch (e) {
				showError(e)
				yield put(datasetsActions.changeDatasetsError())
			}
		},

		* createDatasetsFetch({payload}, {put, call}) {
			yield put(datasetsActions.createDatasetsPending())
			try {
				const dataset = yield call(() => datasetsApi.createDataset(payload))
				yield put(datasetsActions.createDatasetsSuccess(dataset))
			} catch (e) {
				showError(e)
				yield put(datasetsActions.createDatasetsError())
			}
		},

		* getDatasetsFetch({payload}, {put, call}) {
			yield put(datasetsActions.getDatasetsPending())
			try {
				const datasets = yield call(() => datasetsApi.getDatasetsList(payload))
				yield put(datasetsActions.getDatasetsSuccess(datasets))

			} catch (e) {
				yield put(datasetsActions.getDatasetsError())

			}
		},
	}
}

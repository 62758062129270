export const API = {
	// url: 'https://clavem.frontier.kz/api/',
	// url: `${window.location.origin}/api/`,
	url: window.location.href.includes('localhost') ? `https://platform.saudata.kz/api/` : `${window.location.origin}/api/`,
	// url: 'http://192.168.4.51:7007/api/',
	version: 'v1',
}



/*
url: window.location.href.includes('localhost') ? 'https://platform.saudata.kz/api/' : `${window.location.origin}/api/`,*/

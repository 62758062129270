import {PENDING, SUCCESS, ERROR} from "../globalStatuses";
import {productsActions} from "./productsActions";
import {productsApi} from "./productsApi";
import {showError} from "../../Tools/showError";

export const productsModel = {
	namespace: 'products',
	state: {
		data: [],
		view: {
			viewData: [],
			CRUDStatus: null
		},
		CRUDStatus: null,
		BiToken: ''
	},
	reducers: {
		getProductsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getProductsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: payload
			}
		},
		getProductsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getProductsForCurrentUserPending(state) {
			return {
				...state,
				view: {
					...state.view.viewData,
					CRUDStatus: PENDING
				}
			}
		},
		getProductsForCurrentUserSuccess(state, {payload}) {
			return {
				...state,
				view: {
					viewData: payload,
					CRUDStatus: SUCCESS,
				},
			}
		},
		getProductsForCurrentUserError(state) {
			return {
				...state,
				view: {
					...state.view.viewData,
					CRUDStatus: ERROR
				}
			}
		},


		createProductsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		createProductsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data,
					payload
				]
			}
		},
		createProductsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
		changeProductsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},

		changeProductsSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: [
					...state.data.map(item => item.id === payload.id ?
						{
							...payload,
							key: `item-${item.id}`
						} : item)
				]
			}
		},
		changeProductsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		deleteProductsPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		deleteProductsSuccess(state, {payload}) {
			return {
				...state,
				data: [
					...state.data.filter(item => item.id !== payload)
				],
				CRUDStatus: SUCCESS
			}
		},
		deleteProductsError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getBiAccessTokenPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getBiAccessTokenSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				BiToken: payload
			}
		},
		getBiAccessTokenError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},

		getPublicPending(state) {
			return {
				...state,
				CRUDStatus: PENDING
			}
		},
		getPublicSuccess(state, {payload}) {
			return {
				...state,
				CRUDStatus: SUCCESS,
				data: payload
			}
		},
		getPublicError(state) {
			return {
				...state,
				CRUDStatus: ERROR
			}
		},
	},

	effects: {

		* getBiAccessTokenFetch({payload}, {put, call}) {
			yield put(productsActions.getBiAccessTokenPending())
			try {
				const biToken = yield call(() => productsApi.getBiAccessToken(payload))
				yield put(productsActions.getBiAccessTokenSuccess(biToken))
			} catch (e) {
				showError(e)
				yield put(productsActions.getBiAccessTokenError())
			}
		},

		* deleteProductsFetch({payload}, {put, call}) {
			yield put(productsActions.deleteProductsPending())
			try {
				const productId = yield call(() => productsApi.deleteProduct(payload))
				yield put(productsActions.deleteProductsSuccess(productId))
				message.success('Product was deleted')
			} catch (e) {
				showError(e)
				yield put(productsActions.deleteProductsError())
			}

		},

		* changeProductsFetch({payload}, {put, call}) {
			yield put(productsActions.changeProductsPending())
			try {
				const product = yield call(() => productsApi.changeProduct(payload.product, payload.id))
				yield put(productsActions.changeProductsSuccess(product))
			} catch (e) {
				showError(e)
				yield put(productsActions.changeProductsError())
			}
		},

		* createProductsFetch({payload}, {put, call}) {
			yield put(productsActions.createProductsPending())
			try {
				const product = yield call(() => productsApi.createProduct(payload))
				yield put(productsActions.createProductsSuccess(product))
			} catch (e) {
				showError(e)
				yield put(productsActions.createProductsError())
			}
		},

		* getProductsForCurrentUserFetch(action, {put, call}) {
			yield put(productsActions.getProductsForCurrentUserPending())
			try {
				const products = yield call(() => productsApi.getProductsListForCurrentUser())
				yield put(productsActions.getProductsForCurrentUserSuccess(products))

			} catch (e) {
				showError(e)
				yield put(productsActions.getProductsForCurrentUserError())

			}
		},

		* getProductsFetch(action, {put, call}) {
			yield put(productsActions.getProductsPending())
			try {
				const products = yield call(() => productsApi.getProductsList())
				yield put(productsActions.getProductsSuccess(products))

			} catch (e) {
				showError(e)
				yield put(productsActions.getProductsError())

			}
		},

		* getPublicFetch(action, {put, call}) {
			yield put(productsActions.getPublicPending())
			try {
				const products = yield call(() => productsApi.getProductsList())
				yield put(productsActions.getPublicSuccess(products))

			} catch (e) {
				showError(e)
				yield put(productsActions.getPublicError())

			}
		},
	}
}

const regTypes = {
	onReg: 'reg/onReg',
	regPending: 'regPending',
	regSuccess: 'regSuccess',
	regError: 'regError',

	onRevoke: 'reg/onRevoke'
}


export const regActions = {

	onRevoke: () => {
		return {
			type: regTypes.onRevoke
		}
	},

	onReg: (payload) => {
		return {
			type: regTypes.onReg,
			payload: payload
		}
	},

	regPending: () => {
		return {
			type: regTypes.regPending
		}
	},
	regSuccess: () => {
		return {
			type: regTypes.regSuccess
		}
	},
	regError: () => {
		return {
			type: regTypes.regError
		}
	},
}

export const formatNumber = (x) => {
  {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return x;
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + " млн";
    }

    if (x < 100000000000) {
      return (x / 1000000000).toFixed(2) + "млрд"
    }

    return "1T+";
  }
}

export const formatNumberStatistic = (x) => {
  {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x;
    }

    if (x < 1000000) {
      return x;
    }
    if (x < 10000000) {
      return (x / 1000000).toFixed(2) + " млн";
    }

    if (x < 100000000000) {
      return (x / 1000000000).toFixed(2) + " млрд"
    }

    if(x < 1000000000000000){
      return (x / 1000000000000).toFixed(2) + " трлн"
    }

    return "1К+";
  }
}

export const formatNumberWithoutSeparator = (x) => {
  {
    if (isNaN(x)) return x;

    if (x < 9999) {
      return x.toFixed(0);
    }

    if (x < 1000000) {
      return (x / 1000).toFixed(0) + " к";;
    }
    if (x < 999999999 ) {
      return (x / 1000000).toFixed(0) + " млн";
    }

    if (x >= 1000000000) {
      return (x / 1000000000).toFixed(0) + " млрд"
    }

    return "1T+";
  }
}

import React from 'react';
import { connect } from 'dva';
import { avg_receipts_amt } from './data_dictionary'
import MobileDoubleParentWidget from "../MobileDoubleParentWidget";


const emptyData = []
let data = []

const DataReceiptsAmt = connect((state, props) => {


  const map = 'mobile'
  let layer = state.geo.almaty.active_layer
  // let sublayer = state.geo.almaty.active_sublayer
  let filterd_wiget_data = []



    if (state.geo.almaty.start_data.widget_data) {
      // data = state.geo.almaty.start_data.widget_data[layer][0].cbh_type
      // debugger
      data = state.geo.almaty.filtered_widget_data.avg_receipt_amt
      let tempData = Object.entries(data)
      data = tempData.map(item => {
        return {
          item_data: item[0],
          item_value: item[1]
        }
      })
    }

  return {
    // ...props,
    data: data ? data : emptyData,
    start_data:  data ? data : emptyData,
    active_filter: state.geo.almaty.active_filters.data,
    dictionary: avg_receipts_amt,
    item_name: 'item_data',
    filter_name: 'data',
    widget_name: '',
    item_value: 'item_value',
    absolute_left: 50,
    multi: false
  }
})(MobileDoubleParentWidget)
export default DataReceiptsAmt


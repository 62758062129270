import React, {useEffect, useState} from 'react'
import {connect} from "dva";
import {Reg} from "./components/Reg/Reg";
import {regActions} from "../../models/regModel/regActions";
import Form from "antd/es/form";


const RegConnector = (props) => {
	const {regStatus} = props.reg
	const {dispatch} = props

	const onReg = (payload) => {
		dispatch(regActions.onReg(payload))
	}

	return (
		<Reg
			RegStatus={regStatus}
			onReg={onReg}
		/>
	)

}


function mapStateToProps(state) {
	return {
		reg: state.reg
	}
}


export const RegContainer = connect(mapStateToProps)(RegConnector)

import {connect} from "dva";
import {Layout as MainLayout, Menu} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import {Link, Route, Routes} from "react-router-dom"
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import {authActions} from "../models/authModel/authActions";
import {AdminPanelContainer} from "../Pages/AdminPanel/AdminPanelContainer";
import React, {useEffect, useState} from "react";
import {infoActions} from "../models/infoModel/infoActions";
import { useTranslation } from "react-i18next";
import locationContext from "../Tools/context";
import style from '../assets/style.css'
import logo from "./logotypes/frontier.png"
import saudata_logo from "./logotypes/Logo.svg"
import {
	UserInterfaceProductsContainer
} from "../Pages/UserInterface/UserInterfaceProducts/UserInterfaceProductsContainer";
import {
	UserInterfaceDashboardContainer
} from "../Pages/UserInterface/UserInterfaceDashboard/UserInterfaceDashboardContainer";
import {UserInterfaceMapContainer} from "../Pages/UserInterface/UserInterfaceMap/UserInterfaceMapContainer";
import {UserCabinetContainer} from "../Pages/UserInterface/UserInterfaceCabinet/UserCabinetContainer";
// import { Button } from 'antd';
import { Button } from 'antd/es/';

const {SubMenu} = Menu;
const LayoutContainer = (props) => {
	const {
		dispatch,
		currentUser
	} = props

	const [currentPath, setCurrentPath] = useState(window.location.pathname);

	const navigate = (path) => {
		setCurrentPath(path);
		window.location.pathname = path;
	};


	const { t, i18n } = useTranslation('ru');

	const signOut = () => {
		dispatch(authActions.onRevoke())
	}

	useEffect(() => {
		dispatch(infoActions.getcurrentUserFetch())
		dispatch(infoActions.getInfoFetch())
	}, [])

	// FIXME NEED INFO ?!
	// if (currentUser) {
	// 	if (currentUser.is_admin == true) {
	// 		dispatch(infoActions.getInfoFetch())
	// 	}
	// }


	return (
			<MainLayout style={{height: '100vh'}}>
				<Header style={{width: '100%'}} id='header'>
					<div className="container" style={{display: 'flex', justifyContent: 'space-between'}}>
						<Link className="logo" to={'/'}>
							{/*<img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={logo} alt=""/>*/}
						{/*	<span style={{fontSize: '24px', fontWeight: 'bold', color: '#3C64B1', marginRight: '5px', marginLeft: '0px'}}>
							FRONTIER |
						</span>
							товарная аналитика*/}
							<img style={{width: '120px', display: "block"}} src={saudata_logo} alt={' '}/>
						</Link>
						<Menu
							style={{width: 'auto', display: 'flex', marginLeft: '30px', height: '100%'}}
							defaultSelectedKeys={['1']}
							defaultOpenKeys={[]}
							forceSubMenuRender={true}
							subMenuCloseDelay={0.1}
							theme="dark"
							mode="horizontal"
						>

							<Menu.Item>
								<Link to={"/"}>Главная</Link>
							</Menu.Item>
							{currentUser?.is_admin &&
								<>
									<Menu.Item>
										<Link to={"/products"}>Client</Link>
									</Menu.Item>
									<Menu.Item>
										<Link to={"/admin"}>Admin</Link>
									</Menu.Item>
								</>
							}


							{/*<Menu.Item>*/}
							{/*	<Link to={"/cabinet"}>Личный кабинет</Link>*/}
							{/*</Menu.Item>*/}

							<Button danger ghost style={{marginLeft: '10px', marginTop: '8px', marginRight: '10px', borderRadius:'10px'}} onClick={signOut}>
								Выйти из аккаунта
							</Button>
							<Menu.Item  id='user_box' style={{color: "#000"}}>
								<SettingOutlined style={{color: "#000", fontSize: '24px', marginRight: '15px'}}/>
								<Link to={"/cabinet"} style={{color: '#000', display: 'flex', alignItems: 'center'}}>
									<>
										{currentUser?.name}
										<br/>
										{currentUser?.client_name}
									</>
								</Link>
							</Menu.Item>
						</Menu>
					</div>

				</Header>

				<Content style={{background: '#fff'}}>

					<MainLayout style={{background: '#fff'}}>
						<locationContext.Provider value={{t}}>
							<Routes>
								<Route path="/*" element={<UserInterfaceProductsContainer />} />
								{/*{FIXME route component of user cabinet here}*/}
								<Route path={'cabinet'} element={<UserCabinetContainer currentUser={currentUser} dispatch={dispatch}/>} />
								<Route path="dashboard/:productId" element={<UserInterfaceDashboardContainer />} />
								<Route path="map/:productId" element={<UserInterfaceMapContainer />} />

								{currentUser?.is_admin &&
									<Route path={'admin'} element={<AdminPanelContainer />}/>
								}
							</Routes>
						</locationContext.Provider>
					</MainLayout>

				</Content>
			</MainLayout>

	)

}

function mapStateToProps(state) {
	return {
		currentUser: state.info.data.currentUser
	}
}

export const Layout = connect(mapStateToProps)(LayoutContainer);

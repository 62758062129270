import React, {useEffect, useState} from 'react'
import Row from 'antd/es/row'
import Form from 'antd/es/form'
import notification from 'antd/es/notification'
import Input from 'antd/es/input'
import Button from 'antd/es/button'
import Card from 'antd/es/card'
import {ERROR, PENDING} from "../../../../models/globalStatuses";
import graph_1 from "../../../../assets/images/graph_1.png"
import graph_2 from "../../../../assets/images/graph_2.png"
import InputMask from 'react-input-mask';
import style from "./style.css"
import {Link} from "react-router-dom";
import logo from "../../../../Layout/logotypes/Logo.svg";
import background_image from "../../../../assets/images/auth_background.jpg";





const layout = {
	labelCol: {span: 8},
	wrapperCol: {span: 16},
};
const tailLayout = {
	wrapperCol: {offset: 11},
};

const leftStyle = {
	display: 'flex',
	width: '40%',
	height: '100%',
	flexDirection: 'column',
	backgroundColor: '#fff',
	paddingTop: '30px'
}

const container = {
	margin: '0 auto',
	// width: '1360px',
/*	backgroundColor: '#3C64B1'*/
	backgroundColor: 'rgb(255, 255, 255)'
}


export const Reg = ({regStatus, onReg}) => {


	const [checkPassword, setCheckPassword] = useState({
		password: '',
		confirm_password: '',
		check: false
	})


	const checkName = (_, value) => {
		if (checkPassword.password === checkPassword.confirm_password) {
			return Promise.resolve()
		} else {
			return Promise.reject(new Error('Пароли не совпадают'));
		}
	}




	const onFinish = ({username, name, email, phone, password, position, confirm_password, company}) => {
		// FIXME on prod to location.href
		let registration_token = location.href.split('?token=')[1]
		// registration_token = '"https:\\clavem.frontier.kz\\signup?token=202288svcl6mesz3g816140"'
		if (registration_token.includes('#/')) {
			registration_token = registration_token.split('#/')[0]
		}

		// FIXME on prod to location.href
		// let registration_token = '20228832qffjlaa5p316140'
		let payload = {registration_token, username, name, email, phone, password, position, company}
		console.log('payload', payload)
		payload.landing = false

		// debugger

		if (password == confirm_password) {
			onReg(payload)
		}
	};


	const changePassword = (value, type) => {
		setCheckPassword({
			...checkPassword,
			[type]: value
		})
	}

	return (
		<div style={{...container}} id="auth_reg">
			<Row align={'middle'} justify={'space-around'}  className="row" style={{alignItems: 'flex-start'}}>
				<div className="left">
					<div className="authBoxLeft" style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
					<div className="header" style={{fontSize: '14px', fontWeight: 'bold', color: '#3C64B1'}}>
						{/*<span style={{fontSize: '24px', fontWeight: 'bold', color: '#3C64B1', marginRight: '5px', marginLeft: '60px'}}>
							FRONTIER |
						</span>
						   товарная аналитика*/}
							<a href={'https://saudata.kz/'} target={'_blank'}> <img style={{marginLeft: '60px'}} src={logo} alt={'logo'} className={'header_logo'}/></a>
					</div>
					<Card className="regStyle">
						<Form
							{...layout}
							initialValues={{remember: true}}
							onFinish={onFinish}
						>
							<p className='form-title' >
								Регистрация
							</p>
							<div className="flex_box_form" style={{display: 'flex', flexWrap: 'wrap'}}>
								<Form.Item
									label="Имя пользователя"
									name="name"
									rules={[{required: true, message: 'Введите имя пользователя'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item
									label="Введите ФИО"
									name="username"
									rules={[{required: true, message: 'Введите ФИО'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item
									label="E-mail"
									name="email"
									rules={[{required: true, message: 'Введите E-mail'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item
									label="Введите номер телефона"
									name="phone"
									rules={[{required: true, message: 'Введите номер телефона'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<InputMask disabled={regStatus === PENDING}
											   mask='(+7) 999 999 9999'
									>
									</InputMask>
								</Form.Item>

								<Form.Item
									label="Придумайте пароль"
									name="password"
									rules={[{required: true, message: 'Введите пароль'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input.Password
										value={checkPassword.password}
										onChange={(e) => changePassword(e.target.value, 'password')}
										disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item
									label="Повторите пароль"
									name="confirm_password"
									rules={[
										{required: true, validator: checkName}
									]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input.Password disabled={regStatus === PENDING}
													value={checkPassword.confirm_password}
													onChange={(e) => changePassword(e.target.value, 'confirm_password')}
									/>
								</Form.Item>

								<Form.Item
									label="Компания"
									name="company"
									rules={[{required: true, message: 'Введите компанию'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item
									label="Введите должность"
									name="position"
									rules={[{required: true, message: 'Введите должность'}]}
									validateStatus={regStatus === ERROR && "error"}
								>
									<Input disabled={regStatus === PENDING}
									/>
								</Form.Item>

								<Form.Item {...tailLayout}  style={{width: '100%'}}>
									<Button type={regStatus === PENDING ? 'default' : 'primary'}
											htmlType="submit"
											disabled={regStatus === PENDING}
											loading={regStatus === PENDING}
											className='reg-button-submit'
									>
										Зарегистрироваться
									</Button>
								</Form.Item>
							</div>

						</Form>
						<div className="box" style={{
							marginTop: '20px',
						}}>
							<p style={{
								// fontSize: '14px',
								textAlign: 'center',
								color: '#545563',
								margin: '0',
							}}
							   // className={}
							>
								У меня уже есть аккаунт
							</p>

							<Link to={'/'}
								  style={{
									  textAlign: 'center',
									  display: 'block',
									  color: "#292974",
									  // fontSize: '14px',
									  fontWeight: '600'
								  }}
							>
								Вход
							</Link>
						</div>

					</Card>
						<div className="auth_bottom">
							{/*<p style={{*/}
							{/*	fontSize: '14px',*/}
							{/*	textAlign: 'center',*/}
							{/*	color: '#545563',*/}
							{/*	margin: '0',*/}
							{/*}}*/}
							{/*>*/}
							{/*	Хотите ознакомиться с продуктом поближе?*/}
							{/*</p>*/}
							{/*<Link to={'/signup?token=demo'}*/}
							{/*	  style={{*/}
							{/*		  textAlign: 'center',*/}
							{/*		  display: 'block',*/}
							{/*		  color: "#292974",*/}
							{/*		  fontSize: '14px',*/}
							{/*		  fontWeight: '600'*/}
							{/*	  }}*/}
							{/*>*/}
							{/*	Получить Демо доступ*/}
							{/*</Link>*/}
							<p style={{
								// position: 'fixed',
								// paddingTop: '20px',
								fontSize: '12px',
								color: '#545563',
								// marginLeft: '60px',
								// bottom: '2rem',
								marginTop: '10px',
								textAlign: 'center'
							}}
							>
								© TOO SAUDATA 2023
							</p>
						</div>
				</div>
				</div>
				<div className="right" style={{
					display: "flex",
					width: '50%',
					// height: '100vh',
					backgroundImage: `url(${background_image})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					alignItems: 'center'
				}}>
					<div className="flex_row">
						<div className="graph_2">
							<img src={graph_2} alt=""/>
						</div>
						<div className="graph_1">
							<img src={graph_1} alt=""/>
						</div>
					</div>

					{/*<div className="text">
						<div className="minititle">
							Узнайте больше о своем рынке
						</div>
						<div className="description">
							- Отслеживайте ценовые тренды рынка и ваших конкурентов
							<br/>
							- Отслеживайте тренды по объемам продаж, проводите оценку <br/> долей рынка
							<br/>
							- Проводите оценку своего потенциала в категории и <br/>популярность товара у потребителя
							<br/>
							- Совершенствуйте каналы продаж, оптимизируйте ассортимент <br/> и многое другое
						</div>
					</div>*/}



				</div>


			</Row>

		</div>
	)
};


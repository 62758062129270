import React, { Component } from 'react';
// import style from '../../../Ofd/OfdRightBar/Rightbar.css';
import style from '../Rightbar.css';
import Districts from './Districts';
import { connect } from 'dva';
import { Select, Switch } from 'antd';
// import SelectSwitchWidgets from "../../../Ofd/OfdWidgets/SelectSwitchWidgets/SelectSwitchWidgets";
import SelectSwitchWidgets from "../SelectSwitchWidgets/SelectSwitchWidgets";
// import { mapName, mobileMapModelStateName } from '../../../../../utils/map-variables';

@connect((state)=> {
    const map =  'mobile'
    return {
        active_filters: state.geo.almaty.active_filters.deficit_social,
        mode_DP: state.geo.almaty.mode_DP,
    }
})

class DistrictsWidgetWrapper extends Component {

    constructor(props) {
        super(props);
        this.state={
            value: 0,
        }
        const { Option } = Select;

    }




    handleChange = (value) => {
        this.setState({value: value},
        )
    }

    componentDidMount() {
        if(this.props.active_filters !== ''){
            this.setState(state => {
                return {
                    ...state,
                    value: 0
                }
            })
        } else {
            this.setState(state => {
                return {
                    ...state,
                    value: 1,
                }
            })
        }
    }

    render() {

        return <div
            style={{
                maxHeight: window.outerWidth <= 1366 ? '94%' : '100%',
                display: 'flex',
                flexDirection:' column',
                position: 'relative',
                marginBottom: '16px',
                overflow: 'auto',
                height: '50%',
                paddingTop: '20px'
            }}
        >

            <SelectSwitchWidgets
                wrapper_value={this.state.value}
                wrapper_title={'Районы'}
                handleChange={this.handleChange}
                options_type={4}
                badge={this.props.active_filters}
            />

            <div className={style.column_row_tabs_checks}>
                {this.state.value == 1 ? <Districts/> : ''}
            </div>

        </div>
    }
}


export default DistrictsWidgetWrapper;

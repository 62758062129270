// import {
//
// } from './utils/getGeoData';

import { mapApi } from "./mapApi"


import Moment from 'moment';
// import {extendMoment, DateRange} from 'moment-range';
// import { statisticFilter } from '../components/ComponentsByMaps/Ofd/OfdRightBar/Statistic/config_part';
// import { mobile_map_filters, ofd_map_filters } from './ofd_map_config';
// import { date_format } from '../utils/date_format';
import carto from '@carto/carto-vl';
// import turf from '@turf/boolean-intersects'
// import { mapName, mobileMapModelStateName } from '../utils/map-variables';

// const moment = extendMoment(Moment);

const numbersDict = {
	1: 'one',
	2: 'two',
	3: 'three',
	4: 'four',
	5: 'five',
	0: 'zero',
}

console.log('numbersDict', numbersDict)

// const preparedData = {
//   dates: {
//     from: '2021-02-10',
//     to: '2021-02-10'
//   },
// }
const preparedData = {
	dates: {
		from: '2021-05-06',
		to: '2021-05-06'
	},
	dots: [],
	hours: {
		from: 13,
		to: 13
	}
}

const preparedDataFullHoursCrimes = {
	dates: {
		from: '2021-05-06',
		to: '2021-05-06'
	},
	dots: [],
	hours: {
		from: 1,
		to: 24
	}
}
function delay(timeout){
	return new Promise(resolve => {
		setTimeout(resolve, timeout);
	});
}

// function parseDatesFields(data){
//  //fixme WAITING FOR SORTED DATES FROM LAST TO NEW, LAST INDEX == LAST AVAILABLE DATE
//  let fromDate = moment(Object.entries(data[0])[0][0]);
//  let toDate = moment(Object.entries(data[data.length - 1])[0][0]);
//  let range = new DateRange(fromDate, toDate);
//  let array = Array.from(range.by('days'))
//  const datesHash = Object.fromEntries(data.map(x=>[Object.entries(x)[0][0], {date: Object.entries(x)[0][0], value: Object.entries(x)[0][1]}]))
//  return array.map(item => {
//    if(datesHash[moment(item).format(date_format)]){
//      let item_object = datesHash[moment(item).format(date_format)]
//      return {
//        date: item_object.date,
//        value: item_object.value
//      }
//    } else {
//      return {
//        date: moment(item).format(date_format),
//        value: 0
//      }
//    }
//  })
// }

function expandTransferable(buf) {
	var str = String.fromCharCode.apply(null, new Uint16Array(buf));
	return JSON.parse(str);
}

function createTransferable(o) {
	var str = JSON.stringify(o);
	var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
	var bufView = new Uint16Array(buf);
	for (var i = 0, strLen = str.length; i < strLen; i++) {
		bufView[i] = str.charCodeAt(i);
	}
	return buf;
}

// let map_name = ''
// if(window.location.href.includes('mobile-almaty')){
//   console.log('MOBILE window.location.href - true:', window.location.href)
//   window.mobile_map_name = 'Almaty'
//   map_name = 'MobileAlmaty'
// } else if (window.location.href.includes('mobile-pavlodar')) {
//   window.mobile_map_name = 'MobilePavlodar'
//   map_name = 'Pavlodar'
// } else{
//   console.log('MOBILE window.location.href - false:', window.location.href)
//   window.mobile_map_name = 'not_mobile'
//   map_name = 'not_mobile'
// }
// else if(window.location.pathname === '/ofd-almaty-business-dots') {
//   map_name = 'BusinessDotsAlmaty'
// } else if(window.location.pathname === '/ofd-almaty') {
//   map_name = 'Almaty'
// } else if(window.location.pathname === '/') {
//   map_name = 'BusinessDotsAlmaty'
// }

const old_colors = [
	'rgba(178, 249, 216, 0.6)',
	'rgba(103, 219, 165, 0.6)',
	'rgba(66, 179, 126, 0.6)',
	'rgba(44, 130, 89, 0.6)',
	'rgba(255, 233, 53, 0.6)',
	'rgba(225, 206, 1,  0.6)',
	'rgba(247, 153, 56, 0.6)',
	'rgba(255, 129, 0, 0.6)',
	'rgba(165, 85, 0, 0.6)',
	'rgba(95, 57, 15, 0.6)',
	'rgba(241, 102, 95, 0.6)',
	'rgba(239, 61, 51, 0.6)',
	'rgba(218, 0, 255,  0.6)',
	'rgba(158, 21, 181, 0.6)',
	'rgba(97, 4, 113, 0.6)'
];
const new_colors = [
	'rgba(0, 102, 255, 0.5)',
	'rgba(0, 149, 255, 0.5)',
	'rgba(71, 178, 255, 0.5)',
	'rgba(94, 202, 239, 0.5)',
	'rgba(240, 216, 30, 0.5)',
	'rgba(255, 188, 0, 0.5)',
	'rgba(255, 137, 3, 0.5)',
	'rgba(255, 84, 0, 0.5)',
	'rgba(255, 43, 0, 0.5)',
	'rgba(255, 0, 0, 0.7)',
];

// const dp_colors = [
//   'rgba(255, 0, 0, 0.7)',
//   'rgba(255, 43, 0, 0.5)',
//   'rgba(255, 84, 0, 0.5)',
//   'rgba(255, 137, 3, 0.5)',
//   'rgba(255, 188, 0, 0.5)',
//   'rgba(240, 216, 30, 0.5)',
//   'rgba(84, 245, 97, 0.5)',
//   'rgba(9, 220, 27, 0.5)',
//   'rgba(0, 149, 255, 0.5)',
//   'rgba(4, 94, 229, 0.5)',
// ]

const dp_colors = [
	'rgba(4, 94, 229, 0.5)',
	'rgba(0, 149, 255, 0.5)',
	'rgba(9, 220, 27, 0.5)',
	'rgba(84, 245, 97, 0.5)',
	'rgba(9, 220, 27, 0.5)',
	'rgba(240, 216, 30, 0.5)',
	'rgba(255, 188, 0, 0.5)',
	'rgba(255, 137, 3, 0.5)',
	'rgba(255, 84, 0, 0.5)',
	'rgba(255, 43, 0, 0.5)',
	'rgba(255, 0, 0, 0.7)',
]

const old_backets_almaty = [
	25, 50, 100, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000
];
const old_gradient_almaty = [
	{
		min: 0,
		max: 25
	},
	{
		min: 25,
		max: 50
	},
	{
		min: 50,
		max: 100
	},
	{
		min: 100,
		max: 150
	},
	{
		min: 150,
		max: 200
	},
	{
		min: 200,
		max: 250
	},
	{
		min: 250,
		max: 300
	},
	{
		min: 300,
		max: 400

	},
	{
		min: 400,
		max: 500

	},
	{
		min: 500,
		max: 600

	},
	{
		min: 600,
		max: 700

	},
	{
		min: 700,
		max: 800

	},
	{
		min: 800,
		max: 900

	},
	{
		min: 900,
		max: 1000
	},
	{
		min: 1000,
		max: 'infinity'
	}
];
const new_backets_almaty = [
	50, 150, 200, 300, 400, 600, 700, 800, 1000
];
const new_gradient_almaty = [
	{
		min: 0,
		max: 50
	},
	{
		min: 50,
		max: 150
	},
	{
		min: 150,
		max: 200
	},
	{
		min: 200,
		max: 300
	},
	{
		min: 300,
		max: 400

	},
	{
		min: 400,
		max: 600

	},
	{
		min: 600,
		max: 700

	},
	{
		min: 700,
		max: 800

	},
	{
		min: 800,
		max: 1000
	},
	{
		min: 1000,
		max: 'infinity'
	}
];

const proficit_gradient_almaty = [
	{
		min: 0,
		max: 1
	},
	{
		min: 2,
		max: 3
	},
	{
		min: 3,
		max: 4
	},
	{
		min: 4,
		max: 5
	},
	{
		min: 5,
		max: 6

	},
	{
		min: 6,
		max: 7

	},
	{
		min: 7,
		max: 8

	},
	{
		min: 8,
		max: 9

	},
	{
		min: 9,
		max: 10
	}
];

const old_backets_population = [
	250, 500, 750, 1000, 1500, 2000, 2500, 3500, 4000, 4500, 5000, 5500, 6000, 7000
];
const new_backets_population = [
	500, 1000, 1500, 2500, 3500, 4500, 5000, 5500, 7000
];

const old_gradient_population = [
	{
		min: 0,
		max: 250
	},
	{
		min: 250,
		max: 500
	},
	{
		min: 500,
		max: 750
	},
	{
		min: 750,
		max: 1000
	},
	{
		min: 1000,
		max: 1500
	},
	{
		min: 1500,
		max: 2000
	},
	{
		min: 2000,
		max: 2500
	},
	{
		min: 2500,
		max: 3500
	},
	{
		min: 3500,
		max: 4000
	},
	{
		min: 4000,
		max: 4500
	},
	{
		min: 4500,
		max: 5000

	},
	{
		min: 5000,
		max: 5500
	},
	{
		min: 5500,
		max: 6000
	},
	{
		min: 6000,
		max: 7000
	},
	{
		min: 7000,
		max: 'infinity'
	}
];
const new_gradient_population = [
	{
		min: 0,
		max: 500
	},
	{
		min: 500,
		max: 1000
	},
	{
		min: 1000,
		max: 1500
	},
	{
		min: 1500,
		max: 2500
	},
	{
		min: 2500,
		max: 3500
	},
	{
		min: 3500,
		max: 4500
	},
	{
		min: 4500,
		max: 5000

	},
	{
		min: 5000,
		max: 5500
	},
	{
		min: 5500,
		max: 7000
	},
	{
		min: 7000,
		max: 'infinity'
	}
];

// export default {
export const geoModel = {

	namespace: 'geo',

	state: {
		almaty: {
			filtered_widget_data: {
				avg_receipt_amt: {
					cbh_and_competitors_avg_receipt_amt: {},
					cbh_and_no_competitors_avg_receipt_amt: {},
					no_cbh_but_competitors_avg_receipt_amt: {},
					others_only_avg_receipt_amt: {},
				},
				category_sales_amt: {
					cbh_and_competitors_category_sales_amt: {},
					cbh_and_no_competitors_category_sales_amt: {},
					no_cbh_but_competitors_category_sales_amt: {},
					others_only_category_sales_amt: {},
				},
				place_count: {
					cbh_and_competitors_place_count: {},
					cbh_and_no_competitors_place_count: {},
					no_cbh_but_competitors_place_count: {},
					others_only_place_count: {},
				},
				receipts_count: {
					cbh_and_competitors_receipts_count: {},
					cbh_and_no_competitors_receipts_count: {},
					no_cbh_but_competitors_receipts_count: {},
					others_only_receipts_count: {},
				}
			},
			filtered_zids_data: [],
			draw_mode: '',
			interactivity: {
				mobileZids: true,
				sergek: true,
				crimes_dtp: true,
			},
			draw_polygon: {
				features: [],
				type: 'FeatureCollection',
			},
			draw_point: {
				features: [],
				type: 'FeatureCollection',
			},
			rightBarLoader: false,
			crimes_dtp_active_layer: 'crimes',
			animation: {
				status: false,
				pause: false,
				layer_counter: 7,
			},
			hidden_layers: {
				covid_houses: true,
				sergek: false,
				crimes: false,
				road_violation: false,
				business_dots: false,
				zids: false,
				crimes_dtp: false
			},
			right_top_nav_active_tab: 0,
			zid_click_for_active_tab: 0,
			dot_info: [],
			flagForActiveTab: 0,
			SectorActiveSemiTab: 0,
			map_name: '',
			active_layer: 'receipts_count',
			// active_sublayer: 'others_only_receipts_count',
			active_sublayer: 'cbh_and_competitors_receipts_count',
			active_tab_layer: 'activity',
			filtred_zids: [],
			anomaly_zids: [],
			// active_filters: {
			//   region: {
			//     districts: []
			//   },
			//   city: {
			//     districts: []
			//   },
			//   selfiso: {
			//     districts: []
			//   },
			//   population: {
			//     districts: []
			//   },
			// },
			//FIXME Deficit or Proficit mode for switch
			mode_DP: 'deficit',
			DPCategory: 'dist_5',
			aside: false,
			isHistory: false,
			isHistoryError: false,
			historyFirstOpen: true,
			history_filters: {
				isHoursPending: false,
				isHistoryDatesPending: false,
				weekDays: [],
				dates: {
					activity: {
						available_dates: {
							from: '',
							to: '',
						},
						calendar_selected: {
							from: '',
							to: '',
						},
						current_date: {
							day: '',
							hour: '',
						},
					},
				},
				hours: {
					activity: [],
				},
			},
			history_zids: [],
			history_dates: {},
			active_filters: {
				removing_zids: [],
				draw_polygon: [],
				draw_point: [],
				is_anormal: false,
				is_anormal_region: false,
				types: [],
				sergek_direction: [],
				sergek_post: [],
				sergek_way: [],
				provinces_way: [],
				districts: [],
				data: 'cbh_and_competitors_receipts_count',
				// data: 'others_only_receipts_count',
				district_area: [],
				road_is_public: [],
				road_violation: [],
				road_violation_info: [],
				road_district: [],
				road_violation_types: [],
				crimes_hardcode: [],
				crimes_district: [],
				crimes_stat: [],
				current_zid: '',
				deficit_business: 'analizy',
				deficit_social: '',
				proficit_business: 'bary',
				proficit_social: '',
				// selectDateStartActivity: '',
				// selectDateEndActivity: '',
				selectDateStartCrimes: '',
				selectDateEndCrimes: '',
				dots_category: [],
				dot_object: '',
				dot_id: '',
				gradient: {
					min: '',
					max: ''
				}
			},
			calendar: {
				activity: {
					selectDateStart: '',
					selectDateEnd: '',
					current: ''
				},
				crimes: {
					selectDateStart: '',
					selectDateEnd: '',
					current: ''
				},
				dtp: {
					selectDateStart: '',
					selectDateEnd: '',
					current: ''
				},
			},
			start_data: [],
			filtred_data: {
				dates: {
					activity: {},
					crimes: {},
					road_violation: {},
					/*passanger_traffic: {},*/ //FIXME this module deprecated at 07.06.2021
				}
			},
			dates: {
				//fixme old api
				// activity: {
				//   hour: 13,
				//   last: '',
				//   current: ''
				// },
				// crimes: {
				//   hours: {
				//     from: 1,
				//     to: 24
				//   },
				//   last: '',
				//   current: {
				//     from: '',
				//     to: ''
				//   },
				// },
				// road_violation: {
				//   last: '',
				//   current: {
				//     from: '',
				//     to: ''
				//   }
				// },
				//fixme new api
				widget_data: {
					region: [],
					city: [],
					selfiso: [],
					crime: [],
					road: [],
				},
				activity: {
					available_dates: {
						from: '',
						to: ''
					},
					calendar_selected: {
						from: '',
						to: ''
					},
					current_date: {
						day: '',
						hour: ''
					},
				},
				crimes: {
					available_dates: {
						from: '',
						to: ''
					},
					calendar_selected: {
						from: '',
						to: ''
					},
					current_date: {
						day: {
							from: '',
							to: '',
						},
						hour: {
							from: '',
							to: ''
						}
					},
				},
				road_violation: {
					available_dates: {
						from: '',
						to: ''
					},
					calendar_selected: {
						from: '',
						to: ''
					},
					current_date: {
						day: {
							from: '',
							to: '',
						},
					},
				},

				/*passanger_traffic: { //FIXME this module deprecated at 07.06.2021
          hour: 13,
          last: '',
          current: ''
        }*/
			},
			zids: {
				region: [],
				city: [],
				selfiso: [],
				population: [],
			},
			layers: {
				region: {
					zids: [],
					dates: [],
					hours: [],
					districts: [],
					district_area: [],
					animation_data: {
						zids: [],
						districts: [],
						district_area: []
					}
				},
				city: {
					zids: [],
					dates: [],
					hours: [],
					districts: [],
					district_area: [],
					animation_data: {
						zids: [],
						districts: [],
						district_area: []
					}
				},
				population: {
					zids: [],
					dates: [],
					hours: [],
					districts: [],
					district_area: []
				},
				selfiso: {
					zids: [],
					dates: [],
					hours: [],
					districts: [],
					district_area: [],
					animation_data: {
						zids: [],
						districts: [],
						district_area: []
					}
				}
			},
			legends_types: {
				mobile: {
					almaty: {
						backets: new_backets_almaty,
						colors: new_colors,
						gradient: new_gradient_almaty,
					},
				}
			},
		}

	},


	subscriptions: {
		setup({ dispatch }) {
			// console.log('map_name at mobile model:', map_name)

			//FIXME проверить мобильная ли эта карта

			// if(mapName() == 'MobileAlmaty' && mapName() != 'MobilePavlodar'){
			//   console.log('Hey There! We are at mobile model!')
			//   //FIXME OLD API
			//   // dispatch({type: 'getMobileFetch', payload: {preparedData, preparedDataFullHoursCrimes}})
			//   //FIXME NEW API
			//   dispatch({type: 'getMobileFetchNew'})
			// } else if (mapName() == 'MobilePavlodar') {
			//   //FIXME OLD API
			//   // console.log('Hey There! We are at mobile model!')
			//   // dispatch({type: 'getMobilePavlodarFetch', payload: {preparedData, preparedDataFullHoursCrimes}})
			//   //FIXME NEW API
			//
			//   dispatch({type: 'getMobilePavlodarFetchNew'})
			// }
			// getOfdFetch(preparedData).then((response) => {
			//   console.log('subscriptions, then response.text - ', response.object.result)
			//   dispatch({type: 'saveStartData', payload: response.object.result})
			// })
		},
	},

	effects: {
		* fetch({ payload }, { put }) {  // eslint-disable-line
			yield put({ type: 'save' });
		},

		* setStoreData({ payload }, { put }) {

		},

		* getGeoFetch({ payload }, {
			call,
			put,
			select
		}) {


			yield put({ type: 'authorization/showLoader' });
			// payload = {
			//   date_from: '2022-02-13',
			//   date_to: '2022-02-13',
			//   hour_from: '13',
			//   hour_to: '13',
			// }
			let response;
			if (payload) {

				// response = yield call(getExportDataset, payload);
				response = yield call(() => mapApi.getExportDataset(payload[0], payload[1]))


				//FIXME ADD PAYLOAD TO CALL, <---- NOT FIRST CALL
				// if (window.location.pathname.includes('public')) {
				//  response = yield call(getMobileDataNewAlmatyPublic, payload); //start request
				// } else {
				//  response = yield call(getMobileDataNewAlmaty, payload); //start request
				// }
				// // response = yield call(getMobileDataNewAlmaty, payload)
			} else {
				//FIXME FIRST CALL
				// if (window.location.pathname.includes('public')) {
				//  response = yield call(getMobileDataNewAlmatyPublic); //start request
				// } else {
				//  response = yield call(getMobileDataNewAlmaty); //start request
				// }
			}
			// const apiResult = response.object.result;
			const apiResult = response;
			// let {
			//  city,
			//  region,
			//  selfiso,
			//  crime,
			//  roads
			// } = apiResult.global_dates;
			// let parsed_roads = roads;
			// let road_dates_without_zero = parsed_roads.filter(item => item.value != 0);
			// road_dates_without_zero = road_dates_without_zero[road_dates_without_zero.length - 1].date;
			// const dates = {
			//  activity_current_date: apiResult.activity_current_date,
			//  crime_current_date: apiResult.crime.crimes_current_date,
			//  road_current_date: apiResult.road.road_violation_current_date,
			//  //FIXME GETTING FROM BACK LAST DATE AND IT WITH ZERO GeoJSON, SO USE - road_dates_without_zero <-- calculating last date with lengh more then 0
			//  // road_current_date: apiResult.road.road_violation_current_date,
			//  region_last_date: Object.entries(region[region.length - 1])[0][0],
			//  region_first_date: Object.entries(region[0])[0][0],
			//  selfiso_last_date: Object.entries(selfiso[selfiso.length - 1])[0][0],
			//  city_last_date: Object.entries(city[city.length - 1])[0][0],
			//  crimes_last_date: Object.entries(crime[crime.length - 1])[0][0],
			//  crimes_first_date: Object.entries(crime[0])[0][0],
			//  road_first_date: Object.entries(roads[0])[0][0],
			//  road_last_date: Object.entries(roads[roads.length - 1])[0][0],
			//  road_dates_without_zero: road_dates_without_zero
			//
			//  //FIXME this module deprecated at 07.06.2021
			//  //almaty_routes_last_date: Object.entries(allDates.almaty_routes_global_dates[allDates.almaty_routes_global_dates.length - 1])[0][0],
			// };
			// let allDates = {
			//  global_dates_crime: crime,
			//  global_dates_city: city,
			//  global_dates_region: region,
			//  global_dates_selfiso: selfiso,
			//  roads: parsed_roads
			//
			// };
			//FIXME RENAME ALL DATES OBJECT
			// yield put({type: 'setLastAndCurrentDates', payload: dates})
			yield put({
				type: 'putDataAfterFirstPageLoad',
				payload: {
					apiResult: apiResult,
					// allDates: allDates,
					// dates: dates
				}
			});
			// yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},



















		// *getOfdDates({ payload }, { call, put }) {
		//   const getOfdDatesByMap = {
		//     Nursultan: getOfdDatesNursultan,
		//     BusinessDotsAlmaty: getOfdDatesBusinessDotsAlmaty,
		//     Almaty: getOfdDatesBusinessDotsAlmaty
		//   }
		//   yield put({type: 'showLoader'})
		//   const response = yield call(getOfdDatesByMap[map_name], payload)
		//   yield put({type: 'setParsedGetDates', payload: response.object.result})
		//   yield put({type: 'hideLoader'})
		// },
		//
		// *getOfdZid({ payload }, { call, put }) {
		//   const getOfdZidByMap = {
		//     Nursultan: getOfdZidNursultan,
		//     BusinessDotsAlmaty: getOfdZidBusinessDotsAlmaty,
		//     Almaty: getOfdZidBusinessDotsAlmaty
		//   }
		//   const response = yield call(getOfdZidByMap[map_name], payload)
		//   yield put({type: 'setZidDots', payload})
		//   yield put({type: 'setZidData', payload: {
		//       response: response.object.result,
		//       payload: payload
		//     }
		//   })
		// },

		* updateCrimeDate({ payload }, {
			call,
			put,
			select
		}) {
			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			//fixme new api
			const state = yield select(state => state);
			let payloadNewData = {};
			yield put({ type: 'authorization/showLoader' });
			if (payload.dates) {
				payloadNewData = {
					// dates: payload.dates,
					dates_from: payload.dates.from,
					dates_to: payload.dates.to,
					hours_from: Number(state.mobile.almaty.dates.crimes.current_date.hour.from),
					hours_to: Number(state.mobile.almaty.dates.crimes.current_date.hour.to),
				};
			} else if (payload.hours) {
				payloadNewData = {
					// dates: payload.dates,
					dates_from: state.mobile.almaty.dates.crimes.current_date.day.from,
					dates_to: state.mobile.almaty.dates.crimes.current_date.day.to,
					hours_from: Number(payload.hours.from),
					hours_to: Number(payload.hours.to)
				};
			}
			if (mapName() == 'MobileAlmaty') {
				//ANNOTATION CALLING ALMATY API
				yield put({
					type: 'getMobileFetchNewCrime',
					payload: payloadNewData
				});
			} else {
				//ANNOTATION CALLING PAVLODAR API
				yield put({
					type: 'getMobileFetchNewCrimePavlodar',
					payload: payloadNewData
				});
			}
			//fixme old api
			// const state = yield select(state => state);
			// let payloadNewData = {}
			// yield put({type: 'showLoader'})
			// if(payload.dates){
			//   let fromDate = moment(payload.dates.from);
			//   let toDate = moment(payload.dates.to);
			//   let range = new DateRange(fromDate, toDate);
			//   let array = Array.from(range.by('days'))
			//   payloadNewData = {
			//     // dates: payload.dates,
			//     dates: {
			//       from: payload.dates.from,
			//       to: array.length > 2 ? payload.dates.to : payload.dates.from
			//     },
			//     hours: {
			//       from: state.mobile.dates.crimes.hour,
			//       to: state.mobile.dates.crimes.hour
			//     }
			//   }
			// }else if(payload.hours){
			//   payloadNewData = {
			//     dates: state.mobile.dates.crimes.current,
			//     hours: {
			//       from: payload.hours.from,
			//       to: payload.hours.to - payload.hours.from > 1 ? payload.hours.to : payload.hours.from
			//     }
			//     // hours: payload.hours
			//   }
			// }
			//
			// let crime
			// if(state.mobile.map_name == 'Almaty') {
			//   crime = yield call(getMobileAlmatyCrime, payloadNewData)
			//   crime = crime.object.result
			// } else {
			//   crime = yield call(getMobilePavlodarCrime, payloadNewData)
			//   crime = crime.object.result
			// }
			// yield put({
			//   type: 'updateStartData',
			//   payload: {
			//     payload: {
			//       crime
			//     },
			//     current: payload.dates ? payload.dates : state.mobile.dates.crimes.current,
			//     hours: payload.hours ? payload.hours : state.mobile.dates.crimes.hours
			//   }
			// })
			// // yield put({type: 'callMobileZidsWorker'})
			// yield put({type: 'hideLoader'})
		},

		* updateRoadDate({ payload }, {
			call,
			put,
			select
		}) {
			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			//fixme new api
			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			const state = yield select(state => state);
			let payloadNewData = {};
			yield put({ type: 'authorization/showLoader' });
			if (payload.dates) {
				payloadNewData = {
					// dates: payload.dates,
					dates_from: payload.dates.from,
					dates_to: payload.dates.to
				};
			}
			if (mapName() == 'MobileAlmaty') {
				//ANNOTATION CALLING ALMATY API

				yield put({
					type: 'getMobileFetchNewRoad',
					payload: payloadNewData
				});
			} else {
				//ANNOTATION CALLING PAVLODAR API
				yield put({
					type: 'getMobileFetchNewRoadPavlodar',
					payload: payloadNewData
				});
			}

			//fixme old api
			// const state = yield select(state => state);
			// yield put({type: 'showLoader'})
			// let fromDate = moment(payload.dates.from);
			// let toDate = moment(payload.dates.to);
			// let range = new DateRange(fromDate, toDate);
			// let array = Array.from(range.by('days'))
			// let payloadNewData = {
			//   dates: {
			//     from: payload.dates.from,
			//     to: array.length > 2 ? payload.dates.to : payload.dates.from
			//   }
			// }
			// let road
			// if(state.mobile.map_name == 'Almaty') {
			//   road = yield call(getMobileAlmatyRoad, payloadNewData)
			//   road = road.object.result
			// } else if(state.mobile.map_name == 'Pavlodar') {
			//   road = yield call(getMobilePavlodarRoad, payloadNewData)
			//   road = road.object.result
			// }
			// yield put({
			//   type: 'updateStartData',
			//   payload: {
			//     payload: {
			//       road
			//     },
			//     current: payload.dates ? payload.dates : state.mobile.dates.road_violation.current,
			//   }
			// })
			// yield put({type: 'hideLoader'})
		},

		* updateActivityDate({ payload }, {
			call,
			put,
			select
		}) {
			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			const state = yield select(state => state);
			let payloadNewData = {};
			yield put({ type: 'authorization/showLoader' });
			if (payload.dates) {
				payloadNewData = {
					// dates: payload.dates,
					dates_from: payload.dates.from,
					hours_from: state.mobile.almaty.dates.activity.current_date.hour,
				};
			} else if (payload.hour) {
				payloadNewData = {
					dates_from: state.mobile.almaty.dates.activity.current_date.day,
					// hours_from: payload.hours.from,
					hours_from: payload.hour.selected_start,
					// hours: payload.hours
				};
			}

			let response;
			//fixme new api
			if (mapName() == 'MobileAlmaty') {
				//ANNOTATION CALLING ALMATY API

				yield put({
					type: 'getMobileFetchNewActivity',
					payload: payloadNewData
				});
			} else if (mapName() == 'MobilePavlodar') {
				yield put({
					type: 'getMobileFetchNewActivityPavlodar',
					payload: payloadNewData
				});
			}
		},

		//fixme old api
		// *updateActivityDate({ payload }, { call, put, select }) {
		//   // const getOfdFetchByMap = {
		//   //   Nursultan: getOfdFetchNursultan,
		//   //   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
		//   //   Almaty: getOfdFetchBusinessDotsAlmaty
		//   // }
		//
		//   const state = yield select(state => state);
		//   let payloadNewData = {}
		//   yield put({type: 'showLoader'})
		//   if(payload.dates){
		//     payloadNewData = {
		//       // dates: payload.dates,
		//       dates_from: payload.dates.from,
		//       hours_from: state.mobile.dates.activity.hour,
		//     }
		//   }else if(payload.hours){
		//     payloadNewData = {
		//       dates_from: state.mobile.dates.activity.current,
		//       hours_from: payload.hours.from,
		//       // hours: payload.hours
		//     }
		//   }
		//   let response
		//   //fixme new api
		//   if(state.mobile.map_name == 'Almaty') {
		//     //ANNOTATION CALLING ALMATY API
		//     yield put({type: 'getMobileFetchNew', payload: payloadNewData})
		//   } else {
		//     //ANNOTATION CALLING PAVLODAR API
		//   }

		//fixme old API
		// let selfiso = ''
		// let city = ''
		// let region = ''
		// let dynamicData = ''
		// let zidsRegion = ''
		// let zidsSelfiso = ''
		// let covidStat = ''
		// let provinces = ''
		// let sergek = ''
		// let types = ''
		// let covidHouses = ''
		// if(state.mobile.map_name == 'Almaty') {
		//   selfiso = yield call(getMobileAlmatySelfiso, payloadNewData)
		//   selfiso = selfiso.object.result
		//
		//   city = yield call(getMobileAlmatyCity, payloadNewData)
		//   city = city.object.result
		//
		//   region = yield call(getMobileAlmatyRegion, payloadNewData)
		//   region = region.object.result
		//
		//   dynamicData = yield call(getMobileAlmatyDynamicData, payloadNewData)
		//   dynamicData = dynamicData.object.result
		//
		//   zidsRegion = yield call(getMobileAlmatyZidsRegion, payloadNewData)
		//   zidsRegion = zidsRegion.object.result
		//
		//   zidsSelfiso = yield call(getMobileAlmatyZidsSelfiso, payloadNewData)
		//   zidsSelfiso = zidsSelfiso.object.result
		//
		//   covidStat = yield call(getMobileAlmatyCovidStat, payloadNewData)
		//   covidStat = covidStat.object.result
		//
		//   provinces = yield call(getMobileAlmatyProvinces, payloadNewData)
		//   provinces = provinces.object.result
		//
		//   /*let regionRoutes = yield call(getMobileAlmatyRegionRoutes, payloadNewData) //FIXME this module deprecated at 07.06.2021
		//   regionRoutes = regionRoutes.object.result*/
		//
		//   sergek = yield call(getMobileAlmatySergek, payloadNewData)
		//   sergek = sergek.object.result
		//
		//   types = yield call(getMobileAlmatyTypes, payloadNewData)
		//   types = types.object.result
		//   covidHouses = yield call(getMobileAlmatyCovidHouses, payloadNewData)
		//   covidHouses = covidHouses.object.result
		// } else {
		//   selfiso = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   selfiso = {
		//     district_area: selfiso.object.result.city_selfiso.district_area,
		//     districts: selfiso.object.result.city_selfiso.districts,
		//     hours: selfiso.object.result.city_selfiso.hours
		//   }
		//
		//   // city = yield call(getMobileAlmatyCity, payloadNewData)
		//   // city = city.object.result
		//
		//   // region = yield call(getMobileAlmatyRegion, payloadNewData)
		//   // region = region.object.result
		//
		//   // dynamicData = yield call(getMobileAlmatyDynamicData, payloadNewData)
		//   // dynamicData = dynamicData.object.result
		//   //
		//   // zidsRegion = yield call(getMobileAlmatyZidsRegion, payloadNewData)
		//   // zidsRegion = zidsRegion.object.result
		//
		//   // zidsSelfiso = yield call(getMobileAlmatyZidsSelfiso, payloadNewData)
		//   // zidsSelfiso = zidsSelfiso.object.result
		//
		//   // covidStat = yield call(getMobileAlmatyCovidStat, payloadNewData)
		//   // covidStat = covidStat.object.result
		//
		//   // provinces = yield call(getMobileAlmatyProvinces, payloadNewData)
		//   // provinces = provinces.object.result
		//
		//   /*let regionRoutes = yield call(getMobileAlmatyRegionRoutes, payloadNewData) //FIXME this module deprecated at 07.06.2021
		//   regionRoutes = regionRoutes.object.result*/
		//
		//   sergek = yield call(getMobileAlmatySergek, payloadNewData)
		//   sergek = sergek.object.result
		//
		//   types = yield call(getMobileAlmatyTypes, payloadNewData)
		//   types = types.object.result
		//   covidHouses = yield call(getMobileAlmatyCovidHouses, payloadNewData)
		//   covidHouses = covidHouses.object.result
		//
		//   city = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   city = {
		//     district_area: city.object.result.city_activity.district_area,
		//     districts: city.object.result.city_activity.districts,
		//     hours: city.object.result.city_activity.hours
		//   }
		//
		//   region = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   region = {
		//     district_area_region: region.object.result.region_activity.district_area,
		//     districts_region: region.object.result.region_activity.districts,
		//     hours_region: region.object.result.region_activity.hours
		//   }
		//
		//   dynamicData = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   dynamicData = {
		//     zids_city: dynamicData.object.result.city_activity.zids
		//   }
		//
		//   zidsRegion = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   zidsRegion = {
		//     zids: zidsRegion.object.result.region_activity.zids
		//   }
		//
		//   zidsSelfiso = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   zidsSelfiso = {
		//     zids: zidsSelfiso.object.result.city_selfiso.zids
		//   }
		//
		//   covidStat = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   covidStat = {
		//     covid_stat: covidStat.object.result.static.covid_stat
		//   }
		//
		//   provinces = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   provinces = {
		//     provinces: provinces.object.result.region_activity.provinces,
		//     provinces_ways: provinces.object.result.region_activity.provinces_ways
		//   }
		//
		//   types = yield call(getMobilePavlodarAllDates, payloadNewData)
		//   types = {
		//     types: types.object.result.static.types
		//   }
		// }
		//
		// yield put({
		//   type: 'updateStartData',
		//   payload: {
		//     payload: {
		//       selfiso,
		//       city,
		//       region,
		//       dynamicData,
		//       zidsRegion,
		//       zidsSelfiso,
		//       covidStat,
		//       sergek,
		//       provinces,
		//       types,
		//       // covidHouses
		//     },
		//     current: payload.dates ? payload.dates.from : state.mobile.dates.activity.current,
		//     hour: payload.hours ? payload.hours.from : state.mobile.dates.activity.hour
		//   }
		// })
		// yield put({type: 'callMobileZidsWorker'})
		// yield put({type: 'hideLoader'})
		// },

		* getMobilePavlodarFetch({ payload }, {
			call,
			put,
			select
		}) {

			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			yield put({ type: 'authorization/showLoader' });

			let allDates = yield call(getMobilePavlodarAllDates, payload.preparedData); //start request
			allDates = allDates.object.result;
			const dates = {
				current_date: Object.entries(allDates.static.global_dates_region[allDates.static.global_dates_region.length - 1])[0][0],
				region_last_date: Object.entries(allDates.static.global_dates_region[allDates.static.global_dates_region.length - 1])[0][0],
				selfiso_last_date: Object.entries(allDates.static.global_dates_selfiso[allDates.static.global_dates_selfiso.length - 1])[0][0],
				city_last_date: Object.entries(allDates.static.global_dates_city[allDates.static.global_dates_city.length - 1])[0][0],
				crimes_last_date: Object.entries(allDates.static.crime_global_dates[allDates.static.crime_global_dates.length - 1])[0][0],
				road_last_date: Object.entries(allDates.static.road_global_dates[allDates.static.road_global_dates.length - 1])[0][0],
				//FIXME this module deprecated at 07.06.2021
				//almaty_routes_last_date: Object.entries(allDates.almaty_routes_global_dates[allDates.almaty_routes_global_dates.length - 1])[0][0],
			};

			console.log('dates', dates);

			allDates = {
				crime_global_dates: allDates.static.crime_global_dates,
				global_dates_city: allDates.static.global_dates_city,
				global_dates_region: allDates.static.global_dates_region,
				global_dates_selfiso: allDates.static.global_dates_selfiso,
				road_global_dates: allDates.static.road_global_dates,
			};

			yield put({
				type: 'setLastAndCurrentDates',
				payload: dates
			});
			const state = yield select(state => state);
			const payloadLastDateActivity = {
				...payload.preparedData,
				dates: {
					from: state.mobile.almaty.dates.activity.last,
					to: state.mobile.almaty.dates.activity.last
				}
			};
			let population = yield call(getMobileAlmatyPopulation, payloadLastDateActivity); //start request
			population = population.object.result;

			const payloadSelfiso = {
				...payload.preparedData,
				dates: {
					from: dates.selfiso_last_date,
					to: dates.selfiso_last_date
				}
			};
			let selfiso = yield call(getMobilePavlodarAllDates, payloadSelfiso);
			selfiso = {
				district_area: selfiso.object.result.city_selfiso.district_area,
				districts: selfiso.object.result.city_selfiso.districts,
				hours: selfiso.object.result.city_selfiso.hours
			};

			const payloadCity = {
				...payload.preparedData,
				dates: {
					from: dates.city_last_date,
					to: dates.city_last_date
				}
			};
			let city = yield call(getMobilePavlodarAllDates, payloadCity);
			city = {
				district_area: city.object.result.city_activity.district_area,
				districts: city.object.result.city_activity.districts,
				hours: city.object.result.city_activity.hours
			};

			const payloadRegion = {
				...payload.preparedData,
				dates: {
					from: dates.region_last_date,
					to: dates.region_last_date
				}
			};
			let region = yield call(getMobilePavlodarAllDates, payloadRegion);
			region = {
				district_area_region: region.object.result.region_activity.district_area,
				districts_region: region.object.result.region_activity.districts,
				hours_region: region.object.result.region_activity.hours
			};
			let dynamicData = yield call(getMobilePavlodarAllDates, payloadCity);
			dynamicData = {
				zids_city: dynamicData.object.result.city_activity.zids
			};

			let zidsRegion = yield call(getMobilePavlodarAllDates, payloadRegion);
			zidsRegion = {
				zids: zidsRegion.object.result.region_activity.zids
			};

			let zidsSelfiso = yield call(getMobilePavlodarAllDates, payloadSelfiso);
			zidsSelfiso = {
				zids: zidsSelfiso.object.result.city_selfiso.zids
			};

			let dots = yield call(getMobilePavlodarAllDates, payloadLastDateActivity); //start request
			dots = {
				dots: dots.object.result.static.dots
			};
			let covidStat = yield call(getMobilePavlodarAllDates, payloadLastDateActivity);
			covidStat = {
				covid_stat: covidStat.object.result.static.covid_stat
			};

			let provinces = yield call(getMobilePavlodarAllDates, payloadLastDateActivity);
			provinces = {
				provinces: provinces.object.result.region_activity.provinces,
				provinces_ways: provinces.object.result.region_activity.provinces_ways
			};
			/*let regionRoutes = yield call(getMobileAlmatyRegionRoutes, payloadLastDateActivity) //FIXME this module deprecated at 07.06.2021
      regionRoutes = regionRoutes.object.result*/

			let sergek = yield call(getMobileAlmatySergek, payloadLastDateActivity);
			sergek = sergek.object.result;

			const payloadCrime = {
				...payload.preparedData,
				dates: {
					from: dates.crimes_last_date,
					to: dates.crimes_last_date
				},
				hours: {
					from: 1,
					to: 24
				}
			};
			let crime = yield call(getMobilePavlodarCrime, payloadCrime);
			crime = crime.object.result;

			const payloadRoadViolation = {
				...payload.preparedData,
				dates: {
					from: dates.road_last_date,
					to: dates.road_last_date
				}
				// dates: {
				//   from: dates.road_last_date,
				//   to: dates.road_last_date
				// }
			};
			let road = yield call(getMobilePavlodarRoad, payloadRoadViolation);
			road = road.object.result;

			//FIXME this module deprecated at 07.06.2021
			/*const payloadCityRoutes = {
        ...payload.preparedData,
        dates: {
          from: dates.almaty_routes_last_date,
          to: dates.almaty_routes_last_date
        }
      }
      let cityRoutes = yield call(getMobileAlmatyCityRoutes, payloadCityRoutes)
      cityRoutes = cityRoutes.object.result*/













			let types = yield call(getMobilePavlodarAllDates, payloadLastDateActivity);
			types = {
				types: types.object.result.static.types
			};

			// let covidHouses = yield call(getMobileAlmatyCovidHouses, payloadLastDateActivity)
			// covidHouses = covidHouses.object.result
			yield put({
				type: 'putStartData',
				payload: {
					allDates,
					population,
					selfiso,
					city,
					region,
					dynamicData,
					zidsRegion,
					zidsSelfiso,
					dots,
					covidStat,
					provinces,
					/*regionRoutes,*/ //FIXME this module deprecated at 07.06.2021
					sergek,
					crime,
					road,
					/*cityRoutes,*/ //FIXME this module deprecated at 07.06.2021
					types,
					// covidHouses
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'callAnimationAlmaty' });
			yield put({ type: 'authorization/hideLoader' });
			// yield put({type: 'saveStartData', payload: response.object.result, dates: payload})
			// yield put({type: 'setParsedGetDates', payload: response.object.result})
			// yield put({type: 'nonfiltereddots/saveNonFilteredData', payload: response.object.result})
			// yield put({type: 'callZidsDotsWorkers'})
		},

		* getMobileFetchNewActivity({ payload }, {
			call,
			put,
			select
		}) {
			let response = '';
			if (window.location.pathname.includes('public')) {
				response = yield call(getMobileDataNewAlmatyActivityPublic, payload); //start request
			} else {
				response = yield call(getMobileDataNewAlmatyActivity, payload); //start request
			}
			// let response = yield call(getMobileDataNewAlmatyActivity, payload)
			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewActivity',
				payload: {
					apiResult,
					city: 'almaty'
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNewActivityPavlodar({ payload }, {
			call,
			put,
			select
		}) {
			let response = yield call(getMobileDataNewPavlodarActivity, payload);
			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewActivity',
				payload: {
					apiResult,
					city: 'pavlodar'
				}
			});

			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNewRoad({ payload }, {
			call,
			put,
			select
		}) {
			let response = '';
			if (window.location.pathname.includes('public')) {
				response = yield call(getMobileFetchNewAlmatyRoadPublic, payload); //start request
			} else {
				response = yield call(getMobileFetchNewAlmatyRoad, payload); //start request
			}
			// let response = yield call(getMobileFetchNewAlmatyRoad, payload)
			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewRoad',
				payload: {
					apiResult
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNewRoadPavlodar({ payload }, {
			call,
			put,
			select
		}) {

			let response = yield call(getMobileFetchNewPavlodarRoad, payload);

			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewRoad',
				payload: {
					apiResult
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNewCrime({ payload }, {
			call,
			put,
			select
		}) {
			let response = '';
			if (window.location.pathname.includes('public')) {
				response = yield call(getMobileFetchNewAlmatyCrimePublic, payload); //start request
			} else {
				response = yield call(getMobileFetchNewAlmatyCrime, payload); //start request
			}
			// let response = yield call(getMobileFetchNewAlmatyCrime, payload)
			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewCrime',
				payload: {
					apiResult
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNewCrimePavlodar({ payload }, {
			call,
			put,
			select
		}) {
			let response = yield call(getMobileFetchNewPavlodarCrime, payload);
			const apiResult = response.object.result;
			yield put({
				type: 'putDataAfterNewCrime',
				payload: {
					apiResult
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetchNew({ payload }, {
			call,
			put,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			// payload = {
			//   date_from: '2022-02-13',
			//   date_to: '2022-02-13',
			//   hour_from: '13',
			//   hour_to: '13',
			// }
			let response;
			if (payload) {
				//FIXME ADD PAYLOAD TO CALL, <---- NOT FIRST CALL
				if (window.location.pathname.includes('public')) {
					response = yield call(getMobileDataNewAlmatyPublic, payload); //start request
				} else {
					response = yield call(getMobileDataNewAlmaty, payload); //start request
				}
				// response = yield call(getMobileDataNewAlmaty, payload)
			} else {
				//FIXME FIRST CALL
				if (window.location.pathname.includes('public')) {
					response = yield call(getMobileDataNewAlmatyPublic); //start request
				} else {
					response = yield call(getMobileDataNewAlmaty); //start request
				}
			}
			const apiResult = response.object.result;
			let {
				city,
				region,
				selfiso,
				crime,
				roads
			} = apiResult.global_dates;
			let parsed_roads = roads;
			let road_dates_without_zero = parsed_roads.filter(item => item.value != 0);
			road_dates_without_zero = road_dates_without_zero[road_dates_without_zero.length - 1].date;
			const dates = {
				activity_current_date: apiResult.activity_current_date,
				crime_current_date: apiResult.crime.crimes_current_date,
				road_current_date: apiResult.road.road_violation_current_date,
				//FIXME GETTING FROM BACK LAST DATE AND IT WITH ZERO GeoJSON, SO USE - road_dates_without_zero <-- calculating last date with lengh more then 0
				// road_current_date: apiResult.road.road_violation_current_date,
				region_last_date: Object.entries(region[region.length - 1])[0][0],
				region_first_date: Object.entries(region[0])[0][0],
				selfiso_last_date: Object.entries(selfiso[selfiso.length - 1])[0][0],
				city_last_date: Object.entries(city[city.length - 1])[0][0],
				crimes_last_date: Object.entries(crime[crime.length - 1])[0][0],
				crimes_first_date: Object.entries(crime[0])[0][0],
				road_first_date: Object.entries(roads[0])[0][0],
				road_last_date: Object.entries(roads[roads.length - 1])[0][0],
				road_dates_without_zero: road_dates_without_zero

				//FIXME this module deprecated at 07.06.2021
				//almaty_routes_last_date: Object.entries(allDates.almaty_routes_global_dates[allDates.almaty_routes_global_dates.length - 1])[0][0],
			};
			let allDates = {
				global_dates_crime: crime,
				global_dates_city: city,
				global_dates_region: region,
				global_dates_selfiso: selfiso,
				roads: parsed_roads

			};
			//FIXME RENAME ALL DATES OBJECT
			// yield put({type: 'setLastAndCurrentDates', payload: dates})
			yield put({
				type: 'putDataAfterFirstPageLoad',
				payload: {
					apiResult: response.object.result,
					allDates: allDates,
					dates: dates
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobilePavlodarFetchNew({ payload }, {
			call,
			put,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			// payload = {
			//   date_from: '2022-02-13',
			//   date_to: '2022-02-13',
			//   hour_from: '13',
			//   hour_to: '13',
			// }
			let response;
			if (payload) {
				//FIXME ADD PAYLOAD TO CALL, <---- NOT FIRST CALL
				response = yield call(getMobileDataNewPavlodar, payload);
			} else {
				//FIXME FIRST CALL
				response = yield call(getMobileDataNewPavlodar);
			}

			const apiResult = response.object.result;

			let {
				city,
				region,
				selfiso,
				crime,
				roads
			} = apiResult.global_dates;
			let parsed_roads = roads;
			let road_dates_without_zero = parsed_roads.filter(item => item.value != 0);
			road_dates_without_zero = road_dates_without_zero[road_dates_without_zero.length - 1].date;
			const dates = {

				activity_current_date: apiResult.activity_current_date,
				crime_current_date: apiResult.crime.crimes_current_date,
				road_current_date: apiResult.road.road_violation_current_date,
				//FIXME GETTING FROM BACK LAST DATE AND IT WITH ZERO GeoJSON, SO USE - road_dates_without_zero <-- calculating last date with lengh more then 0
				// road_current_date: apiResult.road.road_violation_current_date,
				region_last_date: Object.entries(region[region.length - 1])[0][0],
				region_first_date: Object.entries(region[0])[0][0],
				selfiso_last_date: Object.entries(selfiso[selfiso.length - 1])[0][0],
				city_last_date: Object.entries(city[city.length - 1])[0][0],
				crimes_last_date: Object.entries(crime[crime.length - 1])[0][0],
				crimes_first_date: Object.entries(crime[0])[0][0],
				road_first_date: Object.entries(roads[0])[0][0],
				road_last_date: Object.entries(roads[roads.length - 1])[0][0],
				road_dates_without_zero: road_dates_without_zero

				//FIXME this module deprecated at 07.06.2021
				//almaty_routes_last_date: Object.entries(allDates.almaty_routes_global_dates[allDates.almaty_routes_global_dates.length - 1])[0][0],
			};
			let allDates = {
				global_dates_crime: crime,
				global_dates_city: city,
				global_dates_region: region,
				global_dates_selfiso: selfiso,
				roads: parsed_roads

			};
			//FIXME RENAME ALL DATES OBJECT
			// yield put({type: 'setLastAndCurrentDates', payload: dates})
			yield put({
				type: 'putDataAfterFirstPageLoad',
				payload: {
					apiResult: response.object.result,
					allDates: allDates,
					dates: dates
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* getMobileFetch({ payload }, {
			call,
			put,
			select
		}) {
			// const getOfdFetchByMap = {
			//   Nursultan: getOfdFetchNursultan,
			//   BusinessDotsAlmaty: getOfdFetchBusinessDotsAlmaty,
			//   Almaty: getOfdFetchBusinessDotsAlmaty
			// }
			yield put({ type: 'authorization/showLoader' });
			let allDates = yield call(getMobileAlmatyAllDates, payload.preparedData);
			allDates = allDates.object.result;
			let road_global_dates = allDates.road_global_dates;
			let road_dates_without_zero = road_global_dates.filter(item => item.value != 0);
			road_dates_without_zero = road_dates_without_zero[road_dates_without_zero.length - 1].date;
			const dates = {
				current_date: Object.entries(allDates.global_dates_region[allDates.global_dates_region.length - 1])[0][0],
				region_last_date: Object.entries(allDates.global_dates_region[allDates.global_dates_region.length - 1])[0][0],
				region_first_date: Object.entries(allDates.global_dates_region[0])[0][0],
				selfiso_last_date: Object.entries(allDates.global_dates_selfiso[allDates.global_dates_selfiso.length - 1])[0][0],
				city_last_date: Object.entries(allDates.global_dates_city[allDates.global_dates_city.length - 1])[0][0],
				crimes_last_date: Object.entries(allDates.crime_global_dates[allDates.crime_global_dates.length - 1])[0][0],
				crimes_first_date: Object.entries(allDates.crime_global_dates[0])[0][0],
				road_first_date: Object.entries(allDates.road_global_dates[0])[0][0],
				road_last_date: Object.entries(allDates.road_global_dates[allDates.road_global_dates.length - 1])[0][0],
				road_current_date: road_dates_without_zero

				//FIXME this module deprecated at 07.06.2021
				//almaty_routes_last_date: Object.entries(allDates.almaty_routes_global_dates[allDates.almaty_routes_global_dates.length - 1])[0][0],
			};

			allDates = {
				//FIXME this module deprecated at 07.06.2021
				crime_global_dates: allDates.crime_global_dates,
				global_dates_city: allDates.global_dates_city,
				global_dates_region: allDates.global_dates_region,
				global_dates_selfiso: allDates.global_dates_selfiso,
				road_global_dates: road_global_dates,
			};

			// let road_dates_without_zero = allDates.road_global_dates.filter(item => item.value != 0);
			//  road_dates_without_zero = road_dates_without_zero[road_dates_without_zero.length - 1];

			yield put({
				type: 'setLastAndCurrentDates',
				payload: dates
			});

			const state = yield select(state => state);
			const payloadLastDateActivity = {
				...payload.preparedData,
				dates: {
					from: state.mobile.almaty.dates.activity.last,
					to: state.mobile.almaty.dates.activity.last
				}
			};
			let population = yield call(getMobileAlmatyPopulation, payloadLastDateActivity); //start request
			population = population.object.result;

			const payloadSelfiso = {
				...payload.preparedData,
				dates: {
					from: dates.selfiso_last_date,
					to: dates.selfiso_last_date
				}
			};
			let selfiso = yield call(getMobileAlmatySelfiso, payloadSelfiso);
			selfiso = selfiso.object.result;

			const payloadCity = {
				...payload.preparedData,
				dates: {
					from: dates.city_last_date,
					to: dates.city_last_date
				}
			};
			let city = yield call(getMobileAlmatyCity, payloadCity);
			city = city.object.result;

			const payloadRegion = {
				...payload.preparedData,
				dates: {
					from: dates.region_last_date,
					to: dates.region_last_date
				}
			};
			let region = yield call(getMobileAlmatyRegion, payloadRegion);
			region = region.object.result;

			let dynamicData = yield call(getMobileAlmatyDynamicData, payloadCity);
			dynamicData = dynamicData.object.result;

			let zidsRegion = yield call(getMobileAlmatyZidsRegion, payloadRegion);
			zidsRegion = zidsRegion.object.result;

			let zidsSelfiso = yield call(getMobileAlmatyZidsSelfiso, payloadSelfiso);
			zidsSelfiso = zidsSelfiso.object.result;

			let dots = yield call(getMobileAlmatyDots, payloadLastDateActivity); //start request
			dots = dots.object.result;

			let covidStat = yield call(getMobileAlmatyCovidStat, payloadLastDateActivity);
			covidStat = covidStat.object.result;

			let provinces = yield call(getMobileAlmatyProvinces, payloadLastDateActivity);
			provinces = provinces.object.result;

			/*let regionRoutes = yield call(getMobileAlmatyRegionRoutes, payloadLastDateActivity) //FIXME this module deprecated at 07.06.2021
      regionRoutes = regionRoutes.object.result*/

			let sergek = yield call(getMobileAlmatySergek, payloadLastDateActivity);
			sergek = sergek.object.result;

			const payloadCrime = {
				...payload.preparedData,
				dates: {
					from: dates.crimes_last_date,
					to: dates.crimes_last_date
				},
				hours: {
					from: 1,
					to: 24
				}
			};
			let crime = yield call(getMobileAlmatyCrime, payloadCrime);
			crime = crime.object.result;
			const payloadRoadViolation = {
				...payload.preparedData,
				dates: {
					// from: dates.road_last_date,
					// to: dates.road_last_date
					from: dates.road_current_date,
					to: dates.road_current_date
				}
				// dates: {
				//   from: dates.road_last_date,
				//   to: dates.road_last_date
				// }
			};
			let road = yield call(getMobileAlmatyRoad, payloadRoadViolation);
			road = road.object.result;

			//FIXME this module deprecated at 07.06.2021
			/*const payloadCityRoutes = {
        ...payload.preparedData,
        dates: {
          from: dates.almaty_routes_last_date,
          to: dates.almaty_routes_last_date
        }
      }
      let cityRoutes = yield call(getMobileAlmatyCityRoutes, payloadCityRoutes)
      cityRoutes = cityRoutes.object.result*/

			let types = yield call(getMobileAlmatyTypes, payloadLastDateActivity);
			types = types.object.result;
			let covidHouses = yield call(getMobileAlmatyCovidHouses, payloadLastDateActivity);
			covidHouses = covidHouses.object.result;
			yield put({
				type: 'putStartData',
				payload: {
					allDates,
					population,
					selfiso,
					city,
					region,
					dynamicData,
					zidsRegion,
					zidsSelfiso,
					dots,
					covidStat,
					provinces,
					/*regionRoutes,*/ //FIXME this module deprecated at 07.06.2021
					sergek,
					crime,
					road,
					/*cityRoutes,*/ //FIXME this module deprecated at 07.06.2021
					types,
					covidHouses
				}
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'callAnimationAlmaty' });
			yield put({ type: 'authorization/hideLoader' });
			// yield put({type: 'saveStartData', payload: response.object.result, dates: payload})
			// yield put({type: 'setParsedGetDates', payload: response.object.result})
			// yield put({type: 'nonfiltereddots/saveNonFilteredData', payload: response.object.result})
			// yield put({type: 'callZidsDotsWorkers'})
		},

		* callAnimationData({ payload }, {
			put,
			call,
			select
		}) {

			let state = yield select(state => state);
			const selfisoAnimationPayload = {
				dates: {
					from: state.mobile.almaty.dates.activity.current,
					to: state.mobile.almaty.dates.activity.current
				},
				map: 'selfiso'
			};
			const cityAnimationPayload = {
				dates: {
					from: state.mobile.almaty.dates.activity.current,
					to: state.mobile.almaty.dates.activity.current
				},
				map: 'city'
			};
			const regionAnimationPayload = {
				dates: {
					from: state.mobile.almaty.dates.activity.current,
					to: state.mobile.almaty.dates.activity.current
				},
				map: 'region'
			};
			let selfisoAnimation = yield call(getMobileAnimation, selfisoAnimationPayload);
			selfisoAnimation = selfisoAnimation.object.result;
			let cityAnimation = yield call(getMobileAnimation, selfisoAnimationPayload);
			cityAnimation = cityAnimation.object.result;
			let regionAnimation = yield call(getMobileAnimation, selfisoAnimationPayload);
			regionAnimation = regionAnimation.object.result;
			yield put({
				type: 'pushAnimation',
				payload: {
					selfisoAnimation,
					cityAnimation,
					regionAnimation
				}
			});
			state = yield select(state => state);
		},

		* callAnimationAlmaty({ payload }, {
			put,
			call,
			select
		}) {

			let state = yield select(state => state);
			const datablock = {
				dates: {
					from: state.mobile.almaty.dates.activity.current,
					to: state.mobile.almaty.dates.activity.current
				}
			};
			let animation = yield call(getMobileAnimationAlmaty, datablock);
			animation = animation.object.result;
			yield put({
				type: 'pushAnimation',
				payload: animation
			});
			state = yield select(state => state);
		},
		* callMobileZidsWorker({ payload }, {
			put,
			select
		}) {
			const state = yield select(state => state);
			const { active_filters } = state.mobile.almaty;
			if (mobile_map_filters(active_filters)) {

				const stringified_zids = {
					action: 'zids_mobile',
					payload: {
						zids: state.mobile.almaty.layers[state.mobile.almaty.active_layer].zids,
						active_filters: state.mobile.almaty.active_filters,
						active_layer: state.mobile.almaty.active_layer,
						DPCategory: state.mobile.almaty.DPCategory
					}
				};
				window.zidsWorker.postMessage(JSON.stringify(stringified_zids));
				yield put({ type: 'authorization/hideLoader' });
			} else {
				yield put({ type: 'zidsMobileWorkerSetStoreData' });
			}
		},

		// *callZidsDotsWorkers({ payload }, { put, select, take }) {
		//   const state = yield select(state => state);
		//   const active_filters = state.count.active_filters
		//   if (
		//       ofd_map_filters(active_filters)
		//   ) {
		//     const stringified_dots = {
		//       action: 'dots',
		//       payload: {
		//         dots: state.count.start_data.dots,
		//         active_filters: state.count.active_filters
		//       }
		//     }
		//     window.dotsWorker.postMessage(JSON.stringify(stringified_dots))
		//     const stringified_zids = {
		//       action: 'zids',
		//       payload: {
		//         zids: state.count.start_data.zids,
		//         active_filters: state.count.active_filters
		//       }
		//     }
		//     window.zidsWorker.postMessage(JSON.stringify(stringified_zids))
		//   } else {
		//     yield put({type: 'setFiltredDotsStartData'})
		//     yield put({type: 'setFiltredZidsStartData'})
		//     yield put({type: 'setFiltredWidgetsStartData'})
		//     // yield take('setFiltredWidgetsStartData')
		//     // console.log('after take setFiltredWidgetsStartData')
		//
		//   }
		//   yield put({type: 'hideLoader'})
		// },

		* changeIsAnormalEffect({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'changeIsAnormal' });
			yield put({ type: 'callMobileZidsWorker' });
		},

		* changeIsAnormalRegionEffect({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'changeIsAnormalRegion' });
			yield put({ type: 'callMobileZidsWorker' });
		},

		* changeActiveFilter({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			yield put({
				type: 'changeActiveFilters',
				payload
			});
			if (
				payload.name == 'districts' ||
				payload.name == 'district_area' ||
				payload.name == 'gradient' ||
				payload.name == 'draw_polygon'
			) {
				// yield put({ type: 'callMobileZidsWorker' });
			}
			yield put({ type: 'calculateFilteredZidsFilters', payload });
			yield put({ type: 'calculateWidgetDataFilters', payload });
			yield put({ type: 'authorization/hideLoader' });
			// yield put({type: 'applyActiveFilters'})
			// yield put({type: 'callWidgetWorker'})
			// yield put({type: 'changeWidgetData'})
		},
		* createDrawnObjectEffect({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			yield put({
				type: 'createDrawnObject',
				payload
			});
			// yield put({type: 'addMobileZidsInteractivity'})
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
			// yield put({type: 'applyActiveFilters'})
			// yield put({type: 'callWidgetWorker'})
			// yield put({type: 'changeWidgetData'})
		},
		* deleteDrawPolygon({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			yield put({
				type: 'deleteDrawPolygonRemoveInteractivity',
				payload
			});
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
			// yield put({type: 'applyActiveFilters'})
			// yield put({type: 'callWidgetWorker'})
			// yield put({type: 'changeWidgetData'})
		},

		* addRemoveZidsFeatureEffect({ payload }, {
			put,
			take,
			select
		}) {
			yield put({
				type: 'addRemoveZidsFeature',
				payload
			});
			yield put({ type: 'callMobileZidsWorker' });
		},

		* returnRemovedZidsEffect({ payload }, {
			put,
			select
		}) {
			const state = yield select(state => state);
			if (state.mobile.almaty.active_filters.removing_zids.length > 0) {
				yield put({ type: 'returnRemovedZids' });
				yield put({ type: 'callMobileZidsWorker' });
			}
		},

		* deleteAllPolygonsEffect({ payload }, { put }) {
			yield put({ type: 'authorization/showLoader' });
			yield put({
				type: 'saveInStateDrawMode',
				payload: { mode: 'simple_select' }
			});
			yield put({
				type: 'deleteDrawPolygonRemoveInteractivity',
				payload: { features: payload.features }
			});
			yield put({ type: 'returnRemovedZids' });
			yield put({ type: 'reverseHistory'})
			yield put({ type: 'callMobileZidsWorker' });
			yield put({ type: 'authorization/hideLoader' });
		},

		* removeDotEffect({ payload }, { put }) {
			yield put({
				type: 'removeDot',
				payload
			});
			yield put({ type: 'callMobileZidsWorker' });
		},

		* changeCalendar({ payload }, {
			put,
			take,
			select
		}) {
			yield put({ type: 'authorization/showLoader' });
			yield put({
				type: 'changeCalendar',
				payload
			});
			yield put({ type: 'authorization/hideLoader' });
		},

		* storeDotsDataCallWidgetsWorker({ payload }, {
			put,
			select
		}) {
			yield put({
				type: 'dotsWorkerSetStoreData',
				payload
			});
			const state = yield select(state => state);
			const stringified_widgets = {
				action: 'widgets',
				payload: {
					dots: state.count.filtred_dots,
					active_filters: state.count.active_filters
				}
			};
			window.widgetsWorker.postMessage(JSON.stringify(stringified_widgets));
		},

		* changeMapLayer({ payload }, {
			put,
			select
		}) {
			yield put({
				type: 'setSelectionMapLayer',
				payload
			});
			let state = yield select(state => state);
			yield put({ type: 'calculateWidgetDataFilters', payload });
		},

		* changeTabLayer({ payload }, {
			put,
			select
		}) {
			yield put({
				type: 'setSelectionTabLayer',
				payload
			});
			let state = yield select(state => state);
			if (state.mobile.almaty.active_layer != 'population') {
				// window.animation_layer.update(window[state.mobile.active_layer + '_source'], window['viz_animation_' + state.mobile.animation.layer_counter])
			}
		},

		* animationTik({ payload }, {
			put,
			select,
			call
		}) {
			// window.animation_layer.update(window[state.active_layer + '_source'], window['viz_animation_' + state.animation.layer_counter])
			let state = yield select(state => state);

			//FIXME ADD DYNAMIC SOURCE,
			if (!state.mobile.almaty.animation.pause) {
				if (state.mobile.almaty.animation.layer_counter != 24) {
					yield put({ type: 'animationPlusCounter' });
					state = yield select(state => state);
					console.log('animationTik - animation.layer_counter:', state.mobile.almaty.animation.layer_counter);
					let animation_source = {
						features: state.mobile.almaty.filtred_zids,
						type: 'FeatureCollection'
					};
					window.animation_source = new carto.source.GeoJSON(animation_source);
					//fixme new api, for old look to *changeMapLayer
					window.mobile_animation_layer.update(window.animation_source, window[state.mobile.almaty.active_layer + '_viz_animation_' + state.mobile.almaty.animation.layer_counter]);
					yield call(delay, 1000);
					yield put({ type: 'animationTik' });
				} else {
					console.log('animationTik - animation.layer_counter 24:', state.mobile.almaty.animation.layer_counter);
					yield put({ type: 'stopAnimation' });
				}
			}
		},

		* prevAnimationEffect({ payload }, {
			put,
			select
		}) {
			let state = yield select(state => state);
			if (state.mobile.almaty.animation.layer_counter > 1) {
				yield put({ type: 'animationMinusCounter' });
			}
			if (state.mobile.almaty.animation.pause == false) {
				yield put({ type: 'toggleAnimationPause' });
			}
			console.log('animationTik - animation.layer_counter:', state.mobile.almaty.animation.layer_counter);
			window.mobile_animation_layer.update(window.animation_source, window[state.mobile.almaty.active_layer + '_viz_animation_' + state.mobile.almaty.animation.layer_counter]);
		},

		* nextAnimationEffect({ payload }, {
			put,
			select
		}) {
			let state = yield select(state => state);
			if (state.mobile.almaty.animation.layer_counter < 24) {
				yield put({ type: 'animationPlusCounter' });
			}
			if (state.mobile.almaty.animation.pause == false) {
				yield put({ type: 'toggleAnimationPause' });
			}
			// if ()
			console.log('animationTik - animation.layer_counter:', state.mobile.almaty.animation.layer_counter);
			window.mobile_animation_layer.update(window.animation_source, window[state.mobile.almaty.active_layer + '_viz_animation_' + state.mobile.almaty.animation.layer_counter]);
		},

		* playAnimationEffect({ payload }, { put }) {
			yield put({ type: 'playAnimation' });
			yield put({ type: 'animationTik' });
		},

		* setCurrentAnimationLayerEffect({ payload }, {
			put,
			select
		}) {
			yield put({ type: 'toggleAnimationPause' });
			yield put({
				type: 'setCurrentAnimationLayerReducer',
				payload
			});
			let state = yield select(state => state);
			window.mobile_animation_layer.update(window.animation_source, window[state.mobile.almaty.active_layer + '_viz_animation_' + state.mobile.almaty.animation.layer_counter]);
		},

		* toggleHiddenLayerEffect({ payload }, {
			put,
			select,
			call
		}) {
			yield put({
				type: 'toggleHiddenLayer',
				payload
			});
			let state = yield select(state => state);
			// if(payload == 'business_dots'){
			//   if(state.mobile.hidden_layers.business_dots){
			//     if(window.mobile_business_dots){
			//       yield call(delay,100)
			//       window.mobile_business_dots.hide()
			//       console.log('hide window.mobile_business_dots')
			//     }
			//   } else {
			//     if(window.mobile_business_dots){
			//       yield call(delay,100)
			//       window.mobile_business_dots.show()
			//       console.log('show window.mobile_business_dots')
			//     }
			//   }
			// }

			//FIXME ADD LOGIC FORK LIKE AT MOBILEBUSINESSDOTS TO OTHER LAYERS AND KILL HERE

			// if(payload == 'sergek'){
			//   if(state.mobile.hidden_layers.sergek){
			//     if(window.sergek_layer_dots){
			//       yield call(delay,100)
			//       window.sergek_layer_dots.hide()
			//     }
			//   } else {
			//     if(window.sergek_layer_dots){
			//       yield call(delay,100)
			//       window.sergek_layer_dots.show()
			//     }
			//   }
			// }

			// if(payload == 'crimes'){
			//   if(state.mobile.hidden_layers.crimes){
			//     if(window.crimes_layer_dots){
			//       yield call(delay,100)
			//       window.crimes_layer_dots.hide()
			//     }
			//   } else {
			//     if(window.crimes_layer_dots){
			//       yield call(delay,100)
			//       window.crimes_layer_dots.show()
			//     }
			//   }
			// }

			// if(payload == 'road_violation'){
			//   if(state.mobile.hidden_layers.road_violation){
			//     if(window.road_layer_dots){
			//       yield call(delay,100)
			//       window.road_layer_dots.hide()
			//     }
			//   } else {
			//     if(window.road_layer_dots){
			//       yield call(delay,100)
			//       window.road_layer_dots.show()
			//     }
			//   }
			// }

			// if(payload == 'zids'){
			//   if(state.mobile.hidden_layers.zids){
			//     if(window.mobile_layer){
			//       yield call(delay,100)
			//       window.mobile_layer.hide()
			//     }
			//   } else {
			//     if(window.mobile_layer){
			//       yield call(delay,100)
			//       window.mobile_layer.show()
			//     }
			//   }
			// }

			// if(payload == 'covid_houses'){
			//   if(state.hidden_layers.covid_houses){
			//     window.map.setLayoutProperty(
			//       'covid-houses',
			//       'visibility',
			//     )
			//   } else {
			//     window.map.setLayoutProperty(
			//       'covid-houses',
			//       'visibility',
			//       'none'
			//     )
			//   }
			// }
			//sergek - window.sergek_layer_dots
			//road_violation - window.road_layer_dots
			//crimes - window.crimes_layer_dots
			//dots(больницы) - window.layer_dots
			//mobile_layers - window.mobile_layer
		},

		* toggleInteractivityLayerEffect({ payload }, { put }) {
			yield put({
				type: 'toggleInteractivityLayer',
				payload
			});
		},

		// * zidClickOutEffect({ payload }, {
		//   put,
		//   select,
		//   call
		// }) {
		//   let state = yield select(state => state);
		//   console.log('current_zid', state.mobile.almaty.active_filters.current_zid)
		//   console.log('zoom lvl', window.map.getZoom())
		//   console.log('window.active_zid', window.active_zid)
		//   yield put({type: 'zidClickOut'})
		// },

		*zidClickOutEffect({ payload }, { put, select }){
			const state = yield select(state => state);
			yield put({ type: 'zidClickOut', payload})
			if(state.mobile.almaty.draw_polygon.features.length === 0){
				yield put({ type: 'reverseHistory'})
			}
		},

		*switchModeDPEffect({payload}, {put}){
			yield put({type: 'switchModeDP', payload})
			yield put({type: 'changeDPDynamicGradient'})
		},

		*DPCategorySwitchEffect({ payload }, {put}){
			yield put({type: 'DPCategorySwitch', payload})

			yield put({type: 'changeDPDynamicGradient'})
		},

		*changeSubLayerEffect({ payload }, {put}){
			yield put({type: 'subLayerSwitch', payload})
		},

		*getHistoryHours({payload}, { put, call, select }){
			const state = yield select(state => state);
			let response
			let hours
			yield put({type: 'setHoursPending'})
			if (mapName() == 'MobileAlmaty') {
				let payloadNewData = {}
				if(payload.layer === 'activity'){
					payloadNewData = {
						dates_from: payload.from,
						hours_from: state.mobile.almaty.history_filters.dates[payload.layer].current_date.hour.from || state.mobile.almaty.history_filters.dates[payload.layer].current_date.hour,
					}
					response = yield call(getMobileDataNewAlmatyActivity, payloadNewData)
					hours = response.object.result.hours
				}
			}
			yield put({type: 'putHistoryHours', payload:{hours, layer: payload.layer}})
		},
		*setHistoryFilters({ payload }, { put, call, select }){
			const state = yield select(state => state)
			yield put({ type: 'authorization/showLoader' })
			const hours_from = payload.hours ? payload.hours : 13
			const hours_to = payload.hours ? payload.hours + 1 : 14
			let dates_from = state.mobile.almaty.history_filters.dates.activity.calendar_selected.from || state.mobile.almaty.history_filters.dates.activity.available_dates.from
			let dates_to = state.mobile.almaty.history_filters.dates.activity.calendar_selected.to || state.mobile.almaty.history_filters.dates.activity.available_dates.to
			if(state.mobile.almaty.historyFirstOpen){
				dates_to = state.mobile.almaty.history_filters.dates.activity.available_dates.to
				dates_from = moment(dates_to).subtract(3, 'months').format('YYYY-MM-DD')
			}
			let zid
			let payloadForCall = {
				dates_from,
				dates_to,
				hours_from,
				hours_to,
				// district: payload.district,
				// district_area: payload.district_area,
			}
			if(state.mobile.almaty.active_filters.current_zid !== ''){
				zid = state.mobile.almaty.active_filters.current_zid
				payloadForCall = {
					...payloadForCall,
					zid,
				}
			} else {
				zid = state.mobile.almaty.filtred_zids.map(item => item.properties.zid_id).join(', ')
				payloadForCall = {
					...payloadForCall,
					zids: zid,
				}
			}
			// FIXME forgot why this check needed xd mb unnecessary
			// if(state.mobile.almaty.history_zids.length > 0) {
			//   if(!state.mobile.almaty.history_zids.map(item => item.date).includes(dates_from)){
			//     yield put({ type: 'setDatesPending', payload: true })
			//     let result = yield call(getHistoryData, payloadForCall)
			//     result = result.object.result
			//     if(result){
			//       yield put({ type: 'putHistoryZids', payload: { ...result }})
			//     } else {
			//       yield put({type: 'setHistoryError'})
			//     }
			//   }
			// } else {
			yield put({ type: 'setDatesPending', payload: true })
			let result = yield call(getHistoryData, payloadForCall)
			result = result.object.result
			if(result){
				yield put({ type: 'putHistoryZids', payload: { ...result }})
			} else {
				yield put({type: 'setHistoryError'})
			}
			// }
			yield put({ type: 'authorization/hideLoader' })
		},
		*clearHistoryFilters({ payload }, { put, select }){
			const state = yield select(state => state)
			const current_date = state.mobile.almaty.history_filters.dates.activity.current_date.day
			const available_date = state.mobile.almaty.history_filters.dates.activity.available_dates.to
			if(typeof current_date === 'object'){
				if(current_date.from !== available_date){
					const newActivityPayload = {
						from: available_date,
						layer: 'activity',
					}
					yield put({type: 'getHistoryHours', payload: {...newActivityPayload}})
				}
			} else {
				if(current_date !== available_date){
					const newActivityPayload = {
						from: available_date,
						layer: 'activity',
					}
					yield put({type: 'getHistoryHours', payload: {...newActivityPayload}})
				}
			}
			yield put({type: 'clearCalendar'})
		},
	},

	reducers: {

		//FIXME Switch deficit proficit mode
		switchModeDP(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					mode_DP: payload.mode,
				}
			};
		},

		//FIXME Switch category of deficit proficit
		DPCategorySwitch(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					DPCategory: payload,
				}
			}
		},


		subLayerSwitch(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					active_sublayer: payload.value,
				}
			}
		},

		//FIXME Change dynamic gradient of DP by chosen category / filters


		// clearDPFiltersAfterSwitch(state, {payload}) {
		//   return {
		//     ...state,
		//     .almaty: {
		//       ...state.almaty,
		//       active_filters: {
		//         ...state.almaty.active_filters,
		//         [`${payload.mode}_social`]: payload.mode === 'deficit' ? 'rajonnyj_park' : 'kolledzhi',
		//         [`${payload.mode}_business`]: '',
		//       }
		//     }
		//   }
		// },

		removeZidsInteractivity(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					interactivity: {
						...state.almaty.interactivity,
						mobileZids: false,
					},
				},
			};
		},

		addZidsInteractivity(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					interactivity: {
						...state.almaty.interactivity,
						mobileZids: true,
					},
				},
			};
		},

		deleteDrawPolygonRemoveInteractivity(state, { payload }) {

			const tempDrawObject = {
				drawPolygon: state.almaty.active_filters.draw_polygon,
				drawPoint: state.almaty.active_filters.draw_point,
			};

			payload.features.map(feature => {
				if (state.almaty.active_filters[`draw_${feature.geometry.type.toLowerCase()}`].length > 0) {
					tempDrawObject[`draw${feature.geometry.type}`] = tempDrawObject[`draw${feature.geometry.type}`].filter(item => item.id !== feature.id);
				}
			});
			return {
				...state,
				almaty: {
					...state.almaty,
					interactivity: {
						...state.almaty.interactivity,
						mobileZids: true,
					},
					draw_polygon: {
						features: tempDrawObject.drawPolygon,
						type: 'FeatureCollection',
					},
					draw_point: {
						features: tempDrawObject.drawPoint,
						type: 'FeatureCollection',
					},
					active_filters: {
						...state.almaty.active_filters,
						draw_polygon: tempDrawObject.drawPolygon,
						draw_point: tempDrawObject.drawPoint,
					},
					isHistory: false,
					historyFirstOpen: true,
					history_filters: {
						dates: {
							activity: {
								...state.almaty.history_filters.dates.activity,
								calendar_selected: {
									from: '',
									to: '',
								},
								current_date: {
									day: '',
									hour: '',
								}
							}
						},
						hours: {
							activity: {},
						}
					},
				},
			};
		},

		addRemoveZidsFeature(state, { payload }) {
			let chosenZid = [];
			if (state.almaty.start_data.zids.features.length > 0) {
				chosenZid = state.almaty.start_data.zids.features.filter(item => turf(payload.point, item));
			}
			;
			let all_removing_zids = state.almaty.active_filters.removing_zids;
			if (all_removing_zids.length > 0) {
				if (all_removing_zids.map(item => item.properties.zid_id)
					.includes(chosenZid[0].properties.zid_id)) {
					all_removing_zids = all_removing_zids.filter(item => item.properties.zid_id !== chosenZid[0].properties.zid_id);
				} else {
					all_removing_zids = [...all_removing_zids, ...chosenZid];
				}
			} else {
				all_removing_zids = [...all_removing_zids, ...chosenZid];
			}
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						...state.almaty.active_filters,
						removing_zids: all_removing_zids,
					},
				},
			};
		},

		createDrawnObject(state, { payload }) {
			const createdPolygon = payload.data.features;
			return {
				...state,
				almaty: {
					...state.almaty,
					[payload.mode]: payload.data,
					active_filters: {
						...state.almaty.active_filters,
						[payload.mode]: createdPolygon,
					},
					isHistory: true,
				},
			};
		},

		returnRemovedZids(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						...state.almaty.active_filters,
						removing_zids: [],
					},
				},
			};
		},

		saveInStateDrawMode(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					draw_mode: payload.mode,
				},
			};
		},

		removeDot(state, { payload }) {
			let chosenZid = [];
			if (state.almaty.start_data.zids.features.length > 0) {
				chosenZid = state.almaty.start_data.zids.features.filter(item => turf(payload.clickedPoint, item));
			}
			;
			if (chosenZid.length > 0) {
				let nearestPoint = {};
				if (state.almaty.draw_point.features.length > 0) {
					nearestPoint = state.almaty.draw_point.features.filter(item => turf(chosenZid[0], item))[0];
				}
				const filtredPoints = state.almaty.draw_point.features.filter(item => item.id !== nearestPoint.id);
				window.draw.set({
					type: 'FeatureCollection',
					features: [...state.almaty.draw_polygon.features, ...filtredPoints],
				});
				return {
					...state,
					almaty: {
						...state.almaty,
						draw_point: {
							features: filtredPoints,
							type: 'FeatureCollection',
						},
						active_filters: {
							...state.almaty.active_filters,
							draw_point: filtredPoints,
						},
					},
				};
			} else {
				const filtredPoints = state.almaty.draw_point.features.filter(item => item.id !== payload.featureTarget.properties.id);
				window.draw.set({
					type: 'FeatureCollection',
					features: [...state.almaty.draw_polygon.features, ...filtredPoints],
				});
				return {
					...state,
					almaty: {
						...state.almaty,
						draw_point: {
							features: filtredPoints,
							type: 'FeatureCollection',
						},
						active_filter: {
							...state.almaty.active_filters,
							draw_point: filtredPoints,
						},
					},
				};
			}
		},

		changeIsAnormal(state, { payload }) {
			console.log('Reducer:: changeIsAnormal');
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						...state.almaty.active_filters,
						is_anormal: !state.almaty.active_filters.is_anormal
					}
				}
			};
		},

		changeIsAnormalRegion(state, { payload }) {
			console.log('Reducer:: changeIsAnormal');
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						...state.almaty.active_filters,
						is_anormal_region: !state.almaty.active_filters.is_anormal_region
					}
				}
			};
		},

		zidClick(state, { payload }) {
			console.log('in zidClick');
			return {
				...state,
				almaty: {
					...state.almaty,
					right_top_nav_active_tab: 1,
					zid_click_for_active_tab: 1,
					flagForActiveTab: 1,
					loader: false,
					active_filters: {
						...state.almaty.active_filters,
						current_zid: payload,
						dots_category: [],
						dot_object: '',
						dot_id: ''
					},
					isHistory: true,
				}
			};
		},

		zidClickOut(state, { payload }) {
			window.active_zid = false;
			//
			if (
				state.almaty.active_filters.current_zid.length > 0 ||
				state.almaty.active_filters.dots_category.length > 0 ||
				state.almaty.active_filters.dot_object.length > 0 ||
				state.almaty.active_filters.dots_category.length > 0 ||
				state.almaty.active_filters.dot_object.length > 0 ||
				state.almaty.active_filters.dot_id.length > 0
			) {
				return {
					...state,
					almaty: {
						...state.almaty,
						right_top_nav_active_tab: 0,
						zid_click_for_active_tab: 0,
						flagForActiveTab: 0,
						SectorActiveSemiTab: 0,
						active_filters: {
							...state.almaty.active_filters,
							current_zid: '',
							dots_category: [],
							dot_object: '',
							dot_id: ''
						},
						isHistory: state.almaty.draw_polygon.features.length > 0,
						historyFirstOpen: !state.almaty.draw_polygon.features.length > 0,
						history_filters: {
							dates: {
								activity: {
									...state.almaty.history_filters.dates.activity,
									calendar_selected: {
										from: '',
										to: '',
									},
									current_date: {
										day: '',
										hour: '',
									}
								}
							},
							hours: {
								activity: {},
							}
						}
					}
				};
			} else {
				return state;
			}
		},

		switchCrimesDtp(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					crimes_dtp_active_layer: payload,
				}
			}
		},

		resetZidPopupFilters(state, { payload }) {
			if (
				state.almaty.active_filters.current_zid.length > 0 ||
				state.almaty.active_filters.dots_category.length > 0 ||
				state.almaty.active_filters.dot_object.length > 0 ||
				state.almaty.active_filters.dot_id.length > 0
			) {
				return {
					...state,
					almaty: {
						...state.almaty,
						active_filters: {
							...state.almaty.active_filters,
							current_zid: [],
							dots_category: [],
							dot_object: '',
							dot_id: ''
						}
					},
				};
			} else {
				return state.almaty;
			}
		},

		setCurrentAnimationLayerReducer(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					animation: {
						...state.almaty.animation,
						layer_counter: payload,
					}
				}
			};
		},

		pushAnimation(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					layers: {
						...state.almaty.layers,
						region: {
							...state.almaty.layers.region,
							animation_data: {
								...state.almaty.layers.region.animation_data,
								zids: payload.animation_data.region.zids.features
							}
						},
						city: {
							...state.almaty.layers.city,
							animation_data: {
								...state.almaty.layers.city.animation_data,
								zids: payload.animation_data.city.zids.features
							}
						},
						selfiso: {
							...state.almaty.layers.selfiso,
							animation_data: {
								...state.almaty.layers.selfiso.animation_data,
								zids: payload.animation_data.selfiso.zids.features
							}
						}
					}
				}
			};
		},

		playAnimation(state) {
			window.mobile_animation_layer.show();
			return {
				...state,
				almaty: {
					...state.almaty,
					animation: {
						...state.almaty.animation,
						status: true,
						pause: false,
					}
				}
			};
		},

		stopAnimation(state) {
			window.mobile_animation_layer.hide();
			return {
				...state,
				almaty: {
					...state.almaty,
					animation: {
						...state.almaty.animation,
						status: false,
						pause: true,
						layer_counter: 7
					}
				}
			};
		},

		toggleAnimation(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					animation: {
						...state.almaty.animation,
						status: !state.almaty.animation.status,
					}
				}
			};
		},

		toggleAnimationPause(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					animation: {
						...state.almaty.animation,
						pause: !state.almaty.animation.pause,
					}
				}
			};
		},

		animationMinusCounter(state) {
			if (state.animation.layer_counter > 1) {
				return {
					...state,
					almaty: {
						...state.almaty,
						animation: {
							...state.almaty.animation,
							layer_counter: state.almaty.animation.layer_counter - 1,
						}
					}
				};
			}
		},

		animationPlusCounter(state) {
			if (state.almaty.animation.layer_counter < 24) {
				return {
					...state,
					almaty: {
						...state.almaty,
						animation: {
							...state.almaty.animation,
							layer_counter: state.almaty.animation.layer_counter + 1,
						},
					},
				};
			}
			;
		},

		toggleHiddenLayer(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					hidden_layers: {
						...state.almaty.hidden_layers,
						[payload]: !state.almaty.hidden_layers[payload]
					}
				}
			};
		},
		toggleInteractivityLayer(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					interactivity: {
						...state.almaty.interactivity,
						[payload]: !state.almaty.interactivity[payload],
					}
				}
			};
		},

		// changeGradientViaLayer(state){
		//   if(state.active_filters.gradient.max.length > 0){
		//     if(state.active_filters == 'population'){
		//
		//     }
		//   }
		// },

		setLastAndCurrentDates(state, { payload }) {
			// let road_date_fake_fixed_date = "2021-05-18"
			//FIXME RENAME ALL DATES OBJECT
			return {
				...state,
				almaty: {
					...state.almaty,
					dates: {
						...state.almaty.dates,
						activity: {
							...state.almaty.dates.activity,
							last: payload.region_last_date,
							// current: payload.region_last_date
							current: payload.current_date
						},
						crimes: {
							...state.almaty.dates.crimes,
							last: payload.crimes_last_date,
							current: {
								from: payload.crimes_last_date,
								to: moment(payload.crimes_last_date)
									.add(1, 'days')
									.format(date_format)
							}
						},
						// road_violation: {
						//   last: payload.road_last_date,
						//   current: payload.road_last_date
						// },
						road_violation: {
							last: payload.road_last_date,
							current: {
								from: payload.road_last_date,
								to: moment(payload.road_last_date)
									.add(1, 'days')
									.format(date_format)
							}
						},
						/*passanger_traffic: { //FIXME this module deprecated at 07.06.2021
          ...state.dates.passanger_traffic,
          last: payload.almaty_routes_last_date,
          current: payload.almaty_routes_last_date
        }*/
					},

					// FIXME calendar
					calendar: {
						...state.almaty.calendar,
						activity: {
							...state.almaty.calendar.activity,
							current: payload.region_last_date,
							selectDateStart: payload.region_first_date,
							selectDateEnd: payload.region_last_date
						},
						crimes: {
							...state.almaty.calendar.crimes,
							current: payload.crimes_last_date,
							selectDateStart: payload.crimes_first_date,
							selectDateEnd: payload.crimes_last_date
						},
						dtp: {
							...state.almaty.calendar.dtp,
							selectDateStart: payload.road_first_date,
							selectDateEnd: payload.road_last_date,
							current: payload.road_current_date
						}
					},
				}
			};
		},

		updateStartData(state, { payload }) {
			let result = {};
			if (payload.payload.crime) {
				result = {
					...state,
					almaty: {
						...state.almaty,
						start_data: {
							...state.almaty.start_data,
							...payload.payload
						},
						dates: {
							...state.almaty.dates,
							crimes: {
								...state.almaty.dates.crimes,
								current: {
									from: payload.current.from,
									to: payload.current.to
								},
								hours: payload.hours
							}
						}
					}
				};
			} else if (payload.payload.road) {
				result = {
					...state,
					almaty: {
						...state.almaty,
						start_data: {
							...state.almaty.start_data,
							...payload.payload
						},
						dates: {
							...state.almaty.dates,
							road_violation: {
								...state.almaty.dates.road_violation,
								current: {
									from: payload.current.from,
									to: payload.current.to
								},
							}
						}
					}
				};
			} else if (payload.payload.zidsRegion) {
				result = {
					...state,
					almaty: {
						...state.almaty,
						start_data: {
							...state.almaty.start_data,
							...payload.payload
						},
						layers: {
							...state.almaty.layers,
							region: {
								...state.almaty.layers.region,
								zids: payload.payload.zidsRegion.zids.features,
								hours: payload.payload.region.hours_region,
								districts: payload.payload.region.districts_region,
								district_area: payload.payload.region.district_area_region
							},
							city: {
								...state.almaty.layers.city,
								zids: payload.payload.dynamicData.zids_city.features,
								hours: payload.payload.city.hours,
								districts: payload.payload.city.districts,
								district_area: payload.payload.city.district_area
							},
							selfiso: {
								...state.almaty.layers.selfiso,
								zids: payload.payload.zidsSelfiso.zids.features,
								hours: payload.payload.selfiso.hours,
								districts: payload.payload.selfiso.districts,
								district_area: payload.payload.selfiso.district_area
							},
							deficit: {}
						},
						zids: {},
						dates: {
							...state.almaty.dates,
							activity: {
								...state.almaty.dates.activity,
								current: payload.current,
								hour: payload.hour
							}
						}
					}
				};
			}
			return result;
		},
		//FIXME NEW LOGIC BY START REDUCER, AFTER CHANGING DATE NEED USE ANOTHER REDUCERS(ACTIVITY\CRIME\ROAD)
		putDataAfterNewActivity(state, { payload }) {
			let tempLegendsTypes = {};
			const dynamic_gradient = payload.apiResult.dynamic_gradient || state.almaty.start_data.dynamic_gradient;
			//FIXME For deficit proficit
			const filter = state.almaty.active_filters[`${state.almaty.mode_DP}_social`] ||
				state.almaty.active_filters[`${state.almaty.mode_DP}_business`]
			const category = state.almaty.DPCategory
			if(dynamic_gradient){
				tempLegendsTypes = {
					...state.almaty.legends_types,
					mobile: {
						...state.almaty.legends_types.mobile,
						city: {
							backets: [
								dynamic_gradient.city.threshold_2,
								dynamic_gradient.city.threshold_3,
								dynamic_gradient.city.threshold_4,
								dynamic_gradient.city.threshold_5,
								dynamic_gradient.city.threshold_6,
								dynamic_gradient.city.threshold_7,
								dynamic_gradient.city.threshold_8,
								dynamic_gradient.city.threshold_9,
								dynamic_gradient.city.threshold_10
							],
							gradient: [
								{
									min: 0,
									max: dynamic_gradient.city.threshold_2
								},
								{
									min: dynamic_gradient.city.threshold_2,
									max: dynamic_gradient.city.threshold_3
								},
								{
									min: dynamic_gradient.city.threshold_3,
									max: dynamic_gradient.city.threshold_4
								},
								{
									min: dynamic_gradient.city.threshold_4,
									max: dynamic_gradient.city.threshold_5
								},
								{
									min: dynamic_gradient.city.threshold_5,
									max: dynamic_gradient.city.threshold_6
								},
								{
									min: dynamic_gradient.city.threshold_6,
									max: dynamic_gradient.city.threshold_7
								},
								{
									min: dynamic_gradient.city.threshold_7,
									max: dynamic_gradient.city.threshold_8

								},
								{
									min: dynamic_gradient.city.threshold_8,
									max: dynamic_gradient.city.threshold_9
								},
								{
									min: dynamic_gradient.city.threshold_9,
									max: dynamic_gradient.city.threshold_10
								},
								{
									min: dynamic_gradient.city.threshold_10,
									max: 'infinity'
								}
							],
							colors: new_colors,
						},
						region: {
							backets: [
								dynamic_gradient.region.threshold_2,
								dynamic_gradient.region.threshold_3,
								dynamic_gradient.region.threshold_4,
								dynamic_gradient.region.threshold_5,
								dynamic_gradient.region.threshold_6,
								dynamic_gradient.region.threshold_7,
								dynamic_gradient.region.threshold_8,
								dynamic_gradient.region.threshold_9,
								dynamic_gradient.region.threshold_10
							],
							gradient: [
								{
									min: 0,
									max: dynamic_gradient.region.threshold_2
								},
								{
									min: dynamic_gradient.region.threshold_2,
									max: dynamic_gradient.region.threshold_3
								},
								{
									min: dynamic_gradient.region.threshold_3,
									max: dynamic_gradient.region.threshold_4
								},
								{
									min: dynamic_gradient.region.threshold_4,
									max: dynamic_gradient.region.threshold_5
								},
								{
									min: dynamic_gradient.region.threshold_5,
									max: dynamic_gradient.region.threshold_6
								},
								{
									min: dynamic_gradient.region.threshold_6,
									max: dynamic_gradient.region.threshold_7
								},
								{
									min: dynamic_gradient.region.threshold_7,
									max: dynamic_gradient.region.threshold_8

								},
								{
									min: dynamic_gradient.region.threshold_7,
									max: dynamic_gradient.region.threshold_9
								},
								{
									min: dynamic_gradient.region.threshold_9,
									max: dynamic_gradient.region.threshold_10
								},
								{
									min: dynamic_gradient.region.threshold_10,
									max: 'infinity'
								}
							],
							colors: new_colors,
						},
						selfiso: {
							backets: [
								dynamic_gradient.selfiso.threshold_2,
								dynamic_gradient.selfiso.threshold_3,
								dynamic_gradient.selfiso.threshold_4,
								dynamic_gradient.selfiso.threshold_5,
								dynamic_gradient.selfiso.threshold_6,
								dynamic_gradient.selfiso.threshold_7,
								dynamic_gradient.selfiso.threshold_8,
								dynamic_gradient.selfiso.threshold_9,
								dynamic_gradient.selfiso.threshold_10
							],
							gradient: [
								{
									min: 0,
									max: dynamic_gradient.selfiso.threshold_2
								},
								{
									min: dynamic_gradient.selfiso.threshold_2,
									max: dynamic_gradient.selfiso.threshold_3
								},
								{
									min: dynamic_gradient.selfiso.threshold_3,
									max: dynamic_gradient.selfiso.threshold_4
								},
								{
									min: dynamic_gradient.selfiso.threshold_4,
									max: dynamic_gradient.selfiso.threshold_5
								},
								{
									min: dynamic_gradient.selfiso.threshold_5,
									max: dynamic_gradient.selfiso.threshold_6
								},
								{
									min: dynamic_gradient.selfiso.threshold_6,
									max: dynamic_gradient.selfiso.threshold_7
								},
								{
									min: dynamic_gradient.selfiso.threshold_7,
									max: dynamic_gradient.selfiso.threshold_8

								},
								{
									min: dynamic_gradient.selfiso.threshold_7,
									max: dynamic_gradient.selfiso.threshold_9
								},
								{
									min: dynamic_gradient.selfiso.threshold_9,
									max: dynamic_gradient.selfiso.threshold_10
								},
								{
									min: dynamic_gradient.selfiso.threshold_10,
									max: 'infinity'
								}
							],
							colors: new_colors,
						},
						almaty_population: {
							backets: new_backets_population,
							colors: new_colors,
							gradient: new_gradient_population,
						},

					}
				};
			} else {
				tempLegendsTypes = state.almaty.legends_types;
			}
			let dates
			if(state.almaty.history_filters.dates.activity.current_date.day.from === payload.apiResult.activity_current_date.current_date){
				dates = {
					...state.almaty.dates
				}
			} else {
				dates = {
					...state.almaty.dates,
					activity: {
						...state.almaty.dates.activity,
						current_date: {
							...state.almaty.dates.activity.current_date,
							day: payload.apiResult.activity_current_date.current_date,
							hour: payload.apiResult.activity_current_date.current_hour.from
						},
					},
				}
			}
			return {
				...state,
				[payload.city]: {
					...state[payload.city],
					legends_types: {
						...tempLegendsTypes,
					},
					dates: {
						...dates
					},
					//FIXME PUT START DATA
					start_data: {
						...state.almaty.start_data,
						activity_current_date: payload.apiResult.activity_current_date,
						city: {
							district_area: payload.apiResult.district_area.district_area_city,
							districts: payload.apiResult.districts.districts_city,
							hours: payload.apiResult.hours.hours_city
						},
						covid_stat: payload.apiResult.covid_stat,
						district_area: payload.apiResult.district_area,
						districts: payload.apiResult.districts,
						provinces: payload.apiResult.provinces,
						region: {
							district_area: payload.apiResult.district_area.district_area_region,
							districts: payload.apiResult.districts.districts_region,
							hours: payload.apiResult.hours.hours_region
						},
						selfiso: {
							district_area: payload.apiResult.district_area.district_area_selfiso,
							districts: payload.apiResult.districts.districts_selfiso,
							hours: payload.apiResult.hours.hours_selfiso
						},
						sergek: payload.apiResult.sergek,
						zids: payload.apiResult.zids,
					},
					layers: {
						...state.almaty.layers,
						region: {
							...state.almaty.layers.region,
							zids: payload.apiResult.zids.features,
							hours: payload.apiResult.hours.hours_region,
							districts: payload.apiResult.districts.districts_region,
							district_area: payload.apiResult.district_area.district_area_region
						},
						city: {
							...state.almaty.layers.city,
							zids: payload.apiResult.zids.features,
							hours: payload.apiResult.hours.hours_city,
							districts: payload.apiResult.districts.districts_city,
							district_area: payload.apiResult.district_area.district_area_city
						},
						selfiso: {
							...state.almaty.layers.selfiso,
							zids: payload.apiResult.zids.features,
							hours: payload.apiResult.hours.hours_selfiso,
							districts: payload.apiResult.districts.districts_selfiso,
							district_area: payload.apiResult.district_area.district_area_selfiso
						},
						population: {
							...state.almaty.layers.population,
							zids: payload.apiResult.zids.features,
							districts: payload.apiResult.districts.districts_population,
							district_area: payload.apiResult.district_area.district_area_population
						},
						deficit: {
							...state.almaty.layers.deficit,
							zids: payload.apiResult.zids.features,
							districts: payload.apiResult.districts.districts_population,
							district_area: payload.apiResult.district_area.district_area_population
						},
						proficit: {
							...state.almaty.layers.proficit,
							zids: payload.apiResult.zids.features,
							districts: payload.apiResult.districts.districts_population,
							district_area: payload.apiResult.district_area.district_area_population
						}
					},
					zids: {}
				}
			};
		},

		putDataAfterNewRoad(state, { payload }) {
			//FIXME NEW API
			return {
				...state,
				almaty: {
					...state.almaty,
					dates: {
						...state.almaty.dates,
						road_violation: {
							...state.almaty.dates.road_violation,
							current_date: {
								day: payload.apiResult.road_violation_current_date.current_date,
							},
						},
					},
					//FIXME PUT START DATA
					start_data: {
						...state.almaty.start_data,
						road: payload.apiResult
					},
				}
			};
		},

		putDataAfterNewCrime(state, { payload }) {
			//FIXME NEW API
			return {
				...state,
				almaty: {
					...state.almaty,
					dates: {
						...state.almaty.dates,
						crimes: {
							...state.almaty.dates.crimes,
							current_date: {
								...state.almaty.dates.crimes.current_day,
								day: {
									from: payload.apiResult.crimes_current_date.current_date.from,
									to: payload.apiResult.crimes_current_date.current_date.to
								},
								hour: {
									from: payload.apiResult.crimes_current_date.current_hour.from,
									to: payload.apiResult.crimes_current_date.current_hour.to
								}
							},
						},
					},
					//FIXME PUT START DATA
					start_data: {
						...state.almaty.start_data,
						crime: payload.apiResult
					},
				}
			};
		},

		putDataAfterFirstPageLoad(state, { payload }) {
			//FIXME NEW API
			let dynamic_gradient = payload.apiResult.dynamic_gradient;
			let tempLegendTypes = {};
			//FIXME For deficit proficit
			const filter = state.almaty.active_filters[`${state.almaty.mode_DP}_social`] ||
				state.almaty.active_filters[`${state.almaty.mode_DP}_business`]
			const category = state.almaty.DPCategory
			if(dynamic_gradient) {
				console.log('dynamic_gradient',dynamic_gradient)

				tempLegendTypes = {
					...state.almaty.legends_types,
					mobile: {
						...state.almaty.legends_types.mobile,
						avg_receipt_amt: {
							cbh_and_competitors_avg_receipt_amt: {
								backets: [
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_2 + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_3  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_4  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_5  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_6  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_7  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_8  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_9  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_2
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_2,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_3
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_3,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_4
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_4,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_5
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_5,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_6
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_6,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_7
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_7,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_8

									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_8,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_9
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_9,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_10
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_competitors_avg_receipt_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							cbh_and_no_competitors_avg_receipt_amt: {
								backets: [
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_2  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_3  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_4  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_5  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_6  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_7  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_8  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_9  + 0.5,
									dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_2
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_2,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_3
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_3,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_4
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_4,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_5
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_5,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_6
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_6,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_7
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_7,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_8

									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_8,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_9
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_9,
										max: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_10
									},
									{
										min: dynamic_gradient.avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							no_cbh_but_competitors_avg_receipt_amt: {
								backets: [
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_2  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_3  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_4  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_5  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_6  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_7  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_8  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_9  + 0.5,
									dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_2
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_2,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_3
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_3,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_4
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_4,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_5
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_5,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_6
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_6,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_7
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_7,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_8

									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_8,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_9
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_9,
										max: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_10
									},
									{
										min: dynamic_gradient.avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							others_only_avg_receipt_amt: {
								backets: [
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_2  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_3  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_4  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_5  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_6  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_7  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_8  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_9  + 0.5,
									dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_2
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_2,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_3
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_3,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_4
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_4,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_5
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_5,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_6
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_6,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_7
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_7,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_8

									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_8,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_9
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_9,
										max: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_10
									},
									{
										min: dynamic_gradient.avg_receipt_amt.others_only_avg_receipt_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
						},
						category_sales_amt: {
							cbh_and_competitors_category_sales_amt: {
								backets: [
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_2  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_3  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_4  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_5  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_6  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_7  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_8  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_9  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_2
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_2,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_3
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_3,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_4
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_4,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_5
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_5,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_6
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_6,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_7
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_7,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_8

									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_8,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_9
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_9,
										max: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_10
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_competitors_category_sales_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							cbh_and_no_competitors_category_sales_amt: {
								backets: [
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_2  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_3  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_4  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_5  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_6  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_7  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_8  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_9  + 0.5,
									dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_2
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_2,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_3
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_3,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_4
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_4,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_5
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_5,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_6
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_6,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_7
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_7,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_8

									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_8,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_9
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_9,
										max: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_10
									},
									{
										min: dynamic_gradient.category_sales_amt.cbh_and_no_competitors_category_sales_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							no_cbh_but_competitors_category_sales_amt: {
								backets: [
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_2  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_3  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_4  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_5  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_6  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_7  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_8  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_9  + 0.5,
									dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_2
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_2,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_3
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_3,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_4
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_4,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_5
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_5,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_6
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_6,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_7
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_7,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_8

									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_8,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_9
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_9,
										max: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_10
									},
									{
										min: dynamic_gradient.category_sales_amt.no_cbh_but_competitors_category_sales_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							others_only_category_sales_amt: {
								backets: [
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_2  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_3  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_4  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_5  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_6  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_7  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_8  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_9  + 0.5,
									dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_2
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_2,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_3
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_3,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_4
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_4,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_5
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_5,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_6
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_6,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_7
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_7,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_8

									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_8,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_9
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_9,
										max: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_10
									},
									{
										min: dynamic_gradient.category_sales_amt.others_only_category_sales_amt.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
						},
						place_count: {
							cbh_and_competitors_place_count: {
								backets: [
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_2  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_3  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_4  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_5  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_6  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_7  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_8  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_9  + 0.5,
									dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_2
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_2,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_3
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_3,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_4
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_4,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_5
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_5,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_6
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_6,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_7
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_7,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_8

									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_8,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_9
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_9,
										max: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_10
									},
									{
										min: dynamic_gradient.place_count.cbh_and_competitors_place_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							cbh_and_no_competitors_place_count: {
								backets: [
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_2  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_3  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_4  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_5  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_6  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_7  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_8  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_9  + 0.5,
									dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_2
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_2,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_3
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_3,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_4
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_4,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_5
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_5,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_6
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_6,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_7
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_7,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_8

									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_8,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_9
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_9,
										max: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_10
									},
									{
										min: dynamic_gradient.place_count.cbh_and_no_competitors_place_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							no_cbh_but_competitors_place_count: {
								backets: [
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_2  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_3  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_4  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_5  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_6  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_7  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_8  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_9  + 0.5,
									dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_2
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_2,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_3
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_3,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_4
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_4,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_5
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_5,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_6
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_6,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_7
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_7,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_8

									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_8,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_9
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_9,
										max: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_10
									},
									{
										min: dynamic_gradient.place_count.no_cbh_but_competitors_place_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							others_only_place_count: {
								backets: [
									dynamic_gradient.place_count.others_only_place_count.threshold_2  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_3  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_4  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_5  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_6  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_7  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_8  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_9  + 0.5,
									dynamic_gradient.place_count.others_only_place_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_2
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_2,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_3
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_3,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_4
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_4,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_5
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_5,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_6
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_6,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_7
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_7,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_8

									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_8,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_9
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_9,
										max: dynamic_gradient.place_count.others_only_place_count.threshold_10
									},
									{
										min: dynamic_gradient.place_count.others_only_place_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
						},
						receipts_count: {
							cbh_and_competitors_receipts_count: {
								backets: [
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_2  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_3  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_4  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_5  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_6  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_7  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_8  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_9  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_2
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_2,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_3
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_3,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_4
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_4,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_5
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_5,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_6
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_6,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_7
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_7,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_8

									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_8,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_9
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_9,
										max: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_10
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_competitors_receipts_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							cbh_and_no_competitors_receipts_count: {
								backets: [
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_2  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_3  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_4  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_5  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_6  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_7  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_8  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_9  + 0.5,
									dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_2
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_2,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_3
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_3,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_4
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_4,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_5
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_5,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_6
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_6,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_7
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_7,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_8

									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_8,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_9
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_9,
										max: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_10
									},
									{
										min: dynamic_gradient.receipts_count.cbh_and_no_competitors_receipts_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							no_cbh_but_competitors_receipts_count: {
								backets: [
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_2  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_3  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_4  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_5  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_6  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_7  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_8  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_9  + 0.5,
									dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_2
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_2,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_3
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_3,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_4
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_4,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_5
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_5,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_6
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_6,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_7
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_7,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_8

									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_8,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_9
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_9,
										max: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_10
									},
									{
										min: dynamic_gradient.receipts_count.no_cbh_but_competitors_receipts_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
							others_only_receipts_count: {
								backets: [
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_2  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_3  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_4  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_5  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_6  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_7  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_8  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_9  + 0.5,
									dynamic_gradient.receipts_count.others_only_receipts_count.threshold_10  + 0.5
								],
								gradient: [
									{
										min: 0,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_2
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_2,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_3
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_3,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_4
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_4,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_5
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_5,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_6
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_6,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_7
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_7,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_8

									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_8,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_9
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_9,
										max: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_10
									},
									{
										min: dynamic_gradient.receipts_count.others_only_receipts_count.threshold_10,
										max: 'infinity'
									}
								],
								colors: new_colors,
							},
						},


					}
				};

			} else {
				tempLegendTypes = state.almaty.legends_types;
			}
			// const newAllDates = Object.fromEntries(Object.entries(payload.allDates).map(([key,value]) => {
			//   return[
			//     key,
			//     value.filter(item => item.date >= '2020-01-01')
			//   ]
			// }))
			return {
				...state,
				almaty: {
					...state.almaty,
					legends_types: {
						...tempLegendTypes,
					},
					//FIXME PUT START DATA
					start_data: {
						...payload.apiResult,
						dynamic_gradient: {
							...tempLegendTypes
						}
					},
					filtered_widget_data: {
						avg_receipt_amt: {
							cbh_and_competitors_avg_receipt_amt: payload.apiResult.widget_data.avg_receipt_amt[0].cbh_type.cbh_and_competitors_avg_receipt_amt,
							cbh_and_no_competitors_avg_receipt_amt: payload.apiResult.widget_data.avg_receipt_amt[0].cbh_type.cbh_and_no_competitors_avg_receipt_amt,
							no_cbh_but_competitors_avg_receipt_amt: payload.apiResult.widget_data.avg_receipt_amt[0].cbh_type.no_cbh_but_competitors_avg_receipt_amt,
							others_only_avg_receipt_amt: payload.apiResult.widget_data.avg_receipt_amt[0].cbh_type.others_only_avg_receipt_amt,
						},
						category_sales_amt: {
							cbh_and_competitors_category_sales_amt: payload.apiResult.widget_data.category_sales_amt[0].cbh_type.cbh_and_competitors_category_sales_amt,
							cbh_and_no_competitors_category_sales_amt: payload.apiResult.widget_data.category_sales_amt[0].cbh_type.cbh_and_no_competitors_category_sales_amt,
							no_cbh_but_competitors_category_sales_amt: payload.apiResult.widget_data.category_sales_amt[0].cbh_type.no_cbh_but_competitors_category_sales_amt,
							others_only_category_sales_amt: payload.apiResult.widget_data.category_sales_amt[0].cbh_type.others_only_category_sales_amt,
						},
						place_count: {
							cbh_and_competitors_place_count: payload.apiResult.widget_data.place_count[0].cbh_type.cbh_and_competitors_place_count,
							cbh_and_no_competitors_place_count: payload.apiResult.widget_data.place_count[0].cbh_type.cbh_and_no_competitors_place_count,
							no_cbh_but_competitors_place_count: payload.apiResult.widget_data.place_count[0].cbh_type.no_cbh_but_competitors_place_count,
							others_only_place_count: payload.apiResult.widget_data.place_count[0].cbh_type.others_only_place_count,
						},
						receipts_count: {
							cbh_and_competitors_receipts_count: payload.apiResult.widget_data.receipts_count[0].cbh_type.cbh_and_competitors_receipts_count,
							cbh_and_no_competitors_receipts_count: payload.apiResult.widget_data.receipts_count[0].cbh_type.cbh_and_no_competitors_receipts_count,
							no_cbh_but_competitors_receipts_count: payload.apiResult.widget_data.receipts_count[0].cbh_type.no_cbh_but_competitors_receipts_count,
							others_only_receipts_count: payload.apiResult.widget_data.receipts_count[0].cbh_type.others_only_receipts_count,
						}
					},
					zids: {}
				}
			};
		},
		//FIXME OLD LOGIC BY THIS RUDECER
		putStartData(state, { payload }) {
			//FIXME NEW API
			return {
				...state,
				almaty: {
					...state.almaty,
					start_data: {
						...payload.apiResult,
						allDates: payload.allDates,
						city: {
							district_area: payload.apiResult.district_area.district_area_city,
							districts: payload.apiResult.districts.districts_city,
							hours: payload.apiResult.hours.hours_city
						},
						region: {
							district_area: payload.apiResult.district_area.district_area_region,
							districts: payload.apiResult.districts.districts_region,
							hours: payload.apiResult.hours.hours_region
						},
						selfiso: {
							district_area: payload.apiResult.district_area.district_area_selfiso,
							districts: payload.apiResult.districts.districts_selfiso,
							hours: payload.apiResult.hours.hours_selfiso
						}
					},
					layers: {
						...state.almaty.layers,
						region: {
							...state.almaty.layers.region,
							zids: payload.apiResult.zids.features,
							dates: payload.allDates.global_dates_region,
							hours: payload.apiResult.hours.hours_region,
							districts: payload.apiResult.districts.districts_region,
							district_area: payload.apiResult.district_area.district_area_region
						},
						city: {
							...state.almaty.layers.city,
							zids: payload.apiResult.zids.features,
							dates: payload.allDates.global_dates_city,
							hours: payload.apiResult.hours.hours_city,
							districts: payload.apiResult.districts.districts_city,
							district_area: payload.apiResult.district_area.district_area_city
						},
						population: {
							...state.almaty.layers.population,
							zids: payload.apiResult.zids.features,
							districts: payload.apiResult.districts.districts_population,
							district_area: payload.apiResult.district_area.district_area_population
						},
						selfiso: {
							...state.almaty.layers.selfiso,
							zids: payload.apiResult.zids.features,
							dates: payload.allDates.global_dates_selfiso,
							hours: payload.apiResult.hours.hours_selfiso,
							districts: payload.apiResult.districts.districts_selfiso,
							district_area: payload.apiResult.district_area.district_area_selfiso
						}
					},
					zids: {}
				}
			};

			//FIXME OLD API
			// return {
			//   ...state,
			//   start_data: payload,
			//   layers: {
			//     ...state.layers,
			//     region: {
			//       ...state.layers.region,
			//       zids: payload.zidsRegion.zids.features,
			//       dates: payload.allDates.global_dates_region,
			//       hours: payload.region.hours_region,
			//       districts: payload.region.districts_region,
			//       district_area: payload.region.district_area_region
			//     },
			//     city: {
			//       ...state.layers.city,
			//       zids: payload.dynamicData.zids_city.features,
			//       dates: payload.allDates.global_dates_city,
			//       hours: payload.city.hours,
			//       districts: payload.city.districts,
			//       district_area: payload.city.district_area
			//     },
			//     population: {
			//       ...state.layers.population,
			//       zids: payload.population.zids.features,
			//       districts: payload.population.districts,
			//       district_area: payload.population.district_area
			//     },
			//     selfiso: {
			//       ...state.layers.selfiso,
			//       zids: payload.zidsSelfiso.zids.features,
			//       dates: payload.allDates.global_dates_selfiso,
			//       hours: payload.selfiso.hours,
			//       districts: payload.selfiso.districts,
			//       district_area: payload.selfiso.district_area
			//     }
			//   },
			//   zids: {
			//
			//   }
			// }
		},

		setRightTopNavActiveTab(state, { payload }) {
			// if(payload == 0){
			//   return {
			//     ...state,
			//     right_top_nav_active_tab: payload,
			//     zid_data: {
			//       get_okeds_micro: [],
			//       get_okeds_macro: [],
			//       zid_data_12_weeks: [],
			//       zid_data_12_weeks_started: [],
			//       oked_micro: ''
			//     },
			//     zid_dots: [],
			//     checks_info: {
			//       checks_hours: [],
			//       checks_week: [],
			//       goods_rate: [],
			//       goods_szpt_rate: []
			//     },
			//     dot_info: {
			//       address: '',
			//       bin: '',
			//       oked_micro: '',
			//       qty: '',
			//       amt_check: '',
			//     }
			//   }
			// } else {
			return {
				...state,
				almaty: {
					...state.almaty,
					right_top_nav_active_tab: payload
				}
			};
		},

		setDotsInfo(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					dot_info: payload
				}
			};
		},

		sectorRightBarActiveSemiTab(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					SectorActiveSemiTab: payload
				}
			};
		},

		zidClickForActiveTab(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					zid_click_for_active_tab: payload
				}
			};
		},

		setupFlagForActiveTab(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					flagForActiveTab: payload
				}
			};
		},

		setMapName(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					map_name: mapName()
				}
			};
		},

		setZidDots(state, { payload }) {
			let zid_dots = state.almaty.filtred_dots.filter(item => item.properties.zid_id == payload.zid);
			if (payload.target && payload.level_2.length > 0) {
				zid_dots = zid_dots.filter(item => item.properties.level_2 == payload.level_2);
			}
			return {
				...state,
				almaty: {
					...state.almaty,
					zid_dots,
					zid_data: {
						...state.almaty.zid_data,
						oked_micro: payload.target && payload.target.length > 0 ? payload.level_2 : ''
					},
					current_zid: payload
				}
			};
		},

		refreshZidFilters(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					zid_data: {
						get_okeds_micro: [],
						get_okeds_macro: [],
						zid_data_12_weeks: [],
						zid_data_12_weeks_started: [],
						oked_micro: ''
					},
					zid_dots: []
				}
			};
		},

		setZidData(state, { payload }) {
			if (payload.payload.target) {
				return {
					...state,
					almaty: {
						...state.almaty,
						zid_data: {
							...state.almaty.zid_data,
							get_groups: payload.response.get_groups,
							get_groups_bin: payload.response.get_groups_bin,
							zid_data_12_weeks: payload.response.zid_data_12_weeks,
						}
					}
				};
			} else {
				return {
					...state,
					almaty: {
						...state.almaty,
						zid_data: {
							...state.almaty.zid_data,
							get_okeds_micro: payload.response.get_okeds_micro,
							get_okeds_macro: payload.response.get_okeds_macro,
							get_groups: payload.response.get_groups,
							get_groups_bin: payload.response.get_groups_bin,
							zid_data_12_weeks: payload.response.zid_data_12_weeks,
							zid_data_12_weeks_started: payload.response.zid_data_12_weeks
						},
					}
				};
			}
		},

		refreshActiveFilters(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						districts: [],
						oked_macro: '',
						oked_micro: '',
						groups: '',
						groups_bin: '',
						gradient: {
							min: '',
							max: ''
						}
					},
					parsed_get_dates: state.almaty.parsed_get_dates_start
				}
			};
		},

		setParsedGetDates(state, { payload: count }) {
			const get_dates_catch_bug = count.get_dates;
			const active_filters = state.almaty.active_filters;
			if (state.almaty.parsed_get_dates_start.length == 0) {
				return {
					...state,
					almaty: {
						...state.almaty,
						parsed_get_dates_start: get_dates_catch_bug,
						parsed_get_dates: get_dates_catch_bug
					}
				};
			} else {
				if (
					ofd_map_filters(active_filters)
				) {
					return {
						...state,
						almaty: {
							...state.almaty,
							parsed_get_dates: get_dates_catch_bug,
						}
					};
				} else {
					return {
						...state,
						almaty: {
							...state.almaty,
							parsed_get_dates: state.almaty.parsed_get_dates_start
						}
					};
				}
			}
		},

		// saveStartData(state, { payload: count, dates: dates }){
		//   const nonFilteredDots = {
		//     europharma_dots: count.europharma_dots,
		//     gazprom_dots: count.gazprom_dots,
		//     helios_dots: count.helios_dots,
		//     magnum_dots: count.magnum_dots,
		//     petrol_dots: count.petrol_dots,
		//   }
		//   return {
		//     ...state,
		//     start_data: count,
		//     current_date: state.filtred_dots.length == 0 ?
		//       count.get_dates[count.get_dates.length - 1].date_ :
		//       dates.dates.from
		//     ,
		//     non_filtered_dots: nonFilteredDots
		//   };
		// },

		changeActiveFilters(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					active_filters: {
						...state.almaty.active_filters,
						[payload.name]: payload.value,
					}
				}
			};
		},

		calculateFilteredZidsFilters(state, { payload }) {
			let data_zids = []
			let sublayer = state.almaty.active_filters.data

			let filtered_zids = state.almaty.start_data.zids.features
			if (state.almaty.filtered_zids_data.length > 0) {
				filtered_zids = state.almaty.filtered_zids_data
			}
			if (state.almaty.active_filters.districts.length > 0) {
				if (state.almaty.active_filters.districts.length === 1) {
					filtered_zids = state.almaty.start_data.zids.features.filter(
						item => item.properties.iddistrict == state.almaty.active_filters.districts
					)
				} else {
					state.almaty.start_data.zids.features.map(item => {
						state.almaty.active_filters.districts.map(el => {
							if (item.properties.iddistrict == el) {
								return filtered_zids.push(item)
							}
						})
					})
				}
			}

			if (state.almaty.active_filters.gradient.min != '' && state.almaty.active_filters.gradient.max != '') {
				if (filtered_zids.length > 0) {
					if (state.almaty.active_layer === 'place_count') {
						filtered_zids = filtered_zids.filter(
							item => item.properties[sublayer] > state.almaty.active_filters.gradient.min &&
								item.properties[sublayer] <= state.almaty.active_filters.gradient.max
						)
					} else {
						filtered_zids = filtered_zids.filter(
							item => item.properties[sublayer] > state.almaty.active_filters.gradient.min &&
								item.properties[sublayer] <= state.almaty.active_filters.gradient.max
						)
					}

				}
			}

			if (filtered_zids.length > 0) {
				data_zids = filtered_zids
			}

			if (state.almaty.active_filters.districts == '' && state.almaty.active_filters.gradient.min == '') {
				data_zids = state.almaty.start_data.zids.features
			}

			return {
				...state,
				almaty: {
					...state.almaty,
					filtered_zids_data: data_zids
				}
			};
		},

		calculateWidgetDataFilters(state, { payload }) {
			let layer = state.almaty.active_layer
			// let filteredZidsData = state.almaty.filtered_zids_data


			let filtered_zids = []
			if (state.almaty.filtered_zids_data.length > 0) {
				filtered_zids = state.almaty.filtered_zids_data
			} else {
				filtered_zids = state.almaty.start_data.zids.features
			}
			let forPayload = {}


			let avg_receipt_amt = {
				cbh_and_competitors_avg_receipt_amt: {},
				cbh_and_no_competitors_avg_receipt_amt: {},
				no_cbh_but_competitors_avg_receipt_amt: {},
				others_only_avg_receipt_amt: {},
			}

			let category_sales_amt = {
				cbh_and_competitors_category_sales_amt: {},
				cbh_and_no_competitors_category_sales_amt: {},
				no_cbh_but_competitors_category_sales_amt: {},
				others_only_category_sales_amt: {},
			}

			let place_count = {
				cbh_and_competitors_place_count: {},
				cbh_and_no_competitors_place_count: {},
				no_cbh_but_competitors_place_count: {},
				others_only_place_count: {},
			}

			let receipts_count = {
				cbh_and_competitors_receipts_count: {},
				cbh_and_no_competitors_receipts_count: {},
				no_cbh_but_competitors_receipts_count: {},
				others_only_receipts_count: {},

			}

			if (state.almaty.active_layer === 'avg_receipt_amt') {
				avg_receipt_amt.cbh_and_competitors_avg_receipt_amt = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_competitors_avg_receipt_amt, 0)
				avg_receipt_amt.cbh_and_no_competitors_avg_receipt_amt = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_no_competitors_avg_receipt_amt, 0)
				avg_receipt_amt.no_cbh_but_competitors_avg_receipt_amt = filtered_zids.reduce((acc, num) => acc + num.properties.no_cbh_but_competitors_avg_receipt_amt, 0)
				avg_receipt_amt.others_only_avg_receipt_amt = filtered_zids.reduce((acc, num) => acc + num.properties.others_only_avg_receipt_amt, 0)
				forPayload = avg_receipt_amt
			}

			if (state.almaty.active_layer === 'category_sales_amt') {
				category_sales_amt.cbh_and_competitors_category_sales_amt = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_competitors_category_sales_amt, 0)
				category_sales_amt.cbh_and_no_competitors_category_sales_amt = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_no_competitors_category_sales_amt, 0)
				category_sales_amt.no_cbh_but_competitors_category_sales_amt = filtered_zids.reduce((acc, num) => acc + num.properties.no_cbh_but_competitors_category_sales_amt, 0)
				category_sales_amt.others_only_category_sales_amt = filtered_zids.reduce((acc, num) => acc + num.properties.others_only_category_sales_amt, 0)
				forPayload = category_sales_amt
			}

			if (state.almaty.active_layer === 'place_count') {
				place_count.cbh_and_competitors_place_count = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_competitors_place_count, 0)
				place_count.cbh_and_no_competitors_place_count = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_no_competitors_place_count, 0)
				place_count.no_cbh_but_competitors_place_count = filtered_zids.reduce((acc, num) => acc + num.properties.no_cbh_but_competitors_place_count, 0)
				place_count.others_only_place_count = filtered_zids.reduce((acc, num) => acc + num.properties.others_only_place_count, 0)
				forPayload = place_count
			}

			if (state.almaty.active_layer === 'receipts_count') {
				receipts_count.cbh_and_competitors_receipts_count = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_competitors_receipts_count, 0)
				receipts_count.cbh_and_no_competitors_receipts_count = filtered_zids.reduce((acc, num) => acc + num.properties.cbh_and_no_competitors_receipts_count, 0)
				receipts_count.no_cbh_but_competitors_receipts_count = filtered_zids.reduce((acc, num) => acc + num.properties.no_cbh_but_competitors_receipts_count, 0)
				receipts_count.others_only_receipts_count = filtered_zids.reduce((acc, num) => acc + num.properties.others_only_receipts_count, 0)
				forPayload = receipts_count
			}

			console.log('forPayload', forPayload)

			return {
				...state,
				almaty: {
					...state.almaty,
					filtered_widget_data: {
						...state.almaty.filtered_widget_data,
						[layer]: forPayload

					}
				}
			};
		},

		changeCalendars(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					dates: {
						...state.almaty.dates,
						[payload.type]: {
							...state.almaty.dates[payload.type],
							[payload.name]: payload.value,
							[payload.name]: {
								...state.almaty.dates[payload.type][payload.name],
								[payload.range]: payload.value
							}

							//есть from , нет to

							// state.dates[payload.type][payload.name]

							// [payload.name]: {
							// ...state.calendar.[payload.type].[payload.name],
							// [payload.range]: payload.value
							// }
						}
					}
				}
			};
		},

		changeCurrentDate(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					current_date: payload.start
				}
			};
		},

		hideRightBarLoader(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					rightBarLoader: false,
				}
			};
		},

		showRightBarLoader(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					rightBarLoader: true,
				}
			};
		},

		setFiltredZidsStartData(state) {
			return {
				...state,
				almaty: {
					...state.almaty,
					filtred_zids: state.almaty.start_data.zids.features
				}
			};
		},

		setFiltredWidgetsStartData(state) {
			if (state.almaty.startWidgetsData == undefined) {
				const preparedStartDataDistricts = state.almaty.start_data.districts.map(item => {
					return {
						district: Object.entries(item)[0][0],
						count: Object.entries(item)[0][1]
					};
				});

				const preparedStartDataOkedMicro = state.almaty.start_data.get_okeds_micro.map(item => {
					return {
						microOked: Object.entries(item)[0][0],
						count: Object.entries(item)[0][1]
					};
				});

				const preparedStartDataGroups = state.almaty.start_data.get_groups.map(item => {
					return {
						groups: Object.entries(item)[0][1],
						count: Object.entries(item)[1][1]
					};
				});

				const preparedStartDataGroupsAmt = state.almaty.start_data.get_groups.map(item => {
					return {
						groupsAmt: Object.entries(item)[0][1],
						count: Object.entries(item)[3][1]
					};
				});

				const preparedStartDataGroupsBin = state.almaty.start_data.get_groups_bin.map(item => {
					return {
						groupsBin: Object.entries(item)[0][1],
						count: Object.entries(item)[1][1]
					};
				});

				const preparedStartDataGroupsBinAmt = state.almaty.start_data.get_groups_bin.map(item => {
					return {
						groupsBinAmt: Object.entries(item)[0][1],
						count: Object.entries(item)[3][1]
					};
				});

				return {
					...state,
					almaty: {
						...state.almaty,
						startWidgetsData: {
							preparedStartDataDistricts: preparedStartDataDistricts,
							preparedStartDataOkedMicro: preparedStartDataOkedMicro,
							preparedStartDataGroups: preparedStartDataGroups,
							preparedStartDataGroupsAmt: preparedStartDataGroupsAmt,
							preparedStartDataGroupsBin: preparedStartDataGroupsBin,
							preparedStartDataGroupsBinAmt: preparedStartDataGroupsBinAmt,
						},
						filtred_widget_data: {
							...state.almaty.filtred_widget_data,
							districts: preparedStartDataDistricts,
							microOked: preparedStartDataOkedMicro,
							groups: preparedStartDataGroups,
							groupsAmt: preparedStartDataGroupsAmt,
							groupsBin: preparedStartDataGroupsBin,
							groupsBinAmt: preparedStartDataGroupsBinAmt
						}
					}
				};
			} else {
				const {
					preparedStartDataDistricts,
					preparedStartDataOkedMicro,
					preparedStartDataGroups,
					preparedStartDataGroupsAmt,
					preparedStartDataGroupsBin,
					preparedStartDataGroupsBinAmt
				} = state.almaty.startWidgetsData;
				return {
					...state,
					almaty: {
						...state.almaty,
						filtred_widget_data: {
							...state.almaty.filtred_widget_data,
							districts: preparedStartDataDistricts,
							microOked: preparedStartDataOkedMicro,
							groups: preparedStartDataGroups,
							groupsAmt: preparedStartDataGroupsAmt,
							groupsBin: preparedStartDataGroupsBin,
							groupsBinAmt: preparedStartDataGroupsBinAmt
						}
					}
				};
			}
		},

		widgetsWorkerSetStoreData(state, { payload }) {
			const {
				summary,
				filtred_widget_data
			} = payload.payload;
			const {
				districts,
				microOked,
				groups,
				groupsAmt,
				groupsBin,
				groupsBinAmt
			} = filtred_widget_data;

			const result = {
				...state,
				almaty: {
					...state.almaty,
					statistic: summary.stats,
					filtred_widget_data: {
						...state.almaty.filtred_widget_data,
						districts: (districts || []),
						microOked: (microOked || []),
						groups: (groups || []),
						groupsAmt: (groupsAmt || []),
						groupsBin: (groupsBin || []),
						groupsBinAmt: (groupsBinAmt || [])
					}
				}
			};
			return result;
		},

		zidsMobileWorkerSetStoreData(state, { payload }) {
			if (payload) {
				let calcObject = {
					age: {
						one: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						two: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						three: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						four: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						five:{
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						zero:{
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
					},
					gender: {
						one: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						two: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						zero:{
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
					},
					income: {
						one: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						two: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						three: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						four: {
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
						zero:{
							qnt_max: 0,
							qnt_out: 0,
							qnt_traf: 0,
						},
					},
				}
				const { filtred_zids } = payload.payload;
				let zid_ids = filtred_zids.map(item => item.properties.zid_id)

				//FIXME nik
				// SOC DEM CALCILATION FOR FUTURE
				// state.almaty.start_data.new_ds_data_test.map(
				//     item => {
				//       if(zid_ids.includes(item.zid_id)){
				//         return item
				//       }
				//     })
				// let socDemFiltredObjects = state.almaty.start_data.new_ds_data_test.filter(
				//     item => {
				//       if(zid_ids.includes(item.zid_id + '')){
				//         // numbersDict
				//         calcObject = {
				//           ...calcObject,
				//           age: {
				//             ...calcObject.age,
				//             [numbersDict[item.age_city]]: {
				//               qnt_max: calcObject.age[numbersDict[item.age_city]].qnt_max + item.qnt_max,
				//               qnt_out: calcObject.age[numbersDict[item.age_city]].qnt_out + item.qnt_out,
				//               qnt_traf: calcObject.age[numbersDict[item.age_city]].qnt_traf + item.qnt_traf,
				//             },
				//           },
				//           gender: {
				//             ...calcObject.gender,
				//             [numbersDict[item.gender_city]]: {
				//               qnt_max: calcObject.gender[numbersDict[item.gender_city]].qnt_max + item.qnt_max,
				//               qnt_out: calcObject.gender[numbersDict[item.gender_city]].qnt_out + item.qnt_out,
				//               qnt_traf: calcObject.gender[numbersDict[item.gender_city]].qnt_traf + item.qnt_traf,
				//             },
				//           },
				//           income: {
				//             ...calcObject.income,
				//             [numbersDict[item.income_city]]: {
				//               qnt_max: calcObject.income[numbersDict[item.income_city]].qnt_max + item.qnt_max,
				//               qnt_out: calcObject.income[numbersDict[item.income_city]].qnt_out + item.qnt_out,
				//               qnt_traf: calcObject.income[numbersDict[item.income_city]].qnt_traf + item.qnt_traf,
				//             },
				//           },
				//         }
				//         // debugger
				//         return true
				//       } else {
				//         return false
				//       }
				//     }
				//
				// )
				// console.log('zidsMobileWorkerSetStoreData:: socDemFiltredObjects - :', socDemFiltredObjects)
				console.log('zidsMobileWorkerSetStoreData:: filtred_zids - :', filtred_zids)
				console.log('zidsMobileWorkerSetStoreData:: zid_ids - :', zid_ids)
				console.log('zidsMobileWorkerSetStoreData:: calcObject - :', calcObject)

				let tempAnomalyZids = [];
				if (state.almaty.active_layer === 'city') {
					tempAnomalyZids = filtred_zids.filter(item => item.properties.city_is_anormal ? item.properties.city_is_anormal !== 0 : false);
				} else if (state.almaty.active_layer === 'region') {
					tempAnomalyZids = filtred_zids.filter(item => item.properties.region_is_anormal ? item.properties.region_is_anormal !== 0 : false);
				}
				return {
					...state,
					almaty: {
						...state.almaty,
						filtred_zids: filtred_zids.length > 0 ? filtred_zids : state.almaty.layers[state.almaty.active_layer].zids,
						// anomaly_zids: filtred_zids.filter(item => )
						anomaly_zids: tempAnomalyZids,
					}
				};
			} else {
				let tempAnomalyZids = [];
				if (state.almaty.active_layer === 'city') {
					tempAnomalyZids = state.almaty.layers[state.almaty.active_layer].zids.filter(item => item.properties.city_is_anormal ? item.properties.city_is_anormal !== 0 : false);
				} else if (state.almaty.active_layer === 'region') {
					tempAnomalyZids = state.almaty.layers[state.almaty.active_layer].zids.filter(item => item.properties.region_is_anormal ? item.properties.region_is_anormal !== 0 : false);
				}
				return {
					...state,
					almaty: {
						...state.almaty,
						filtred_zids: state.almaty.layers[state.almaty.active_layer].zids,
						anomaly_zids: tempAnomalyZids,
					}
				};
			}
		},

		// dotsWorkerSetStoreData(state, {payload}) {
		//   const { filtred_dots } = payload.payload
		//   const result = {
		//     ...state,
		//     filtred_dots: filtred_dots.length > 0 ? filtred_dots : state.start_data.dots.features,
		//   }
		//   return result
		// },
		setSelectionMapLayer(state, { payload }) {

			const layers = {
				place_count: 'place_count',
				avg_receipt_amt: 'avg_receipt_amt',
				category_sales_amt: 'category_sales_amt',
				receipts_count: 'receipts_count',
			};
			let sublayer = {
				place_count: 'cbh_and_competitors_place_count',
				avg_receipt_amt: 'cbh_and_competitors_avg_receipt_amt',
				category_sales_amt: 'cbh_and_competitors_category_sales_amt',
				receipts_count: 'cbh_and_competitors_receipts_count',
				// receipts_count: 'others_only_receipts_count',
			}
			if (state.almaty.active_filters.gradient.max.toString().length > 0) {
				// FIXME GRADIENT?
				// let index = state.almaty.legends_types.mobile[layers[state.almaty.active_layer]].gradient.findIndex(item => item.max == state.almaty.active_filters.gradient.max);
				return {
					...state,
					almaty: {
						...state.almaty,
						active_layer: payload,
						active_sublayer: sublayer[payload],
						aside: payload === 'deficit' || payload === 'proficit' ? true : false,
						active_filters: {
							...state.almaty.active_filters,
							gradient: state.almaty.legends_types.mobile[layers[payload]].gradient[index],
						},
					},
				};
			} else {
				return {
					...state,
					almaty: {
						...state.almaty,
						active_layer: payload,
						active_sublayer: sublayer[payload],
						aside: payload === 'deficit' || payload === 'proficit' ? true : false,
					}
				};
			}

			//FIXME Old logic
			// if(state.almaty.active_filters.gradient.max.toString().length > 0){
			//   if(payload == 'population' && payload !== state.almaty.active_layer){
			//     let index = state.almaty.legends_types.mobile.almaty.gradient.findIndex(item => item.max == state.almaty.active_filters.gradient.max)
			//     return {
			//       ...state,
			//       .almaty: {
			//         ...state.almaty,
			//         active_layer: payload,
			//         active_filters: {
			//           ...state.almaty.active_filters,
			//           gradient: state.almaty.legends_types.mobile.almaty_population.gradient[index]
			//         }
			//       }
			//     }
			//   } else if(payload !== 'population' && state.almaty.active_layer == 'population'){
			//     let index = state.legends_types.mobile.almaty_population.gradient.findIndex(item => item.max == state.active_filters.gradient.max)
			//     return {
			//       ...state,
			//       .almaty: {
			//         ...state.almaty,
			//         active_layer: payload,
			//         active_filters: {
			//           ...state.almaty.active_filters,
			//           gradient: state.almaty.legends_types.mobile.almaty.gradient[index]
			//         }
			//       }
			//     }
			//   }
			// }
			// return {
			//   ...state,
			//   .almaty: {
			//     ...state.almaty,
			//     active_layer: payload
			//   }
			// }
		},
		historySwitchReducer(state, { payload }) {
			return {
				...state,
				almaty:{
					...state.almaty,
					isHistory: payload.checked
				}
			}
		},
		changeHistoryCalendars(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						dates: {
							...state.almaty.history_filters.dates,
							[payload.type]: {
								...state.almaty.history_filters.dates[payload.type],
								[payload.name]: payload.value,
								[payload.name]: {
									...state.almaty.history_filters.dates[payload.type][payload.name],
									[payload.range]: payload.value
								}
							}
						}
					}
				}
			};
		},
		historySaveDate(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						dates: {
							...state.almaty.history_filters.dates,
							[payload.layer]: {
								...state.almaty.history_filters.dates[payload.layer],
								current_date: {
									...state.almaty.history_filters.dates[payload.layer].current_date,
									day: {
										from: payload.dates.from,
									},
								}
							}
						}
					}
				}
			}
		},
		putHistoryHours(state, { payload }){
			if(payload.layer === 'activity'){
				return {
					...state,
					almaty: {
						...state.almaty,
						history_filters: {
							...state.almaty.history_filters,
							isHoursPending: false,
							hours: {
								...state.almaty.history_filters.hours,
								[payload.layer]: payload.hours
							}
						}
					}
				}
			}
		},

		historySaveHour(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						dates: {
							...state.almaty.history_filters.dates,
							[payload.layer]: {
								...state.almaty.history_filters.dates[payload.layer],
								current_date: {
									...state.almaty.history_filters.dates[payload.layer].current_date,
									hour: payload.hour,
								}
							}
						}
					}
				}
			}
		},
		toggleHistorySubmitted(state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					isHistorySubmitted: payload,
				}
			}
		},
		clearCalendar(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						weekDays: [],
						dates: {
							...state.almaty.history_filters.dates,
							activity: {
								...state.almaty.history_filters.dates.activity,
								calendar_selected: {
									from: '',
									to: '',
								},
								current_date: {
									day: state.almaty.history_filters.dates.activity.available_dates.to,
									hour: 13,
								}
							}
						}
					}
				}
			}
		},
		setHoursPending(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						isHoursPending: true,
					}
				}
			}
		},
		putHistoryZids(state, { payload }){
			const sortFunc = (a,b) => {
				if(a.date > b.date){
					return 1
				}
				if(a.date < b.date){
					return -1
				}
				return 0
			}
			const layers = ['city', 'region', 'selfiso']
			let history_dates = Object.fromEntries(layers.map(item => {
				return [
					item,
					payload.zids_data.map(elem => {
						return {
							date: elem.date,
							value: elem[`${item}_avg_`]
						}
					}).sort(sortFunc)
				]
			}))
			history_dates['population'] = []
			return {
				...state,
				almaty: {
					...state.almaty,
					// history_zids: [
					//   ...payload.zids
					// ],
					isHistoryError: false,
					history_dates,
					historyFirstOpen: false,
					history_filters:{
						...state.almaty.history_filters,
						isHistoryDatesPending: false,
					}
				}
			}
		},
		reverseHistory(state, { payload }){
			const dates_to = state.almaty.history_filters.dates.activity.available_dates.to
			const dates_from = moment(dates_to).subtract(3, 'months').format('YYYY-MM-DD')
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						isHistoryDatesPending: false,
						weekDays: [],
						dates: {
							...state.almaty.history_filters.dates,
							activity: {
								...state.almaty.history_filters.dates.activity,
								calendar_selected: {
									from: dates_from,
									to: dates_to,
								},
								current_date: {
									day: state.almaty.history_filters.dates.activity.available_dates.to,
									hour: 13,
								}
							}
						}
					},
					history_zids: [],
					history_dates: {},
					isHistory: false,
					historyFirstOpen: true,
					isHistoryError: false,
				}
			}
		},
		setDatesPending( state, { payload }) {
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						isHistoryDatesPending: payload
					}
				}
			}
		},
		setHistoryError(state, { payload }){
			return {
				...state,
				almaty: {
					...state.almaty,
					isHistoryError: true,
				}
			}
		},
		putWeekDays(state, { payload }){
			const days = {
				0: 'Mon',
				1: 'Tue',
				2: 'Wed',
				3: 'Thu',
				4: 'Fri',
				5: 'Sat',
				6: 'Sun',
			}
			const engWeekDays = payload.days.map(item => days[item])
			return {
				...state,
				almaty: {
					...state.almaty,
					history_filters: {
						...state.almaty.history_filters,
						weekDays: [...engWeekDays],
					}
				}
			}
		}
	},
};

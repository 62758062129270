import {DatasetsListComponent} from "./components/DatasetsListComponent";
import {useEffect, useState} from "react";
import {datasetsActions} from "../../../models/datasetsModel/datasetsActions";
import {connect} from "dva";
import {DatasetsModal} from "./modals/DatasetsModal";
import {changeFormFieldsData} from "../../../Tools/changeFormFieldsData";
import {productsActions} from "../../../models/productsModel/productsActions";

const initialFields = [
	{
		name: 'name',
		value: ''
	},
	{
		name: 'description',
		value: ''
	},
	{
		name: 'path',
		value: ''
	},
	{
		name: 'product_id',
		value: []
	}
]


export const DatasetsConnector = (props) => {
	const {
		datasets,
		dispatch,
		products
	} = props

	const [createDatasetsModal, setCreateDatasetsModal] = useState(false)
	const [updateDatasetsModal, setUpdateDatasetsModal] = useState(false)
	const [fields, setFields] = useState(initialFields);

	useEffect(() => {
		document.title = 'Admin - Datasets'
		dispatch(datasetsActions.getDatasetsFetch());
		dispatch(productsActions.getProductsFetch())
	}, [])

	const onCancel = () => {
		setFields(initialFields)
		setCreateDatasetsModal(false)
		setUpdateDatasetsModal(false)
	}

	const onDeleteDataset = (id) => {
		dispatch(datasetsActions.deleteDatasetsFetch(id))
	}

	const onOpenModal = (type, value) => {
		if (type === "create") {
			setCreateDatasetsModal(true)
		} else if (type === "update") {
			setFields(changeFormFieldsData(fields, value))
			setUpdateDatasetsModal(value)
		}
	}

	const onSubmit = (value) => {
		if (createDatasetsModal) {
			dispatch(datasetsActions.createDatasetsFetch(value))
		} else if (updateDatasetsModal) {
			dispatch(datasetsActions.changeDatasetsFetch(value, updateDatasetsModal.id))
		}
		onCancel()
	}

	return (
		<>
			<DatasetsModal
				createDatasetsModal={createDatasetsModal}
				updateDatasetsModal={updateDatasetsModal}
				onSubmit={onSubmit}
				fields={fields}
				onCancel={onCancel}
				products={products}
				datasets={datasets.data}
			/>
			<DatasetsListComponent
				onOpenModal={onOpenModal}
				onDelete={onDeleteDataset}
				datasets={datasets.data}
				CRUDStatus={datasets.data.CRUDStatus}
			/>
		</>
	)
}

function mapStateToProps(state) {
	return {
		datasets: state.datasets,
		products: state.products.data
	}
}

export const DatasetsContainer = connect(mapStateToProps)(DatasetsConnector);

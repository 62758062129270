import {useContext, useEffect, useState} from "react";
import {productsActions} from "../../../models/productsModel/productsActions";
import {connect} from "dva";
import {UserInterfaceProductsListComponent} from "./components/UserInterfaceProductsListComponent";
import {UserInterfaceReportsListComponent} from "./components/UserInterfaceReportsListComponent";
import {useNavigate} from "react-router-dom";
import {datasetsActions} from "../../../models/datasetsModel/datasetsActions";
import locationContext from "../../../Tools/context";
import {Tabs} from "antd";
import Layout from "antd/es/layout/layout";
import {UserInterfaceHelpListComponent} from "./components/UserInterfaceHelpListComponent";


const { TabPane } = Tabs;

export const ProductsConnector = (props) => {
	const {
		products,
		dispatch,
	} = props

	const navigate = useNavigate();
	const {t} = useContext(locationContext)

	const [filteredData, setFilteredData] = useState([]);
	const [filteredData2, setFilteredData2] = useState([]);
	let reports = []
	let reports_obj = []
	let products_no_dataset = []

	if (products.viewData) {
		if (products.viewData.length > 0) {

			reports = props.products.viewData.filter(item => item.type == 'files' && item.datasets.length > 0)
			reports.map(item => {

				if (item.datasets.length >= 1) {
					item.datasets.map(el => {
						return reports_obj.push(el)
					})
					return reports_obj
				} else {

				}
			})

			products_no_dataset = products.viewData.filter(item => item.type != 'files')

		}
	}




	useEffect(() => {
		document.title = 'My products'
		dispatch(productsActions.getProductsForCurrentUserFetch());
	}, [])

	const exportDatasetFile = (id) => {
		const randomMath = Math.floor(Math.random() * 1000000000)
		dispatch(datasetsActions.getExportDatasetFileFetch(id, randomMath))
	}

	const onSearch = (value) => {
		const rawData = products?.viewData?.filter(item => item.type != 'files')
		const result = rawData?.map(item => {
			if(item.name.toLowerCase().includes(value.toLowerCase()) || item.client_name?.toLowerCase().includes(value.toLowerCase())) {
				return item
			} else
				return ''
			}).filter(notEmpty => notEmpty !== '')

		setFilteredData(result)
	};
// FIXmE need 1 func
	const onSearch2 = (value) => {
		// const result = reports_obj
		const rawData = reports_obj.map(item => {
			if(item.name.toLowerCase().includes(value.toLowerCase()) || item.client_name?.toLowerCase().includes(value.toLowerCase())) {
				return item
			} else
				return ''
		}).filter(notEmpty => notEmpty !== '')

		setFilteredData2(rawData)
	};

	return (
		<Layout style={{paddingBottom: 80, background: '#fff'}} className={'contentLayout container'}>
			<Tabs defaultActiveKey="my-products" id="client_page" >
				<TabPane  tab="Дэшборд" key="products" >
					<UserInterfaceProductsListComponent
						products={products_no_dataset}
						CRUDStatus={products.CRUDStatus}
						exportDatasetFile={exportDatasetFile}
						navigate={navigate}
						location={t}
						onSearch={onSearch}
						filteredData={filteredData}
					/>
				</TabPane>
				<TabPane tab="Отчёты" key="reports">
					<UserInterfaceReportsListComponent
						products={reports_obj}
						CRUDStatus={products.CRUDStatus}
						exportDatasetFile={exportDatasetFile}
						navigate={navigate}
						location={t}
						onSearch={onSearch2}
						filteredData={filteredData2}
					/>
				</TabPane>
				<TabPane tab="Помощь" key="help">
					<UserInterfaceHelpListComponent/>
				</TabPane>
			</Tabs>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		products: state.products.view
	}
}

export const UserInterfaceProductsContainer = connect(mapStateToProps)(ProductsConnector);


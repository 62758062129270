import React, {useEffect, useState} from 'react'
import {connect} from "dva";
import {usersActions} from "../../../models/usersModel/usersActions";
import {Cabinet} from "./components/Cabinet"
import Form from "antd/es/form";


const initialFields = [
    {
        name: 'name',
        value: ''
    },
    {
        name: 'description',
        value: ''
    },
    {
        name: 'path',
        value: ''
    },
    {
        name: 'product_id',
        value: []
    },
    {
        name: 'phone',
        value: '',
    },
    {
        name: 'position',
        value: '',
    },
    {
        name: 'client_name',
        value: '',
    },
]




export const UserCabinetContainer = (props) => {

    const {dispatch, currentUser} = props
    const [fields, setFields] = useState(initialFields);
    const [form] = Form.useForm()
    let userFields = currentUser
    if (currentUser) {
        userFields.change_password = currentUser.mailing.change_password
        userFields.new_report = currentUser.mailing.change_password
    }

    const [checkPassword, setCheckPassword] = useState({
        password: '',
        confirm_password: '',
        check: false,
        reports: true,
        confirm: true
    })

    const changePassword = (value, type) => {
        console.log('changePassword', value, type)
            let rawConfirm = true

        if (type === 'confirm_password') {
            if (checkPassword.password === value) {
                rawConfirm = true
            } else {
                rawConfirm = false
            }
        }
        setCheckPassword({
            ...checkPassword,
            [type]: value,
            confirm: rawConfirm
        })
    }



    const Cancel = () => {
        location.reload()
    }


    useEffect(() => {
        if (userFields) {
            setFields(
                Object.entries(userFields).map(item => {
                    return{
                        name: item[0],
                        value: item[1]
                    }
                })
            )
        }

    }, [userFields])


    const onSubmit = (payload) => {

        if (checkPassword.check === true) {
            payload.password = checkPassword.password
        }

        const mailing_settings = {
            new_report: checkPassword.reports,
            change_password: checkPassword.check
        }


        payload.mailing_settings = mailing_settings



        dispatch(usersActions.editUsersFetch(payload))

    }

    return <>
        <Cabinet
            form={form}
            fields={fields}
            // onEdit={onEdit}
            onSubmit={onSubmit}
            Cancel={Cancel}
            changePassword={changePassword}
            checkPassword={checkPassword}

            currentUser={currentUser}
        />
    </>
}


function mapStateToProps(state) {
    debugger
    return {
        products: state.products.view
    }
}

export const UserCabinetConnector = connect(mapStateToProps)(UserCabinetContainer);

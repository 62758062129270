import {Route, Routes} from "react-router-dom"
import {AuthContainer} from "../Pages/Auth/AuthContainer";
import {RegContainer} from "../Pages/Registration/RegContainer";

export const LoginLayoutContainer = () => {
    return <>
            <Routes>
                <Route path={'/*'}  element={<AuthContainer />} />

                <Route path='/signup' element={<RegContainer />}/>
            </Routes>
    </>
}



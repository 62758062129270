import {instance} from "../../api/api";

export const usersApi = {
	getUsersList: () => {
		return instance().get(`/users/`).then(res => res.data)
	},

	getProductsForUserList: (id) => {
		return instance().get(`/users_products/?user_id=${id}`).then(res => res.data)
	},

	createUser: (payload) => {
		return instance().post(`/users/`,payload).then(res => res.data)
	},

	blockUser: (payload) => {
		return instance().post(`/users/block/${payload}`).then(res => res.data)
	},

	unBlockUser: (payload) => {
		return instance().post(`/users/unblock/${payload}`).then(res => res.data)
	},

	editUser: (payload) => {
		return instance().put(`/users/personal_update`, payload).then(res => res.data)
	},

	createMassProductsToUser: (payload) => {
		return instance().post(`/users_products/`,payload).then(res => res.data)
	},

	changeUser: (client, id) => {
		return instance().put(`/users/${id}`, client).then(res => res.data)
	},

	deleteUser: (id) => {
		return instance().delete(`/users/purge/${id}`).then(res => res.data)
	},

	deleteBindUser: (user_id, product_id) => {
		return instance().delete(`/users_products/?user_id=${user_id}&product_id=${product_id}`).then(res => res.data)
	},

	setProductsToUser: (payload) => {
		return instance().post(`/users_products/`, payload).then(res => res.data)
	},
};


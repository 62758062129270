import Layout from "antd/es/layout/layout";
import {ClientsContainer} from "./Clients/ClientsContainer";
import {ProductsContainer} from "./Products/ProductsContainer";
import {UsersContainer} from "./Users/UsersContainer";
import {DatasetsContainer} from "./Datasets/DatasetsContainer";
import {Tabs} from "antd";
import styles from '../../assets/style.css'

const { TabPane } = Tabs;

export const AdminPanelContainer = () => {

	return (
		<>
			<Layout style={{paddingBottom: 80}} className={'contentLayout container'}>
				<Tabs defaultActiveKey="users"  id='admin_page'>
					<TabPane tab="Пользователи" key="users">
						<UsersContainer />
					</TabPane>
					<TabPane tab="Клиенты" key="clients">
						<ClientsContainer />
					</TabPane>
					<TabPane tab="Продукты" key="products">
						<ProductsContainer />
					</TabPane>
					<TabPane tab="Датасеты" key="datasets">
						<DatasetsContainer />
					</TabPane>
				</Tabs>
			</Layout>
		</>
	)
}


import React from 'react';
import { connect } from 'dva';
import { districts_dictionary } from './districts_dictionary'
import MobileDoubleParentWidget from "../MobileDoubleParentWidget";


const emptyData = []
let data = []

const Districts = connect((state, props) => {
  const map = 'mobile'
  let layer = state.geo.almaty.active_layer
  let sublayer = state.geo.almaty.active_filters.data
  let active_layer = ''

  if (sublayer.includes('avg_receipt_amt')) {
    active_layer = 'avg_receipt_amt'
  } else if (sublayer.includes('category_sales_amt')) {
    active_layer = 'category_sales_amt'
  } else if (sublayer.includes('place_count')) {
    active_layer = 'place_count'
  } else if (sublayer.includes('receipts_count')) {
    active_layer = 'receipts_count'
  }




  if (state.geo.almaty.start_data.widget_data) {


    data = state.geo.almaty.start_data.widget_data[active_layer][1].districts[sublayer]
    data = data.map(item => {
      return {
        districts: Object.entries(item)[0][0],
        item_value: Object.entries(item)[0][1]
      }
    })
  }

  return {
    // ...props,
    data: data ? data : emptyData,
    start_data:  data ? data : emptyData,
    active_filter: state.geo.almaty.active_filters.districts,
    dictionary: districts_dictionary,
    item_name: 'districts',
    filter_name: 'districts',
    widget_name: '',
    item_value: 'item_value',
    absolute_left: 50,
    multi: true
  }
})(MobileDoubleParentWidget)
export default Districts

